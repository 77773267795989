export const userActions = {
  FETCH_USER_PENDING: "FETCH_USER_PENDING",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILED: "FETCH_USER_FAILED",
};

export const heroActions = {
  FETCH_HERO_PENDING: "FETCH_HERO_PENDING",
  FETCH_HERO_SUCCESS: "FETCH_HERO_SUCCESS",
  FETCH_HERO_FAILED: "FETCH_HERO_FAILED",
  FETCH_HERO_LIST: "FETCH_HERO_LIST"
};

export const movementActions = {
  FETCH_MOVEMENT_PENDING: "FETCH_MOVEMENT_PENDING",
  FETCH_MOVEMENT_SUCCESS: "FETCH_MOVEMENT_SUCCESS",
  FETCH_MOVEMENT_FAILED: "FETCH_MOVEMENT_FAILED",
  FETCH_MOVEMENT_LIST: "FETCH_MOVEMENT_LIST"
};

export const storyActions = {
  FETCH_STORY_PENDING: "FETCH_STORY_PENDING",
  FETCH_STORY_SUCCESS: "FETCH_STORY_SUCCESS",
  FETCH_STORY_FAILED: "FETCH_STORY_FAILED",
  FETCH_STORY_LIST: "FETCH_STORY_LIST"
};
