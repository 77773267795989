import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFacebookF, faLinkedin, faSquareXTwitter, faSquareInstagram, faTiktok, faTelegram, faSquareReddit, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons' 

const SocialMedia = ({item}) => {
  return (
    <div className="social-links">
        <h5>Follow Us</h5>
        <div className="links">
            <a href="http://twitter.com/bigtaleme"  target="_blank">
                <FontAwesomeIcon icon={faSquareXTwitter}/>
            </a>
            <a href="https://www.linkedin.com/company/bigtaleme"  target="_blank">
                <FontAwesomeIcon icon={faLinkedin}/>
            </a>
            <a href="https://www.instagram.com/bigtaleme/"  target="_blank">
                <FontAwesomeIcon icon={faSquareInstagram}/>
            </a>
            <a href="https://www.tiktok.com/@bigtaleme"  target="_blank">
                <FontAwesomeIcon icon={faTiktok}/>
            </a>
            <a href="https://t.me/bigtaleme"  target="_blank">
                <FontAwesomeIcon icon={faTelegram}/>
            </a>
            <a href="https://www.facebook.com/bigtaleme"  target="_blank">
                <FontAwesomeIcon icon={faFacebookF}/>
            </a>
            <a href="https://whatsapp.com/channel/0029VaS8lzvKgsO14ISPRy43"  target="_blank">
                <FontAwesomeIcon icon={faWhatsappSquare}/>
            </a>
            <a href="https://www.reddit.com/r/bigtale/s/lLIqFGcgok"  target="_blank">
                <FontAwesomeIcon icon={faSquareReddit}/>
            </a>
        </div>
        
    </div>

  );
};
// sidebar-active
export default SocialMedia;
{/* <Link to={item.link} style={{ textDecoration: 'none' }}>
      <li className="sidebar-item">{item.name}</li>
    </Link> */}