import React from "react";
// import { useMediaQuery } from "react-responsive";
// import styled from "styled-components";

import Header from "components/layout/Header";
import Register from "./Register";


function Index(props) {
  return (
    <div>
      <Header />
      <div className="wrapper-center">
        <h3>Register</h3>
        <Register />
      </div>
    </div>
  );
}
// {useMediaQuery({ maxWidth: 768 }) ? <Header /> : <Span>Register</Span>}
export default Index;
