import React from "react";
import Header from "components/layout/Header";
import Leftbar from "components/layout/LeftBar";
import Content from "./Content";

export default (props) => {
  return (
      <div>
         <Header  />
         <Leftbar />
         <Content />
      </div>

  );
};
