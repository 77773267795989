import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoginBottom from "./LoginBottom";

import { Formik } from "formik";
import * as Yup from "yup";

import { loginUser, fetchUser } from "store/actions/userActions";
import StyledButton from "components/utils/StyledButton";

import Header from "components/layout/Header";
import InputNew from "components/utils/InputNew";

import customToastSuccess from "components/utils/CustomToast/customToastSuccess";
import customToastError from "components/utils/CustomToast/customToastError";
import { toast } from "react-toastify";




function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
 
      dispatch(
        loginUser({ identifier: data.username, password: data.password })
      ).then((json) => {
        if (json && json.error) {
          customToastError("Invalid username/password");
        } else  {
          const path = localStorage.getItem('path');
          localStorage.removeItem('path');
          navigate(path || "/account");
        }
        setSubmitting(false);
      });
    },
    [dispatch]
  );

  return (
    <>
      <Header />
      <div className="wrapper-center">
        <div className="form-wrapper">
          <h3>Hi, do we know you?</h3>
          <h6>Log in with the account</h6>
          <Formik
            initialValues={{ username: "", password: "", remember_me: 0 }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              username: Yup.string().required("Required"),
              password: Yup.string().required("No password provided.")

            })}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <div className="form-inner">
                  <form onSubmit={handleSubmit}>
                    <input
                      type={"text"}
                      name="username"
                      placeholder="Username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.username && touched.username && "error"}
                    />
                    {errors.username && touched.username && (
                      <div className="input-feedback">{errors.username}</div>
                    )}
                    <input
                      type={"password"}
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.password && touched.password && "error"}
                    />

                    {errors.password && touched.password && (
                      <div className="input-feedback">{errors.password}</div>
                    )}


                      <StyledButton
                        alignSelf="flex-start"
                        type="submit"
                        mediaWidth="100%"
                        // disabled={isSubmitting}
                      >
                        Login
                      </StyledButton>
                  </form>
                </div>
              );
            }}
          </Formik>
        </div>
        <LoginBottom />
      </div>
    </>
  );
}

export default Index;

// do we need checkbox?
// <CheckboxButtonWrapper>
//   <CheckboxAndTitleWrapper>
//     <CheckBox
//       type="checkbox"
//       onChange={handleChange}
//       name="remember_me"
//       value={values.remember_me}
//     />
//     <Span3>Remember Data</Span3>
//   </CheckboxAndTitleWrapper>
