import endPoint from "config/endPoint";
import { storyActions } from "../types";


export const saveStory = (data) => {
  console.log('aa',data)
  const fetchStoryFailed = () => ({
    type: storyActions.FETCH_STORY_FAILED,
    payload: data,
  });

  const fetchStorySuccess = (story) => ({
    type: storyActions.FETCH_STORY_SUCCESS,
    payload: story,
  });

  const fetchStoryPending = () => ({
    type: storyActions.FETCH_STORY_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchStoryPending());

    return fetch(`${endPoint.endpoint}/stories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
          return dispatch(fetchStorySuccess(json));
      })
      .catch(() => dispatch(fetchStoryFailed));
  };
};

export const setCurrentStory = (story) => {
  return {
    type: storyActions.FETCH_STORY_SUCCESS,
    payload: story
  }
}

export const editStory = (data, id) => {
  const fetchStoryFailed = () => ({
    type: storyActions.FETCH_STORY_FAILED,
    payload: data,
  });

  const fetchStorySuccess = (story) => ({
    type: storyActions.FETCH_STORY_SUCCESS,
    payload: story,
  });

  const fetchStoryPending = () => ({
    type: storyActions.FETCH_STORY_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchStoryPending());
    return fetch(`${endPoint.endpoint}/stories/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
          return dispatch(fetchStorySuccess(json));
      })
      .catch(() => dispatch(fetchStoryFailed));
  };
};

export const fetchStories = (id) => {
  const fetchStoryList = (list) => ({
    type: storyActions.FETCH_STORY_LIST,
    payload: list,
  });


  return async (dispatch) => {
    return fetch(`${endPoint.endpoint}/stories?filters[owner][id][$eq]=4&filters[owner][id][$eq]=${id}`, {
    // return fetch(`${endPoint.endpoint}/stories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(fetchStoryList(json));
      })
      .catch(() => dispatch(fetchStoryList([])));
  };
};

export const getStory = (id) => {
  const fetchStoryFailed = () => ({
    type: storyActions.FETCH_STORY_FAILED,
    payload: null,
  });

  const fetchStorySuccess = (story) => ({
    type: storyActions.FETCH_STORY_SUCCESS,
    payload: story,
  });

  const fetchStoryPending = () => ({
    type: storyActions.FETCH_STORY_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchStoryPending());
    return fetch(`${endPoint.endpoint}/stories/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(fetchStorySuccess(json));
      })
      .catch(() => dispatch(fetchStoryFailed));
  };
};
