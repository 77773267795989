import { heroActions } from "../types";
import myHero from "config/myHeroProps";

const initialState = {
  // hero: {properties: myHero}
  hero: null,
  list: null
};

const heroDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case heroActions.FETCH_HERO_PENDING:
      return {
        ...state,
        hero: {...state.hero},
      };
    case heroActions.FETCH_HERO_SUCCESS:
      return {
        ...state,
        hero: action.payload || null,
      };
    case heroActions.FETCH_HERO_FAILED:
      return {
        ...state,
        hero: null,
      };
    case heroActions.FETCH_HERO_LIST:
      return {
        ...state,
        list: action.payload || null,
      };
    default:
      return state;
  }
};

export default heroDataReducer;
