import React, {  useState, useEffect } from "react";
import Dropdown from "components/utils/Dropdown";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";
import CheckBox from "components/utils/CheckboxNew";
import _ from 'lodash';
import bones from "config/bonesDropdown";
import axis from "config/axis";
import movement from "config/movement";
import ModelCanvas from "pages/CreateHero/ModelCanvas";

const ManualStepChange =  ({currentNodes, runWithPrev, setPrev,  currentBone,  selectBone, getStartPosition, changeValue, changeMove, stepArr, value}) => {

  return (
    
    <div>
        <div className="step-model-cont">
            <ModelCanvas getStartPosition={getStartPosition} currentNodes={currentNodes}  selectBone={selectBone} currentBone={currentBone}/>
        </div>
        <div  className="step-body">
            <Dropdown options={bones} name="choose-bone"  selected={currentBone || stepArr[0]} onChange={(val) => changeMove(val, 0)}/>
            <Dropdown options={movement} name="choose-movement" selected={stepArr[1]} onChange={(val) => changeMove(val, 1)}/>
            <Dropdown options={axis} name="choose-axis" selected={stepArr[2]} onChange={(val) => changeMove(val, 2)}/>
            <InputNumberWithArrows label="Value" value={value || 0} changeValue={changeValue} />

        </div>
        
      
        <div className="movement-buttons">
            <div>
            <CheckBox labelText={'Run with previous'} defaultChecked={runWithPrev} changeValue={() => setPrev(!runWithPrev)}/>
            </div>
        </div>
        
    </div>
        
    
  );
};

export default ManualStepChange;

