
import React, { useState } from "react";

const Slider = ({ min, max, value, onChange, step = 1 }) => {
  const [currentValue, setCurrentValue] = useState(value || min);

  const handleChange = (event) => {
    const newValue = Math.min(Math.max(min, +event.target.value), max);
    setCurrentValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="slider-container">
      <input
        type="range"
        min={min}
        max={max}
        value={currentValue}
        step={step}
        onChange={handleChange}
      />
      <span className="slider-value">{currentValue}</span>
    </div>
  );
};

export default Slider;