import React from "react";
import Header from "components/layout/Header";
import AccountPage from "./AccountPage";

export default (props) => {

  return (
      <div className="main-container">
         <Header  />
         <AccountPage />
      </div>
  );
};
