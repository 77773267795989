import React, {useState} from "react";
import ObjBody from "./AddObjectBody";
import AddBtn from "components/utils/AddBtn";
import RemoveIcon from "components/utils/RemoveIcon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';


const ObjCont =  ({changeValue, add, objects, remove}) => {
  const [selected, setSelected] = useState(0);

  const addItem = () => {
    add('objects');
    setSelected(objects.length);
  }

  const removeItem = () => {
    remove('objects', selected);
    setSelected(objects.length - 1);
  }
  const renderHeaderArea = () => {
    return objects.map((el, index) => {
      let isSelected = index === selected;
      let liClassname = isSelected ? "selected li-tab-header": 'li-tab-header';
      let className = isSelected ? "selected tab-header": 'tab-header';
      let icon = isSelected ? faChevronDown :faChevronUp;
      return <li key={index} className={liClassname} onClick={() => {setSelected(index)}}>
                <div  className={className}>
                  <span>Object {index}</span>
                  <div className="icons">
                    <RemoveIcon remove={removeItem}/>
                    <FontAwesomeIcon  icon={icon} />
                  </div>

                </div>

                {
                  isSelected ?
                  <ObjBody object={el} changeValue={changeValue} index={selected}/>
                  :''
                }
              </li>
    })
  }
  return (
    <div>
      <ul>
        {renderHeaderArea()}
      </ul>

      <AddBtn handleClick={addItem}/>
    </div>

  );
};

export default ObjCont;
