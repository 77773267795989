import React  from "react";

const Content =  (props) => {

  return (
    <div className="main-container">
        How to use
    </div>
  );
};

export default Content;
