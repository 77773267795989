import React, { useState } from "react";
import styled from "styled-components";

import { Field, ErrorMessage } from "formik";

const Selected = styled.div`
  width: 100%;
  height: 54px;
  border: 1px solid rgba(134, 117, 79, 0.2);
  border-radius: 6px;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.light_Grey_Inactive};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:focus {
    outline: none;
  }

  .op {
    font-family: "Avenir";
    background: ${({ theme }) => theme.white};
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 52px;
    font-size: 14px;
    margin: 0 2%;
    border: none;
    width: 100%;
    font-weight: 800;
    :focus {
      outline: none;
    }
    -webkit-appearance: none;
    -moz-appearance: none;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${({ theme }) => theme.dark_Grey};
      opacity: 1; /* Firefox */
      font-weight: 800;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${({ theme }) => theme.dark_Grey};
      font-weight: 800;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${({ theme }) => theme.dark_Grey};
      font-weight: 800;
    }
  }

  @media (max-width: 767px) {
    width: ${({ mediawidth }) => mediawidth || "100%"};
    .op {
      width: 100%;
      margin: 0 4%;
    }
  }
  margin: 10px 0;
`;

function Asd({ label, name, options, ...rest }) {
  return (
    <Selected {...rest}>
      {/* <label htmlFor="name">{label}</label> */}
      <Field as="select" id={name} name={name} {...rest} className="op">
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          );
        })}
      </Field>
    </Selected>
  );
}

export default Asd;
