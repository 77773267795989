import React from "react";

const Button =  ({handleClick, className, text, disabled}) => {
  return (
    <button
      className={className || "default-button"} 
      onClick={handleClick}
      disabled={disabled}
      >
      {text}
    </button>
  );
};

export default Button;
