import { toast } from "react-toastify";

export default function (err) {
  console.log(err)
  return toast(err, {
    className: "toast-error",
    bodyClassName: "toast-font",
    progressClassName: "toast-progressBar",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
    // position: "top-left",
    // autoClose: 5000,
    // hideProgressBar: false,
    // closeOnClick: true,
    // pauseOnHover: true,
    // draggable: true,
    // progress: undefined,
    // theme: "light",
  });
}
