import React from "react";
import { useMediaQuery } from "react-responsive";

import SuccessMobile from "components/utils/SuccessMobile";
import Confirmation from "./Confirmation";

export default (props) => {
  return useMediaQuery({ maxWidth: 768 }) ? (
    <SuccessMobile
      buttonValue="Resend email"
      buttonOption="white"
      header="Confirmation sent"
      icon="confirm"
      message={3}
      {...props}
    />
  ) : (
      <Confirmation {...props} />
    );
};
