import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import endPoint from "config/endPoint";

import { forgotPassword } from "store/actions/userActions";
// import {
//   updatePhoto,
//   updateUser,
//   removePhoto,
// } from "store/actions/userActions";
import Input from "components/utils/NewInput";
import colors from "config/colors";
import Btn from "components/utils/StyledButton";

const FormInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 40px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

const InputLabel = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
`;

const Button = styled.button`
  max-width: 200px;
  width: 100%;
  margin: ${({ margin }) => margin || "10px 0"};
  align-self: flex-end;
  height: 56px;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme, color }) => (color ? theme.error : theme.blue)};
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  border: solid 1px
    ${({ border, theme }) => (border ? theme.error : theme.blue)};
  margin-top: 20px;
  align-self: center;

  &:focus {
    outline: none;
  }

  @media (min-width: 768px) {
    max-width: 180px;
    margin-top: 0;
  }
`;

const SaveBtnWr = styled.div`
  margin-top: 20px; //61

  /* @media (min-width: 1440px) {
    margin-top: 20px; //230
  } */
`;

const UpdateBtnWr = styled.div`
  flex: 1;

  @media (min-width: 768px) {
    margin-left: 28px;
  }
`;

const ImageWrapper = styled.div`
  width: 143px;
  height: 143px;
  border-radius: 10px;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 767px) {
    // width: 250px;
    // height: 250px;
  }
`;

const ImageAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: inherit;
    align-items: flex-end;
  }
`;

const EditForm = ({ user }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [file, setFile] = useState(null);

  const hiddenFileInput = useRef(null);

  const handleClick = useCallback(() => {
    hiddenFileInput.current.click();
  }, []);

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  // const handleRemovePhoto = useCallback(() => {
  //   if(!file && user.photo !== 'default-user-image.png') {
  //     setRemoveLoading(true);
  //     // dispatch(removePhoto()).then(() => setRemoveLoading(false));
  //   } else {
  //     hiddenFileInput.current.value = null;
  //     setFile(null);
  //   }
  // }, [dispatch, file, user.photo]);

  const handleSubmit = useCallback(
    async ({ email, username, phone }, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);

      if (file) {
        // await dispatch(updatePhoto(file));
      }

      const data = {
        username,
        phone,
      };

      if (email !== user.email) {
        data.email = user.email;
        data.new_email = email;
      } else {
        data.email = email;
      }

      // dispatch(updateUser(data)).then(() => {
      //   setLoading(false);
      //   console.log('asdasd');
      //   setSubmitting(false);
      // });
    },
    [dispatch, file]
  );

  // const photo =
  //   user.photo && user.photo.includes("base64")
  //     ? user.photo
  //     : `${endPoint}/${user.photo}`;

  return (
    <>
      <ImageAndButtonWrapper>
{
      //  <ImageWrapper>
      //     <img src={file ? URL.createObjectURL(file) : photo} alt="profile" />
      //   </ImageWrapper>
}
        <Input
          type="file"
          display="none"
          ref={hiddenFileInput}
          onChange={handleChange}
          accept="image/*"
        />

        <UpdateBtnWr>

          <Button
            border
            color
            margin="0px 0 10px 0"
            // onClick={handleRemovePhoto}
          >
            {removeLoading ? "Loading ..." : "Remove"}
          </Button>

          <Button margin="0px" onClick={handleClick}>
            Update
          </Button>
        </UpdateBtnWr>
      </ImageAndButtonWrapper>

      <Formik
        initialValues={{
          username: user.username,
          email: user.email,
          phone: user.phone,
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          username: Yup.string().required("Required"),
          email: Yup.string().email().required("Required"),
          phone: Yup.string()
            .required("No phone provided.")
            .matches(
              /^\+(\d{1})+( \(\d{3}\))+( \d{4})+(-\d{2})+(-\d{2})/,
              "Phone format is wrong."
            )
            .nullable(),
        })}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <FormInnerWrapper>
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <InputWrapper>
                  <InputLabel htmlFor="full-name">Full Name</InputLabel>

                  <Input
                    id="full-name"
                    margin="10px 0 0 0"
                    width="100%"
                    placeholder="username"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.username && touched.username && "error"}
                  />
                  {errors.username && touched.username && (
                    <div className="input-feedbackProfile">{errors.username}</div>
                  )}
                </InputWrapper>

                <InputWrapper>
                  <InputLabel htmlFor="email">Email</InputLabel>

                  <Input
                    id="email"
                    width="100%"
                    margin="10px 0 0 0"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.email && touched.email && "error"}
                  />
                  {errors.email && touched.email && (
                    <div className="input-feedbackProfile">{errors.email}</div>
                  )}
                </InputWrapper>

                <InputWrapper>
                  <InputLabel htmlFor="phone">Phone</InputLabel>

                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    inputStyle={{
                      width: "100%",
                      height: "56px",
                      border: "1px solid rgba(134, 117, 79, 0.2)",
                      borderRadius: "6px",
                    }}
                    country="de"
                    alwaysDefaultMask={true}
                    regions="europe"
                    enableSearch
                    countryCodeEditable={false}
                    defaultMask="(...) ....-..-.."
                    name="phone"
                    value={values.phone}
                    className={
                      "inputPhone" + errors.phone && touched.phone && " error"
                    }
                  />
                  {errors.phone && touched.phone && (
                    <div className="input-feedbackProfile">{errors.phone}</div>
                  )}
                </InputWrapper>

                <Btn
                  mediaWidth="100%"
                  width="100%"
                  height="52px"
                  backgroundColor={colors.white}
                  color={colors.blue}
                  border=" solid 1px #538BF0"
                  onClick={() => {
                    setPasswordLoading(true);
                    forgotPassword(user.email).then(() =>
                      setPasswordLoading(false)
                    );
                  }}
                >
                  {passwordLoading ? "Changing..." : "Change Password"}
                </Btn>

                <SaveBtnWr>
                  <Btn
                    mediaWidth="100%"
                    width="100%"
                    type="submit"
                    height="52px"
                    disabled={loading}
                    style={{ pointerEvents: loading ? 'none' : 'all'}}
                  >
                    {loading ? "Saving..." : "Save changes"}
                  </Btn>
                </SaveBtnWr>
              </form>
            </FormInnerWrapper>
          );
        }}
      </Formik>
    </>
  );
};

EditForm.propTypes = {
  user: PropTypes.shape({
    created_at: PropTypes.string,
    email: PropTypes.string,
    email_verified_at: PropTypes.string,
    gender: PropTypes.string,
    id: PropTypes.number,
    username: PropTypes.string,
    new_email: PropTypes.string || null,
    phone: PropTypes.number || null,
    // photo: PropTypes.string,
    role_id: PropTypes.number,
    token_optional: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default EditForm;
