import React from "react";
import NavigationCircle from "components/utils/NavigationCircle";
import Item from "./Item";
// import Dropdown from "components/utils/Dropdown";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";
import InputColor from "components/utils/InputColor";

const CharacterCont =  ({changeValue, index, object}) => {
//   const categories = [{name: 0, display_name: 'bg', count: 4}, {name: 1, display_name: 'effect', count: 3}]
//   const category = categories[area.category] || categories[0];
//   const {display_name, count} = category;

  const count = 4;
  const arr = Array.from({ length: count}, (_, index) => index );

  const changeObjectsProps = (value, type) => {
    changeValue(value, type, 'objects', index);
  }


  return (
    <div className="cont-item">
      <NavigationCircle changeValue={changeObjectsProps} defaultUp={object.up} defaultRight={object.right} defaultZIndex={object.zIndex}/>
      <InputNumberWithArrows label='Scale' value={object.scale || 1}  min={0.25}  changeValue={(val) => changeObjectsProps(val, 'scale')}/>
      <InputColor label={'Color'} value={object.color} changeValue={(val) => changeObjectsProps(val, 'color')}/>
      {/* <Dropdown options={categories} name="choose-category" selected={display_name} onChange={(val) => changeObjectsProps(val, 'category')}/> */}

      <div className="bg-cont">
        {
          arr.map((el, index) => {
            return <Item key={index} selected={el === object.name} value={`url(${require('assets/glb/obj/obj_' + el +'.png')})`} changeValue={() => changeObjectsProps(el, 'name')}/>
          })
        }
      </div>

    </div>

  );
};

export default CharacterCont;
