import React from "react";
import styled from "styled-components";
import SuccessIcon from "components/iconsAndLogos/SuccessIcon";
import StyledButton from "./StyledButton";
import colors from "config/colors";
import ConfirmationIcon from "components/iconsAndLogos/ConfirmationIcon";
import {Link} from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  height: 100vh;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
  background-color: ${({ theme }) => theme.white};
`;

const Span1 = styled.span`
  font-family: "Avenir";
  margin-top: 35px;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 43px;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ theme }) => theme.dark_Grey}; ;
`;

const Span2 = styled.span`
  font-family: "Avenir";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 250px;
  color: ${({ theme }) => theme.light_Grey_Inactive};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  position: fixed;
  background-color: ${({ theme }) => theme.white};
  height: 72px;
  width: 375px;
  padding: 0 20px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
`;

function MatchSuccess({
  icon,
  person,
  buttonOption,
  buttonValue,
  message,
  header,
}) {
  return (
    <Wrapper>
      <InnerWrapper>
        {icon === "confirm" ? <ConfirmationIcon /> : <SuccessIcon />}
        <Span1>{header}!</Span1>
        <Span2>
          {message === 1
            ? `You succesfully made a match with the ${person}. If you want to contact
        him continue now.`
            : message === 2
            ? "Your account has been successfully registered"
            : "We have sent a confirmation letter to your mail, follow the link in it to continue"}
        </Span2>
      </InnerWrapper>

      <ButtonWrapper>
        {buttonOption === "white" ? (
          <Link to='/' style={{ textDecoration: 'none' }}>
            <StyledButton
              height="52px"
              border="solid 1px #efefef"
              color={colors.blue}
              backgroundColor={colors.white}
              mediaWidth="375px"
              width="577px"
            >
              {buttonValue}
            </StyledButton>
          </Link>
        ) : (
          <Link to='/' style={{ textDecoration: 'none' }}>
            <StyledButton
              height="52px"
              color={colors.white}
              backgroundColor={colors.fancy_Green}
              mediaWidth="375px"
              width="577px"
            >
              {buttonValue}
            </StyledButton>
          </Link>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
}

MatchSuccess.defaultProps = {
  person: "landlord",
  buttonOption: "white",
  buttonValue: "Go to my Matches",
  header: "Success",
  message: 1,
};

export default MatchSuccess;
