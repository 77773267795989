import React, {useState} from "react";
// import { GoogleGenerativeAI } from "@google/generative-ai";
import bones from "config/bonesDropdown";
import GEMINI_API_KEY  from "config/aiApiKey"
import Input from "components/utils/InputNew";



// const SERVICE_ACCOUNT_KEY_FILE = require('gen-lang-client-0731050810-82c0a43960d1.jsonl');
// const token = 'ya29.c.c0ASRK0GaCymNMh6YTKBlWnpgzJBETCOqLX4h7PnH2TqQtZh6WjfAhdQRhiFUmiWS-pSpZI3rZCqghitHeHG_EQg1VbRVfBwUNRme1f_G7v2dVWkMJrfLIam5YmAOLycl20zR6FcGovXYzvM6rPs5Pg4LkK1hPQMeVjBLGF4yPd6_bsHpXaWD-0LtXXAA4gqdLLfqyGNJyL867Hez18PJppmyot0TJXYDmQYhZZLkuzz8Tb3_zFnRDy2N8GqiuaBzDd6YXVJWzTOzmwt-yJQZQ4WQMef2JyUB36NANbe0TMkm02TJg67cLQ-C0GEZ9LZyoHtMPNDXC0ZwIdYVr0s7AJW0bsD8FOee8Aho9F0Paq21UDqR9DK6ltJwE384PoFc2ow09w06MMh3tdMftbWafnQ4Y8MZcFxrFXz9aS7XmZa23u35ej6kWkUYZ4i-ciyp4nVixqb-r4kXznboIyROQyI8sW1lax38nmiyhjFahZMuOJ1dJIpzaklkWWSRU2e7g2cS--8-1Fenlgom3mxchcIne2FqahhRB2U8wgkU9sokUM66Q7eu4owehQbgaIsFiotvm19ucXjeX6t7cFMft_0il-XRgpa6JF60Rlxtqlwe_Wlf085xmBZOuFUz6w84BRyX_o2Q6xFZdYopMQriIppyWUc0M5h8IJ8zao_sYzimoVcpgl19-Q1SSJUI7M81UMkdOkBztrUtM5S9z6hckdnmaqxp0Xu4mBz43emkV9VjwF3jkMq8vt3eaQsizb0a8JhoSWlIw6j9WgBgMiqVVlwOdlZnUII4x6F2aFBJ55yQ4nuaba06J6dwl5m4rWx-73gJp3wOY4fBXhh32rnz0JqtJ6S16rrnQ9_3m3Zmoyy8c9kdlR7mmXdtkBmoFaWjhr4z3hFw1FM8Bjbioxrr7drU1yWnyQzXefzM2zv6BqxZBrcb5BjgQzhsuZ1aozfbZJ4tMSt7-iy96e2Yn0tZjn3lXiIdzms3YV22dQR04MeS-Iliozg8viih'
// import {google} from "googleapis"

// Model configuration
// node --version # Should be >= 18
// npm install @google/generative-ai
// const {
//     VertexAI
//   } = require('@google-cloud/vertexai');

const {
  GoogleGenerativeAI,
  HarmCategory,
  HarmBlockThreshold,
} = require("@google/generative-ai");

const MODEL_NAME = "gemini-1.5-pro-latest";
const API_KEY = "YOUR_API_KEY";
const list = 'pelvis, thighL, shinL, footL, toesL, thighR, shinR, footR, toesR, spine001, spine002, chest, neck01, neck02, face_head, face_jaw, face_eyeL, face_eyeR, face_earL, face_earR, face_cheekL, face_cheekR, face_eyeleshL, face_eyeleshL01, face_eyeleshR, face_eyeleshR01, lh_clavicleL, lh_upper_armL, lh_lowerarmL, lh_handL, lh_index01L, lh_index02L, lh_index03L, lh_thumb01L, lh_thumb02L, lh_thumb03L, lh_middle01L, lh_middle02L, lh_middle03L, lh_ring01L, lh_ring02L, lh_ring03L, lh_pinky01L, lh_pinky02L, lh_pinky03L, lh_clavicleR, rh_upper_armR, rh_lowerarmR, rh_handR, rh_index01R, rh_index02R, rh_index03R, rh_thumb01R, rh_thumb02R, rh_thumb03R, rh_middle01R, rh_middle02R, rh_middle03R, rh_ring01R, rh_ring02R, rh_ring03R, rh_pinky01R, rh_pinky02R, rh_pinky03R'
    

async function runChat(input) {
  const genAI = new GoogleGenerativeAI(GEMINI_API_KEY);
  const model = genAI.getGenerativeModel({ model: "projects/380951612821/locations/us-central1/models/6487744225994604544" });
  // 6487744225994604544
  console.log(model)

  
  const result = await model.generateContent(input);
  const response = await result.response.text();
  return response
  // setResponse(response)
  // console.log(response.text());
}


// const genAI = new GoogleGenerativeAI(GEMINI_API_KEY);
// const model = genAI.getGenerativeModel({
//   model:"gemini-pro",
//   // generationConfig :generationConfig,
//   // systemInstruction: systemInstruction,
//   // safetySettings: safetySettings  
//   }
// );


const Prompt =  ({moveByPrompt, reset}) => {
    let value = '';
    const boneStr = JSON.stringify(bones);
    const [aiResponse, setResponse] = useState('');
    // console.log(bones.map((a) => { return a.name}).join(', '))
    
   
    
    // Start conversation with history
    // const conversation = model.startChat(conversationHistory);
    
    
    
    const handleClick = () => {
        reset()
        runChat(value, setResponse).then((json) => {
          moveByPrompt(JSON.parse(json))
        });
     
    }

      
    const handleChange = (val) => {
        value = val;
    }



  return (
    <div >
        <Input placeholder="movement" defaultValue={value} changeValue={handleChange} />
        <button onClick={() => handleClick()}>Search</button>
        
    </div>

  );
};

export default Prompt;
