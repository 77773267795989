import React from "react";
import Slider from "components/utils/Slider";

const EmotionsSelection =  ({bone, setStep}) => {
//   const renderBox = () => {
//     return  Object.keys(standardMovements[bone]).map(function(key) {
//         return <Item key={key}value={`url(${require('assets/movement/' + key +'.png')})`} changeValue={() => handleClick(key)} />
//     });
//   }
//   const handleClick = (boneName) => {
//     setStep(standardMovements[bone][boneName]);
//   }((


// jghayn
// : 
// 0
// jpit
// : 
// 2
// tartel
// : 
// 1
  const changeValue = (value, index) => {
    const obj = {
        [`emotion ${index} face 0`]: value
    }
    setStep(obj)
    // console.log(obj)
    // set
    //index is morphTargetInfluences index

  }
  return (
    <div>
        <div>Smile</div>
        <Slider 
            min={0}
            max={1}
            step={0.1}
            onChange={(value) => changeValue(value, 2)}
        />
        <div>Angry</div>
        <Slider 
            min={0}
            max={1}
            step={0.1}
            onChange={(value) => changeValue(value, 0)}
        />
        <div>Blink</div>
        <Slider 
            min={0}
            max={1}
            step={0.1}
            onChange={(value) => changeValue(value, 1)}
        />
    </div>
  );
};

export default EmotionsSelection;
