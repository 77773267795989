import React from "react";
import standardMovements from "config/standardMovements";
import Item from "pages/CreateStory/Item"


const SelectionBox =  ({bone, setStep}) => {
  const renderBox = () => {
    return  Object.keys(standardMovements[bone]).map(function(key) {
        return <Item key={key}value={`url(${require('assets/movement/' + key +'.png')})`} changeValue={() => handleClick(key)} />
    });
  }
  const handleClick = (boneName) => {
    setStep(standardMovements[bone][boneName]);
  }
  return (
    <div>
        {renderBox()}
    </div>
  );
};

export default SelectionBox;
