import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

import Logo from "components/iconsAndLogos/Logo";
import Container from "components/utils/Container";
// import LanguageSelect from "./components/LanguageSelect";
import UserImageIcon from "components/iconsAndLogos/UserImageIcon";
import colors from "config/colors";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  z-index: 99999;
`;

const NavBar = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const MenuListBox = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: row;
`;

const MenuListItem = styled.li`
  font-size: 12px;
  line-height: 1.33;
  font-weight: 900;
  margin-right: 33px;

  & > a {
    color: ${({ theme }) => theme.text_Grey};
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    font-size: 16px;
  }
`;

const SubMenu = styled.ul`
  display: none;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50px;

  ${MenuListItem} {
      margin-right: 0;
      height: 20px;
      background: #325;
      margin: 1px;
  }
`;

const MenuList = styled.ul`
  display: flex;
  align-items: center;
  text-align: center;

  ${MenuListItem}:first-child:hover {
    ${SubMenu} {
      display: block;
    }
  }
  ${MenuListItem} {
    &:last-child {
      margin-right: 0;
    }
  }
`;
const UserText = styled.p`
  font-size: 12px;
  line-height: auto;
  font-weight: 900;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.text_Grey};
  margin-left: 14px;
`;

const HeaderMain = ({ userId, links }) => {
  const activeStyle = {
    color: "rgb(238 50 55)",
    // borderColor: "rgb(238 50 55)",
  }

  return (
    <Wrapper>
      <Container>
        <div
          style={{
            display: "flex",
            // alignItems: "center",
            // justifyContent: "space-between",
            width: "80%",
            height: "68px",
            float: "left"
          }}
        >





          <NavBar>
            <Link to={userId ? "/landing" : "/"}>
              <Logo />
            </Link>

            <div style={{ marginLeft: "40px",background:"red", }}>
              {
                //<LanguageSelect />
              }
            </div>
          </NavBar>






          
        </div>





        <div
          style={{
            display: "flex",
            // alignItems: "right",
            justifyContent: "end",
            width: "20%",
            height: "68px",
          }}
        >
          <NavLink
            to={userId ? "/account" : "/login"}
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <UserImageIcon />

            <UserText
              style={{
                fontSize: "16px"
              }}
            >{userId ? "Account" : "SIGN IN"}</UserText>
          </NavLink>



        </div>
      </Container>
    </Wrapper>
  );
};

HeaderMain.propTypes = {
  userId: PropTypes.number || undefined,
  links: PropTypes.array,
};

export default HeaderMain;
