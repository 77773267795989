import React from "react";
import ModelCanvas from "pages/CreateHero/ModelCanvas";
import Button from "components/utils/Btn";
import { useNavigate } from "react-router-dom";

import myHero from "config/myHeroProps";

const Item =  ({hero}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/edit-character/${hero.id}`);
  }
  return (
    <div className="hero-item">
        <div>{hero.attributes.name}</div>
        <div>{hero.attributes.description}</div>
        <div className="model-cont">
          <ModelCanvas myHeroProps={hero.attributes.properties || myHero }/>
        </div>
        <Button handleClick={handleClick} text="Edit"/>

    </div>

  );
};

export default Item;
