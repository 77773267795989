import React from "react";

const Item =  ({value, changeValue, selected, name, gender}) => {
  const selectedClassName = selected ? 'selected' : '';

  return (
    <div>
      {
        <div onClick={() => changeValue(value)} className={selectedClassName}>
        {name === 'gender' 
          ? <div>{value}</div>
          :value
          ?<img src={require(`assets/heroProps/${name}_${gender}_${value}.png`)} alt={`img${name}_${value}`}/>
          : <img src={require(`assets/heroProps/none.jpg`)} alt="none"/>
        }

        </div>
      }
    </div>

  );
  // position={position} zoom={currentProp.zoom} scale={scale} rotation={[0, 1.5, 0]}  myHeroProps={myHeroProps[4]} changes={changes}
};

export default Item;
// :<div onClick={() => changeValue(value)}>{value} className={selectedClassName}</div>

// <div style={{width: '100px', height: '100px'}} className={selectedClassName} onClick={() => changeValue(value)}>
//   <Canvas  camera={{position: [90, 14, 0]}}  style={{ background: "#fff"} }>
//     <Model myGlb={require(`assets/glb/hair_rig_${value}.glb`)}  scale={2}  myHeroProps={a} position={[80, 12, 0]} rotation={[0, 1.5, 0]}  />
//
//   </Canvas>
// </div>
