import React, {  useState, useEffect } from "react";
import _ from 'lodash';
import bones from "config/bonesDropdown";
import axis from "config/axis";
import movement from "config/movement";
import Prompt from "./Prompt_4"
import ManualStepChange from "./ManualStepChange"

const Step =  ({step, setStep, currentNodes, index, runWithPrev, setPrev, viewMotions, currentBone, setCurrentBone, selectBone, getStartPosition}) => {
  const defChange = [bones[0].name, movement[0].name, axis[0].name];
  defChange.push(currentNodes[defChange[0]][defChange[1]][defChange[2]])
  const [currentChange, setChangeArr] = useState(defChange);
  const [startValue, setStartValue] = useState(defChange[3]);
  const [isManual, chnageMode] = useState(true);
  const [stepArr, setStepArr] = useState([bones[0].name, 'rotation', 'x', 0]);
  const [value, setValue] = useState(0);

  const changeValue = (val) => {
    // let val = e.target.value;
    // console
    let joined = currentChange.join(' ');
    setValue(val);
    setStep({[joined]: +val + startValue});
  }
  const changeMove = (val, index) => {
    let arr = [...currentChange];
    console.log(arr)
  
    arr[index] = val;
    let newStartValue = currentNodes[arr[0]][arr[1]][arr[2]];
    arr[3] = newStartValue;

    if(!viewMotions.length || Object.values(viewMotions[viewMotions.length - 1])[0] != +value  + startValue) {
      reset();
    } else {
      setValue(0)
    }

    setChangeArr(arr);
    setStartValue(newStartValue);
    if(index === 0 && val != currentBone) {
      setCurrentBone(val)
    }
  }
  const moveByPrompt = (json) => {
    console.log(json)
    // setStep(json)
    const obj = {};
    for(let key in json) {

      const arr = key.split(' ')
      const newStartValue = currentNodes[arr[0]][arr[1]][arr[2]]
      setChangeArr(arr)
      setStartValue(newStartValue);
      arr.push(newStartValue);
      const joined = arr.join(' ');

      obj[joined] = json[key] + newStartValue;
    }
    setStep(obj)

  }
  

  const reset = () => {
    let joined = currentChange.join(' ');
    setStep({[joined]: startValue});
    setValue(0);
  }

  useEffect(() => {
      const newStepArr = step && !_.isEmpty(step) ? Object.keys(step)[0].split(' ') : [bones[0].name, 'rotation', 'x', 0];
      console.log(newStepArr)
      // if(newStepArr[0] !== 'emotions') {
      setStepArr(newStepArr);

      setValue(step ? Object.values(step)[0] - newStepArr[3] : 0)
      // }
      
  }, [step]);
  useEffect(() => {
    changeMove(currentBone, 0)
  }, [currentBone]);
  return (
    <div>
      <ul className="tab-header">
        <li className={isManual ? 'active' : ''} onClick={() => chnageMode(true)}>Manual movement</li>
        <li className={isManual ? '' : 'active'} onClick={() => chnageMode(false)}>AI based movement</li>
      </ul>
      {isManual ? 
        <ManualStepChange
          runWithPrev={runWithPrev} 
          setPrev={setPrev} 
          currentNodes={currentNodes} 
          currentBone={currentBone} 
          getStartPosition={getStartPosition} 
          selectBone={selectBone}
          changeMove={changeMove}
          changeValue={changeValue}
          stepArr={stepArr}
          value={value}
        />
        
        
        :<Prompt moveByPrompt={moveByPrompt} reset={reset}/>
      }
     </div>
  );
};

export default Step;

