import React from "react";
import Header from "components/layout/Header";
import Leftbar from "components/layout/LeftBar";
import Content from "./Content";
import { Helmet } from 'react-helmet-async';

export default (props) => {
  return (
      <div>
        <Helmet>
        <title>{'Default Title'}</title>
        <meta name="description" content={'Default description'} />
        <meta name="image" content={'/assets/message/b1.png'} />
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content={'Default Title'} />
        <meta property="og:description" content={'Default description'} />
        <meta property="og:image" content={'/assets/message/b1.png'} />
        <meta property="og:url" content={window.location.href} />
        {/* Twitter meta tags for social sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={'Default Title'} />
        <meta name="twitter:description" content={'Default description'} />
        <meta name="twitter:image" content={'/assets/message/b1.png'} />
      </Helmet>
         <Header  />
         <Leftbar />
         <Content />
      </div>

  );
};
