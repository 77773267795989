import React  from "react";

const Content =  (props) => {

  return (
    <div className="main-container">
      <div className="dashboard-style">
        <div className="terms-and-conditions">
        <h1>Big Tale Terms & Conditions</h1>
        <p>
          Last Updated: 01.04.2024
        </p>
        <p>
          Welcome to Big Tale! These Terms & Conditions ("Terms") explain the rules
          for using our website ("Site"), including the current Beta version. By
          using the Site, you agree to these Terms.
        </p>
        <h2>Here's the simple stuff:</h2>
        <ul>
          <li>
            Beta Version: This is an early access version of Big Tale, so you
            might encounter some bugs or glitches. We appreciate your patience and
            feedback as we keep improving!
          </li>
          <li>
            Content & Ownership: All the cool stuff on the Site, like designs,
            3D objects, and characters, belongs to Big Tale or our partners. You
            can't copy it without permission.
          </li>
          <li>
            Your Content:
            <ul>
              <li>
                You're free to create awesome characters and stories! You can also
                share your created content with your friends or followers.
              </li>
              <li>
                Respectful & Original: Keep things appropriate and respectful.{' '}
                Do not create content based on copyrighted characters or stories.
              </li>
              <li>
                Our Guidelines: We reserve the right to remove any content that
                violates our guidelines, including copyright infringement.
              </li>
            </ul>
          </li>
          <li>
            Feedback & Ideas: We love hearing your thoughts on the Beta! By
            submitting feedback or ideas, you grant us permission to use them to
            improve Big Tale.
          </li>
          <li>
            Being Nice: Don't bully or harass others on the Site. Everyone's
            here to have fun and create!
          </li>
          <li>
            Following the Rules: We may update these Terms sometimes, so keep
            an eye out.
          </li>
        </ul>
        <h2>Additional Terms for Beta Users:</h2>
        <ul>
          <li>
            Limited Features: The Beta version may not have all the features
            planned for the final release.
          </li>
          <li>
            Changes & Discontinuation: We may change or discontinue the Beta
            version at any time without notice.
          </li>
          <li>
            Data & Privacy for Beta: During the Beta, we will only collect
            your email address. We will use your email to contact you about the
            Beta, including bug fixes, updates, and important announcements. We can
            use your email for marketing purposes with your consent. We will not
            share or sell your data to third parties. Please note: a separate
            Privacy Policy will be implemented for the final release, which will
            detail how we handle user information.
          </li>
          <li>
            Content Changes During Beta: We may need to delete or modify
            user-created content during the Beta phase to fix bugs, maintain
            functionality, or prepare for the final release. We'll make reasonable
            efforts to minimize disruption, but understand that this is a
            possibility when using an unfinished product.
          </li>
        </ul>
        <p>
          If you have any questions, feel free to contact us!
        </p>
      </div>
      </div>
    </div>
  );
};

export default Content;
