import React from "react";
import { NavLink } from "react-router-dom";


function LoginBottom() {
  return (
    <div className="form-bottom">
      <b>
        <NavLink className="navlink" to="/register">
          Register
        </NavLink>
      </b>
      <span>
        {" "}
        <NavLink className="navlink" to="/forgot-password">
          Forgot Password
        </NavLink>
      </span>
    </div>
  );
}

export default LoginBottom;
