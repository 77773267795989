export default {
    face_head: {
        open_mounth: [{
            "face_jaw rotation x 1.9148380423255011": 2.1658380423254737
        }]
    },
    rh_handR: {
        right_victory: [
            {
                "rh_lowerarmR rotation x 0.10907765511519414": 2.109077655115195,
                "rh_lowerarmR rotation z 0.02420497623125973": -0.5257950237687402,
                "rh_lowerarmR rotation y -0.19831222237587126": -0.6983122223758713,
                "rh_handR rotation y -0.16739063603280435": -0.4673906360328043,
                "rh_thumb02R rotation z -0.12545818331803293": 0.6245418166819672,
                "rh_thumb02R rotation x 0.16497864523482783": 1.6649786452348287,
                "rh_pinky02R rotation x -0.046787650218951975": 1.4532123497810487,
                "rh_pinky02R rotation z 0.14251145572220394": 1.642511455722205,
                "rh_pinky03R rotation x -0.02016826639897946": 1.7298317336010216,
                "rh_ring02R rotation x -0.022087014676755883": 1.727912985323245,
                "rh_ring02R rotation z 0.15859937738076757": 1.9085993773807688,
                "rh_ring03R rotation x 0.02142268799117279": 1.7714226879911739,
                "rh_index01R rotation y 0.43576171242137646": 0.9357617124213768
              }
        ]
    },
    lh_handL: {
        left_victory: [
        {
            "lh_lowerarmL rotation x 0.10907773817525937": 2.10907773817526,
            "lh_lowerarmL rotation z -0.02420815235759888": 0.5257918476424011,
            "lh_lowerarmL rotation y 0.19831258935443813": 0.49831258935443806,
            "lh_handL rotation y 0.1673908729321756": 0.4173908729321756,
            "lh_thumb02L rotation z 0.12545312910484555": -0.8745468708951547,
            "lh_thumb02L rotation x 0.16498143674003138": 1.4149814367400322,
            "lh_pinky02L rotation x -0.04679254909087361": -1.5467925490908745,
            "lh_pinky02L rotation z -0.14250966427839076": -0.8925096642783911,
            "lh_pinky03L rotation x -0.020168594754876864": -1.770168594754878,
            "lh_ring02L rotation x -0.022092420643501868": -1.772092420643503,
            "lh_ring02L rotation z -0.15859710209348368": -0.6585971020934838,
            "lh_ring03L rotation x 0.021422474564019908": -1.4785775254359808,
            "lh_index01L rotation y -0.43576060088056573": -0.9357606008805661
        }
        ]

    }
  };
  
  /* @media (max-width: 1200px) {
    }
  }
  @media (max-width: 992) {
    } */
  // @media (max-width: 767px) {
  
  //  }
  