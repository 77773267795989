import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
  align-self: ${({ alignSelf }) => alignSelf || ""};
  justify-content: center;
`;

const InnerWrapper = styled.span`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  margin-right: 18px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 1.28;
  font-weight: 800;
  letter-spacing: .7px;
  color: ${({ theme }) => theme.error};
  text-transform: uppercase;
`;

function LogOutIcon({ onClick, alignSelf }) {
  return (
    <Wrapper alignSelf={alignSelf} onClick={onClick}>
      <InnerWrapper>
        <svg
          width="24"
          height="18"
          viewBox="0 0 24 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.5 0.369257L8.4375 0.369258C8.74688 0.369258 9 0.61719 9 0.920218L9 2.75675C9 3.05978 8.74688 3.30772 8.4375 3.30772L4.5 3.30771C3.67031 3.30771 3 3.96428 3 4.77694L3 13.5923C3 14.405 3.67031 15.0615 4.5 15.0615L8.4375 15.0615C8.74688 15.0615 9 15.3095 9 15.6125L9 17.449C9 17.7521 8.74687 18 8.4375 18L4.5 18C2.01562 18 1.72597e-07 16.0257 3.85332e-07 13.5923L1.156e-06 4.77694C1.36873e-06 2.34353 2.01563 0.369257 4.5 0.369257ZM6.70313 9.59785L14.5781 17.3113C15.2812 18 16.5 17.5179 16.5 16.5308L16.5 12.1231L22.875 12.1231C23.4984 12.1231 24 11.6318 24 11.0212L24 6.61348C24 6.00283 23.4984 5.51156 22.875 5.51156L16.5 5.51156L16.5 1.10387C16.5 0.116735 15.2813 -0.365355 14.5781 0.323344L6.70313 8.03679C6.26719 8.46838 6.26719 9.16626 6.70313 9.59785Z"
            fill="#EB5757"
          />
        </svg>
      </InnerWrapper>
      <Text>
        log out
      </Text>
    </Wrapper>
  );
}

export default LogOutIcon;
