import React, {useState} from "react";
import Button from "components/utils/Btn";

const PropertiesList =  ({heroProperties, setPropertyIndex, index, buttonDisabled, save}) => {
  const selectItem = (i) => {
    setPropertyIndex(i);
  };

  const renderProperties = () => {
    return heroProperties.map((el, i) => {
      const className = i=== index ?  "selected sidebar-item" : "sidebar-item";
      return <li className={className} key={i} onClick={() => selectItem(i)}><div>{el.display_name}</div></li>;
    });
  }
  const aboutIndex = -1;
  const className = index  === aboutIndex ?  "selected sidebar-item" : "sidebar-item";
  return (
      <div className="left-container">
        <ul id="sidebar-nav" className="sidebar-nav">
          <li className={className} key={aboutIndex} onClick={() => selectItem(aboutIndex)}><div>About</div></li>
          {renderProperties()}
        </ul>
        <Button disabled={buttonDisabled} handleClick={save} text='Save'/>
      </div>
  );
};

export default PropertiesList;
