import React from "react";

const InputColor =  ({label, value, changeValue}) => {

  return (
    <div className="title-cont">
      <span className="title">{label}</span>
      <input type="color" value={value || '#000000'} onChange={(e) => changeValue(e.target.value)} />
    </div>
  );
};

export default InputColor;
