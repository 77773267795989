import React from "react";
import Button from "components/utils/Btn";
import { useNavigate } from "react-router-dom";


const Item =  ({movement}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/edit-movement/${movement.id}`);
  }
  return (
    <div className="hero-item">
        <div>{movement.attributes.name}</div>
        <div>{movement.attributes.description}</div>
        <div>{movement.attributes.properties.length}</div>

        <Button handleClick={handleClick} text="Edit"/>

    </div>

  );
};

export default Item;
