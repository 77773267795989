import React, { useEffect, useState } from "react";
import NavigationCircle from "components/utils/NavigationCircle";
import { useSelector, useDispatch } from "react-redux";
import { fetchHeroes } from "store/actions/heroActions";
import { fetchMovements } from "store/actions/movementActions";
import Dropdown from "components/utils/Dropdown";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";


const CharacterChange =  ({changeValue, index, character}) => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.list);
  const { user } = useSelector((state) => state.user);
  const { movementList } = useSelector((state) => state.movementList);
  const [heroOptions, setHeroOptions] = useState([]);
  const [moveOptions, setMoveOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchHeroes(user.id));
    dispatch(fetchMovements(user.id));
  }, []);
  useEffect(() => {
    setOptionsList(list, setHeroOptions)
  }, [list]);
  useEffect(() => {
    setOptionsList(movementList, setMoveOptions)
  }, [movementList]);

  const setOptionsList = (defList, callBackFunc) => {
    if(defList) {
      const newOptions = defList.data.map((el, i) => {return {name: el.attributes.name, index: i}});
      callBackFunc(newOptions);
    }
  }
  const changeHeroPosition = (value, type) => {
    changeValue(value, type, 'hero', index);
  }

  const changeDropdown = (value, options, list, type) => {
    const i = options.findIndex(obj => obj.name === value);
    changeValue(i, `${type}_index`, 'hero', index);

    //changeValue(list.data[i].attributes.properties, type, 'hero', index);
  }

  return (
    <div className="cont-item">

      {
        list ?
        <Dropdown options={heroOptions} label="Character name" selected={heroOptions[character.properties_index]  || ''} name="choose-hero" onChange={(val) => changeDropdown(val, heroOptions, list, 'properties')}/> :''
      }
      {
        movementList ?
        <Dropdown options={moveOptions} label="Movement name" selected={moveOptions[character.movement_index]  || ''} name="choose-move" onChange={(val) => changeDropdown(val, moveOptions, movementList, 'movement')}/> :''
      }
      <h5>Change size:</h5>
      <InputNumberWithArrows type={'scale'} value={character.scale || 1}  changeValue={(val) => changeValue(val, 'scale', 'hero', index)}/>
      <NavigationCircle changeValue={changeHeroPosition} defaultUp={character.up } defaultRight={character.right} defaultZIndex={character.zIndex}/>
      
    </div>

  );
};

export default CharacterChange;
