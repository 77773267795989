import React, {useState, useEffect} from "react";
import PropertiesBar from "components/SelectionBar";
import BgBody from "./BackgroundCont";
import TextCont from "./TextCont";
import CharacterCont from "./CharacterCont";
import LinesCont from "./LinesCont";
import AreaCont from "./AreaCont";
import CameraCont from "./CameraCont";
import TemplateCont from "./TemplateCont";
import PointsCont from "./PointsCont";
import ObjCont from "./AddObjectCont";
import Input from "components/utils/InputNew";
import storyProperties from "config/storyProperties";

const SelectionBar = ({changeBg, changeValue, add, remove, myStory, isPoint, fullStory, page, setStory, storyData, changeData}) => {
  const lastIndex = isPoint || page !== 0 ? 0 : -1;
  // console.log(lastIndex, )
  const [currentPropertyIndex, setPropertyIndex] = useState(lastIndex);

  const setNewPropertyIndex = (index) => {
    let propertiesLength = storyProperties.length;
    let newIndex = index < lastIndex ? lastIndex : index === propertiesLength + lastIndex ? propertiesLength - 1 + lastIndex: index;
    setPropertyIndex(newIndex);
  }
  let properties = [...storyProperties];
  if(isPoint  || page === 0) {
    properties.splice(7, 1);
  }

  useEffect(() => {
    if(page > 0 && currentPropertyIndex === -1) {
      setPropertyIndex(0);
    }
  }, [page]);

  return(
    <div className="properties-cont">
      <PropertiesBar
        page={page}
        index={currentPropertyIndex}
        setPropertyIndex={setNewPropertyIndex}
        properties={properties}
      />
      <div className="props-cont">
        {
          currentPropertyIndex === -1 ?
          <div>
            <Input placeholder="Name" name="name" changeValue={changeData} defaultValue={storyData.name}/>
            <Input placeholder="Description" name="description" changeValue={changeData} defaultValue={storyData.description}/>
          </div>
          : currentPropertyIndex === 0 ?
          <CharacterCont changeValue={changeValue} add={add} characters={myStory.hero} remove={remove}/>
          : currentPropertyIndex === 1 ?
          <BgBody
            currentProperty={storyProperties[currentPropertyIndex]}
            changeValue={changeBg}
            change={changeValue}
            position={myStory.position[0]}
            defaultValue={myStory.background}
            defaultImg={myStory.image}
          />
          : currentPropertyIndex === 2 ?
          <TextCont changeValue={changeValue} add={add} remove={remove} texts={myStory.text}/>
          : currentPropertyIndex === 3 ?
          <LinesCont changeValue={changeValue} add={add} remove={remove} lines={myStory.lines}/>
          : currentPropertyIndex === 4?
          <AreaCont changeValue={changeValue} add={add} remove={remove} areas={myStory.areas}/>
          : currentPropertyIndex === 5 ?
          <CameraCont changeValue={changeValue} camera={myStory.camera[0]}/>
          : currentPropertyIndex === 6 ?
          <ObjCont changeValue={changeValue} add={add} objects={myStory.objects} remove={remove}/>
          : currentPropertyIndex === 7 && !isPoint && page > 0 ?
          <PointsCont changeValue={changeValue} add={add} remove={remove} points={myStory.points} changeBg={changeBg}  page={page} fullStory={fullStory} setStory={setStory}/>
          :''
        }

      </div>
    </div>
  )
}

export default SelectionBar;
