import { userActions } from "../types";

const initialState = {
  user: null,
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.FETCH_USER_PENDING:
      return {
        ...state,
        user: {...state.user},
      };
    case userActions.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload || null,
      };
    case userActions.FETCH_USER_FAILED:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default userDataReducer;
