import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import StyledButton from "components/utils/StyledButton";
import Header from "components/layout/Header";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;

  @media (max-width: 767px) {
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 768px) {
    margin-top: 68px;
  }
`;

const WrapperLeft = styled.div`
  margin: 100px 0 0 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.white};

  @media (max-width: 767px) {
    align-items: center;
    margin: 100px 0 0 0px;
  }
`;

const Span1 = styled.span`
  margin: 30px 0 100px 0;
  width: 370px;
  height: 95px;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 120%;
  color: ${({ theme }) => theme.blue_Grey};

  @media (max-width: 767px) {
    text-align: center;
    width: 300px;
    height: 180px;
    margin: 30px 0 50px 0;
  }
`;



function Error404() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  return (
    <>
      <Header mobileDisabled />
      <Wrapper>
        <WrapperLeft>
          <Span1>Sorry, page not found</Span1>
          <NavLink to="/landing" style={{ textDecoration: "none" }}>
            <StyledButton fontSize="18px" width="286px" height="52px">
              Go to main screen
          </StyledButton>
          </NavLink>
        </WrapperLeft>
      </Wrapper>
    </>
  );
}

export default Error404;
