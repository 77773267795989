import React from "react";
import Header from "components/layout/Header";
import MainContent from "./MainContent";


export default (props) => {

  return (
      <div className="draggable">
         <Header  />
         <MainContent />
      </div>
  );
};
