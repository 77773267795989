import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";

import Logo from "components/iconsAndLogos/Logo";
import Container from "components/utils/Container";
// import LanguageSelect from "./components/LanguageSelect";
import UserImageIcon from "components/iconsAndLogos/UserImageIcon";
import colors from "config/colors";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.white};
  z-index: 99999;

  height: ${({ active }) => (active ? "100%" : "auto")};
`;

const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuListBox = styled.div`
  align-items: center;
  height: 100%;
  padding: 50px 16px 0;
  justify-content: center;
  flex-direction: column;
  transition: all 350ms;

  display: ${({ active }) => (active ? "flex" : "none")};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: ${({ active }) => (active ? "translateY(0)" : "translateY(100%)")};
`;

const MenuListItem = styled.li`
  font-size: 18px;
  line-height: 1.25;
  font-weight: 800;
  margin-bottom: 50px;

  & > a {
    color: #000;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
  }
`;

const MenuList = styled.ul`
  text-align: center;

  ${MenuListItem} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const BurgerBoxLine = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  height: 2px;
  border-radius: 10px;
  transition: transform 250ms;
  background-color: ${({ theme }) => theme.logo_Red};
`;

const BurgerBox = styled.div`
  position: relative;
  width: 20px;
  height: 16px;
  cursor: pointer;

  ${BurgerBoxLine} {
    &:nth-child(1) {
      top: ${({ active }) => (active ? "6px" : "0")};
      transform: ${({ active }) => (active ? "rotate(45deg)" : "")};
      width: ${({ active }) => (active ? "25px" : "100%")};
    }

    &:nth-child(2) {
      display: ${({ active }) => (active ? "none" : "block")};
      top: 7px; // 5px margin + 2px height
    }

    &:nth-child(3) {
      bottom: ${({ active }) => (active ? "8px" : "0")};
      transform: ${({ active }) => (active ? "rotate(-45deg)" : "")};
      width: ${({ active }) => (active ? "25px" : "100%")};
    }
  }
`;

const UserText = styled.p`
  font-size: 18px;
  line-height: 1.25;
  font-weight: 900;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.text_Grey};
  margin-left: 14px;
`;

const HeaderMobile = ({
  userId,
  links,
  children
}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = useCallback(
    (e, url) => {
      e.preventDefault();
      navigate(url);
      setMenuOpened(false);
    },
    [navigate]
  );

  const activeStyle = { color: colors.blue, borderColor: colors.blue }

  return (
    <Wrapper active={menuOpened}>
      <Container>
        <NavBar>


        <div>
          <Link to={userId ? '/account' : '/'}>
            <Logo />
          </Link>
        </div>


        <div>
          <BurgerBox
            active={menuOpened}
            onClick={() => setMenuOpened(!menuOpened)}
          >
            <BurgerBoxLine />
            <BurgerBoxLine />
            <BurgerBoxLine />
          </BurgerBox>
        </div>


          {
            //children ? children : <LanguageSelect />
          }


        </NavBar>
      </Container>

      <MenuListBox active={menuOpened}>
        <NavLink
          to={userId ? "/account" : "/login"}
          onClick={() =>setMenuOpened(false)}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "81px",
          }}
        >
          <UserImageIcon />

          <UserText>{userId ? "Account" : "SIGN IN"}</UserText>
        </NavLink>
          <MenuList>
            {links.map((link) => (
              <MenuListItem key={link.name.split("")}>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }
                  to={link.url}
                  onClick={(e) => handleNavigate(e, link.url)}
                >
                  {link.name}
                </NavLink>
              </MenuListItem>
            ))}
          </MenuList>
      </MenuListBox>
    </Wrapper>
  )
};

HeaderMobile.propTypes = {
  userId: PropTypes.number || undefined,
  links: PropTypes.array,
  children: PropTypes.node
};

export default HeaderMobile;
