import React, {useState, useRef} from "react";
import ModelCanvas from "pages/CreateHero/ModelCanvas";
import storyBody from "config/storyBody";
import AddBtn from "components/utils/AddBtn";
import { Link } from 'react-router-dom';
import Button from "components/utils/Btn";


const Pages = ({story, setStory, setPage, selected, showStory, isVerified, save, parent}) => {
  // const [pages, setPagesList] = useState([1]);
  // const [selected, setSelected] = useState(0);
  const bottomScroll = useRef();
  const lastPageIndex = story.length - 1;

  const addPage = () => {
    let arr = [...story];
    let newObj = JSON.parse(JSON.stringify(storyBody));
    arr.push(newObj);
    setStory(arr);
    let selected = arr.length - 1
    setPage(selected);
    setTimeout(() => {
      bottomScroll.current.scrollIntoView({behavior: 'smooth'});
    }, 100)

  }

  const removePage = (index) => {
    let arr = [...story];
    const newArray = arr.slice(0, index).concat(arr.slice(index + 1));

    // if(selected === newArray.length) {
    //   // console.log(selected - 1, newArray)
    //   setPage(selected - 1);
    // }
    setStory(newArray);

    // console.log(newArray)
  }

  const isPagePointExist = (points) => {
    return points.some(obj => obj.isPage && obj.storyId)
  }
  const renderPointsLink = (points) => {
    return points.map((point, i) => {
      return point.storyId && point.isPage ?
        <Link key={i} to={`/edit-story/${point.storyId}`} style={{ textDecoration: 'none' }} target="_blank">
          <div className="sidebar-item">Child Story {i}</div>
        </Link>:''
    })
  }


  return(
    <div className="left-container pages">
      <div className="ul-cont">
        {
          parent ?
          <Link to={`/edit-story/${parent}`} style={{ textDecoration: 'none' }} target="_blank">
            <div className="sidebar-item">Parent</div>
          </Link>
          :''
        }
        <ul>
          {
            story.map((el, index) => {
                let className = selected === index ? "page-item selected": "page-item";
                return <li className={className} key={index} onClick={() => setPage(index)} >
                  <ModelCanvas myStory={el} currentProp={{scale: 1.5}}/>
                  {
                    index > 0 ?
                    <div className="remove-icon" onClick={() => removePage(index)}>X</div>
                    :''
                  }
                </li>
            })
          }
          <div ref={bottomScroll} />
      </ul>
      </div>
      {
        isVerified ?
        <div className="pages-buttons">
          {
            story[lastPageIndex].points[0] && isPagePointExist(story[lastPageIndex].points) ?
            renderPointsLink(story[lastPageIndex].points)

            :<Button handleClick={addPage} text="New page"/> 
          }

          <div>
            <Button  className="view-story" handleClick={() => showStory(true)} text="View story" />
            <Button handleClick={save} text="Save"/>
          </div>
        </div>:''
      }
    </div>
  )
}

// 'Please fill in all fields before saving.'

export default Pages;
