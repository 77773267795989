import endPoint from "config/endPoint";
import { userActions } from "../types";

import customToastSuccess from "components/utils/CustomToast/customToastSuccess";
import customToastError from "components/utils/CustomToast/customToastError";

export const fetchUser = () => {
  const fetchUserSuccess = (user) => ({
    type: userActions.FETCH_USER_SUCCESS,
    payload: user,
  });

  const fetchUserFailed = () => ({
    type: userActions.FETCH_USER_FAILED,
    payload: null,
  });

  const fetchUserPending = () => ({
    type: userActions.FETCH_USER_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchUserPending());
    return fetch(`${endPoint.endpoint}/users/me`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if(json.error) {
          localStorage.removeItem("token");
        }
        dispatch(fetchUserSuccess(json))

      })
      .catch(() => dispatch(fetchUserFailed));
  };
};

export const logoutUser = (push) => {
  const fetchUserSuccess = () => ({
    type: userActions.FETCH_USER_SUCCESS,
    payload: null,
  });

  const fetchUserFailed = () => ({
    type: userActions.FETCH_USER_FAILED,
    payload: null,
  });

  return async (dispatch) => {
    localStorage.removeItem("token");
    return dispatch(fetchUserSuccess())
  //   return fetch(`${endPoint.endpoint}/auth/local/logout`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then(() => {
  //       localStorage.removeItem("token");
  //       dispatch(fetchUserSuccess())
  //     })
  //     .catch(() => dispatch(fetchUserFailed()));
  };
};

export const loginUser = (data, push) => {
  const fetchUserFailed = () => ({
    type: userActions.FETCH_USER_FAILED,
    payload: null,
  });

  const fetchUserPending = () => ({
    type: userActions.FETCH_USER_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchUserPending());
    return fetch(`${endPoint.endpoint}/auth/local`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.jwt) {
          localStorage.setItem("token", json.jwt);
          // console.log(localStorage)
          return dispatch(fetchUser(json));
        }

        return json;
      })
      .catch(() => dispatch(fetchUserFailed));
  };
};

export const registerUser = (data, push) => {
  // console.log(data)
  const fetchUserFailed = () => ({
    type: userActions.FETCH_USER_FAILED,
    payload: null,
  });

  const fetchUserPending = () => ({
    type: userActions.FETCH_USER_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchUserPending());
    return fetch(`${endPoint.endpoint}/auth/local/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.jwt) {
          localStorage.setItem("token", json.jwt);

          return dispatch(fetchUser(json));
        }

        return json;
      })
      .catch(() => dispatch(fetchUserFailed));
  };
};

export const updateUser = (data) => {
  return async (dispatch) => {
    return fetch(`${endPoint.endpoint}/api/landlord/edit-profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.access_token) {
          localStorage.setItem("token", json.access_token);
          dispatch(fetchUser());
        }
        return json;
      });
  };
};


export const forgotPassword = async (email) => {
  return fetch(`${endPoint.endpoint}/auth/forgot-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email }),
  })
    .then((res) => res.json())
    .then((json) => {
      if (json.ok) {
        customToastSuccess('Check email');
      } else {
        const text = json.error || json;
        customToastError(text);
      }
      return json;
    })

    .catch(console.log);
};

export const resetPassword = async (data) => {
  console.log(data)
  return fetch(`${endPoint.endpoint}/auth/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((json) => {
      if (json.error) {
        customToastError(json.error.message);
      } else {
        localStorage.setItem("token", json.jwt);
      }
      return json;
    })
    .catch(console.log);
};

