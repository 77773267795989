import { toast } from "react-toastify";

export default function (err) {
  return toast(err, {
    className: "toast-success",
    bodyClassName: "toast-font",
    progressClassName: "toast-progressBar",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
