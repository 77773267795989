import React, {useEffect, useState} from "react";

const Dropdown =  ({options, name, onChange, selected, label}) => {
  const [value, setValue] = useState('');
  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  }

  useEffect(() => {
    setValue(selected)
  }, [selected]);

  return (
    <div>
      <label>{label}</label>
      <select name={name} value={value && typeof value === 'object' ? value.name : value} className={name} onChange={handleChange}>
        {
          options.map((el, index) => {
            return <option value={el.name} key={index}>{el.display_name || el.name}</option>
          })
        }
      </select>

    </div>

  );
};

export default Dropdown;
