import React  from "react";

const Content =  (props) => {

  return (
    <div className="main-container">
        <div className="give-feedback-cont">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScyMpFdlH3OVCLXRnPOf9njakcSNjJLDUa-FMTCDI1ZaLKdqw/viewform?embedded=true" >Loading…</iframe>
        </div>
    </div>
  );
};

export default Content;
