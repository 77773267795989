import React from "react";

const Item =  ({value, changeValue, selected, color}) => {
  const selectedClassName = selected ? 'selected' : '';
  const bg = value || `url(${require('assets/heroProps/none.jpg')})`;
  const styles = {backgroundImage: bg}
  return (
    <div>
        <div style={styles} className={`color-items ${selectedClassName}`} onClick={() => changeValue(value, 'image')}></div>
    </div>

  );
};

export default Item;
