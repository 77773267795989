export default [
  {display_name:"Gender",  type: "nodes", index:0, name:"type", values: {b:['b', 'g'], g:['b', 'g']}},
  {display_name:"Body type and color",  type: "nodes", index:1, name:"type", values: {b: ['','0'], g: ['','0']}},
  {display_name:"TShirt type and color",  type: "nodes", index:2, name:"type", values: {b:['','0','1', '2' ], g:['','0','1', '2','3', '4', '5', '6'] }},
  {display_name:"Short type and color",    type: "nodes", index:3, name:"type", values: {b:['', '0','1', '2'], g:['', '0','1', '2', '3',  '4', '5']}},
  {display_name:"Hairstyle and color", zoom: 10,  position: [0, -1.25, 0], type: "nodes", name:"type", index: 4, values: {b: ['', '0','1', '2', '3'], g: ['', '0','1', '2', '3', '4', '5', '6','7', '8', '9','10', '11', '12', '13']}},
  {display_name:"Eyes type and color",  type: "nodes", zoom: 10,  position: [0, -1.25, 0], index: 5, name:"type", values: {b: ['0'], g: ['0']}},
  {display_name:"Eyebrows type and color",  type: "nodes", zoom: 10,  position: [0, -1.25, 0], index: 6, name:"type", values: {b: ['0'], g: ['0']}},
  {display_name:"Accessories type and color",  type: "nodes", index: 7, name:"type", values: {b: ['', '0', '1', '2'], g: ['', '0', '1', '2']}},
  {display_name:"Shoes type and color",  type: "nodes", index: 8, name:"type", values: {b: ['', '0','1'], g: ['', '0','1']}},
  
  // {display_name:"Nose type and color",  type: "nodes", zoom: 10,  position: [0, -1.25, 0], index: 7, name:"type", values: ['0','1', '2','3', '4', '5']},
  // {display_name:"Mouth type and color",  type: "nodes", zoom: 10,  position: [0, -1.25, 0], index: 8, name:"type", values: ['0','1', '2' ]},
  // {display_name:"Ears type and color",  type: "nodes", zoom: 10,  position: [0, -1.25, 0], index: 9, name:"type", values: ['0','1', '2','3', '4', '5']},
  // {display_name:"Head type and color",  type: "nodes", zoom: 10,  position: [0, -1.25, 0],   index: 10, name:"type", values: ['0','1', '2','3', '4', '5', '6']},
 
]
