import React from "react";
import ModelCanvas from "pages/CreateHero/ModelCanvas";
import Button from "components/utils/Btn";
import { useNavigate } from "react-router-dom";

const Item =  ({story}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/edit-story/${story.id}`);
  }
  const viewStory = () => {
    navigate(`/view-story/${story.id}`);
  }

  return (
    <div className="hero-item">
        <div>Name: {story.attributes.name}</div>
        <div className="model-cont" onClick={viewStory}>
          <ModelCanvas myStory={story.attributes.properties[0]}/>
        </div>
        
        <div>Desc: {story.attributes.description}</div>
        <div>Author: {story.attributes.author}</div>
        <div>Page:{story.attributes.properties.length}</div>
        <Button handleClick={handleClick} text="Edit"/>

    </div>

  );
};

export default Item;
