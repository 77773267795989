import React from "react";

const Item =  ({selectedIndex, index, last, selectItem, removeStep}) => {

  const className = index=== selectedIndex ?  "selected sidebar-item" : "sidebar-item";

  return (
    <li className={className} onClick={() => selectItem(index)}>
      <div>{`Step ${index + 1}`}</div>
      {
        index !== last ?
          <div className="remove-icon" onClick={() => removeStep(index)}>X</div>
        :''
      }

    </li>

  );
};

export default Item;
