import React , {useRef} from "react";
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import {useLoader} from '@react-three/fiber';
import DragControl from "./Dragable";

const Area =  ({areaProps, index, myStory, changeValue}) => {
  const categories = [{name: 0, display_name: 'bg', count: 14}, {name: 1, display_name: 'effect', count: 1}]
  const category = categories[areaProps.category] || categories[0];
  const n = areaProps.bg && areaProps.bg <= category.count ? areaProps.bg : 1;
  const texture = useLoader(TextureLoader, require(`assets/bg/${category.display_name}_${n}.png`));
  const width = areaProps.width || 4;
  const height = areaProps.height || 4;
  const right = +areaProps.right || 0;
  const up = areaProps.up || 0;
  const zIndex = +areaProps.zIndex || 0;
  const areaRef = useRef('');
 
  return (
    <DragControl  property='areas' elRef={areaRef} index={index} myStory={myStory} changeValue={changeValue}>
        <mesh position={[right, up, zIndex]} transparent>
          <planeGeometry args={[width, height]} />
          <meshBasicMaterial toneMapped={false} attach="material" color={areaProps.color || '#ffffff'}  />
        </mesh>
        <mesh  ref={areaRef} position={[right, up, zIndex]}>
          <planeGeometry args={[width, height]} />
          <meshBasicMaterial attach="material"  toneMapped={false}  transparent map={texture} emissiveIntensity={0} roughness={'0.0'} />
        </mesh>
    </DragControl>
  );
};
export default Area;