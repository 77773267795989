import React, { Suspense, useState, useRef } from "react";
import "styles/heroStyles.scss";
import { Canvas } from '@react-three/fiber'
import Spinner from "components/spinner";
import ModelAllParts  from "./ModelAllParts";
import { OrbitControls, Star, DragControls} from "@react-three/drei";
import Text from "./Text";
import Line from './Line';
import CustomArea from "./CustomArea";
import BonePoint from "./BonePoint";
import Point from "./Point";
import Input from "components/utils/InputNew";
import CustomObj from "./CustomObject";
import Watermark from "./Watermark";
import Camera  from "./Camera";
import {Popup} from "components/utils/Popup";
import Button from "components/utils/Btn";
import heroProperties from "config/heroProperties";
import myHero from "config/myHeroProps";
import { setCurrentStory } from "store/actions/storyActions";
import { useSelector, useDispatch } from "react-redux";
import {deepCopy} from "components/utils/helperFunctions";
import bones from "config/bones";




const HeroCanvas =  ({currentProp = heroProperties, myHeroProps = myHero,  changes, getStartPosition, myStory, page, currentNodes, selectBone, currentBone, changeValue, parent}) => {
  const dispatch = useDispatch();
  const { story } = useSelector((state) => state.story);
  // const { list } = useSelector((state) => state.list);
  // const { movementList } = useSelector((state) => state.movementList);
  const [isPopupShown, showPopup ] = useState(false);
  const inputValue = useRef('');
  const pointProps = useRef({});
  const storyRef = useRef(story);
  

  const functions = {
    changeValue: (value, type, property, index) => {
      // console.log(story)
      let myNewObj = storyRef.current ? deepCopy(storyRef.current) : deepCopy(story);
      let idx = index || 0;
      if(type === 'textProps') {
        // console.log(value)
        myNewObj.data.attributes.properties[page][property][idx] = value;
        storyRef.current = myNewObj;
        dispatch(setCurrentStory(storyRef.current));
        return
      }
      
      if(!myNewObj.data.attributes.properties[page][property][idx]) {
        myNewObj.data.attributes.properties[page][property][idx] = {};
      }
      myNewObj.data.attributes.properties[page][property][idx][type] = value;
      // if(type === 'properties_index') {
      //   myNewObj.data.attributes.properties[page][property][idx].properties = list.data[value].attributes.properties
      // } else if(type === 'movement_index') {
      //   // console.log(movementList.data[value].attributes.properties)
      //   myNewObj.data.attributes.properties[page][property][idx].movement = movementList.data[value].attributes.properties
      // }
      storyRef.current = myNewObj;
      dispatch(setCurrentStory(storyRef.current));
    },
    changeBg: (value, type = "background") => {
      let currentType = type || "background"
      let myNewObj = storyRef.current ? deepCopy(storyRef.current) : deepCopy(story);
      const val = currentType === 'image' && value ? `url(${require('assets/bg/bg_' + value +'.png')})` : value;
     
      myNewObj.data.attributes.properties[page][currentType] = val;
      storyRef.current = myNewObj;
      dispatch(setCurrentStory(storyRef.current));
    }
  }
  const getBackground = () => {
    if(myStory) {
      const isNotGradient = myStory.background.length === 7; 
      let background = isNotGradient ? myStory.background : `linear-gradient(${myStory.background})`;
      if(myStory.image) {
        let backgroundPosition = `${myStory.position[0].right}% ${myStory.position[0].up}%`;
        if(isNotGradient) {
          return {background:`${myStory.image}${myStory.background} ${backgroundPosition}`};
        }
        // let colors = myStory.background
        return {background:`${myStory.image} ${backgroundPosition}, ${background}`};
      }
      
      return {background: background}
    }
    return {background:"#ffffff"};

  }

  const changeData = (value) => {
    inputValue.current = value;
  }

  const save = () => {
    const newTextIndex = myStory.text.length;

    const newTextObj = {
      right:+pointProps.current.right || 0,
      up: +pointProps.current.up || 0,
      texture_right:+pointProps.current.right || 0,
      texture_up: +pointProps.current.up || 0,
      bg: '1',
      content: inputValue.current
    }


    functions.changeValue(newTextObj, 'textProps','text', newTextIndex);
    showPopup(false);
  }

  const beforeShowPopup = (newProps) => {
    pointProps.current = newProps;
    showPopup(true);
  }
 
 
  // const up = textProps.up || 10;
  // const z = right + (width/2) - 2;
  // const handleClick = () => {
  //   if(myStory) {
  //     myStory.hero.forEach((item, i) => { 
  //       //el.movement or temp ?
  //     });
  //
  //   }
  //   console.log('aaaaa')
  // }
  // <ambientLight intensity={1} />
  // <spotLight intensity={1} position={[600, 500, 600]} />
  // <directionalLight/>
  const a = () => {
    console.log(1)
  }
  
  return (
    <Suspense fallback={<Spinner />} >
      <Canvas className="hero-cont" style={getBackground()} camera={{zoom: 2.5}}>

        <ambientLight intensity={0.5} />
        <spotLight intensity={0.5} position={[600, 500, 600]} />
        <Camera zoom={currentProp.zoom || 2.5} cameraProps={myStory ? myStory.camera[0] : null}/>
        {/* <pointLight position={[1, 5, 3]} /> */}
        {/* <ambientLight intensity={0.5} />
      <directionalLight position={[1, 2, 1]} intensity={1} />
      <perspectiveCamera position={[0, 0, 5]} /> */}


        {
          myStory  ?
          myStory.hero.map((el, index) => <ModelAllParts onClick={a} key={index} changeValue={changeValue} currentProp={currentProp} myHeroProps={el.properties ? [...el.properties] : [...myHeroProps]} changes={el.movement ? el.movement : changes} getStartPosition={getStartPosition} myStory={myStory} index={index}/>)
          : <ModelAllParts currentProp={currentProp} myHeroProps={[...myHeroProps]} changes={changes} getStartPosition={getStartPosition} myStory={myStory} />
        }

        {
          myStory && myStory.text[0] && myStory.text[0].content ?
          myStory.text.map((el, index) => <Text key={index} textProps={el} myStory={myStory} index={index} changeValue={changeValue}/>)
          : ''
        }
        {
          myStory && myStory.lines[0] ?
          myStory.lines.map((el, index) => <Line key={index} linesProps={el} myStory={myStory} index={index} changeValue={changeValue}/>)
          : ''
        }
        {
          myStory && myStory.areas[0] ?
          myStory.areas.map((el, index) => <CustomArea key={index} areaProps={el} myStory={myStory} index={index} changeValue={changeValue}/>)
          : ''
        }

        {
          myStory && myStory.points[0] ?
          myStory.points.map((el, index) => <Point key={index} pointProps={el} functions={functions} showPopup={beforeShowPopup} myStory={myStory} index={index} changeValue={changeValue}/>)
          : ''
        }

{
          myStory && myStory.objects[0] ?
          myStory.objects.map((el, index) => <CustomObj key={index} objProps={el} myStory={myStory} index={index} changeValue={changeValue}/>)
          : ''
        }

        {
          currentNodes ?
          bones.map((el, index) => currentNodes[el.name] ? <BonePoint key={index} bone={currentNodes[el.name]} name={el.name} selectBone={selectBone} currentBone={currentBone}/>: '')
          : ''
        }
        {
          parent ? 
          <Watermark id={parent} />:''
        }
        
         {/* <OrbitControls />  */}
      </Canvas>

      {
        isPopupShown ?
        <Popup show={isPopupShown}  onClose={() => showPopup(false)}>
          <Input placeholder="type text" name="interactiveText" changeValue={changeData} defaultValue={inputValue.current}/>
          <Button handleClick={save} text="Save"/>
        </Popup>:''
      }

    </Suspense>
  );
};
{/* <OrbitControls /> */}
// <div className="my-text">Hello detka</div>
export default HeroCanvas;
