import React from "react";
import { NavLink } from "react-router-dom";



function ForgotPasswordBottom() {
  return (
    <div className="form-bottom">
      <b>
        {" "}
        <NavLink className="navlink" to="/login">
          Back to Login
        </NavLink>
      </b>
    </div>
  );
}

export default ForgotPasswordBottom;
