import React, {useState, useEffect,useRef} from "react";
import {playMovements} from "components/utils/helperFunctions";
import "styles/heroStyles.scss";
import Model  from "./Model";
import DragControl from "./Dragable";



const ModelCont =  ({currentProp, myHeroProps, changes, getStartPosition, myStory, index, changeValue}) => {
  const groupRef = useRef('');

  let position = currentProp.position ? [...currentProp.position]: [0, -1, 0];
  const [currChanges, setChanges] = useState(changes);
  const gender = myHeroProps[0].type;

  let scale = 1;
  if(myStory && myStory.hero[index]) {
    position[0] = myStory.hero[index].right || 0;
    position[1] = myStory.hero[index].up || -1;
    position[2] = +myStory.hero[index].zIndex || 0;
    scale = myStory.hero[index].scale || scale;
  }
  const cameraProps = myStory ? myStory.camera[0] : null;
  useEffect(() => {
    setChanges(changes)
  }, [changes]);
  const playStep = (val) => {
    setTimeout(()=> {
      setChanges(val)
    },10)
  }
  const handleClick =() => {
    if(myStory && myStory.hero[index].movement) {
      let newMotions = myStory.hero[index].movement;
      playMovements(newMotions,  playStep);

    }
  }
  
  // const up = textProps.up || 10;
  // const z = right + (width/2) - 2;
  //<group dispose={null} onPointerMissed={handleClick}>
  return (
    <DragControl  property='hero' elRef={groupRef} index={index} myStory={myStory} changeValue={changeValue}>
      <group dispose={null} ref={groupRef}  position={position}  onClick={handleClick}>
        {
        //<Model myGlb={`/glb/hair_rig_${myHeroProps[3].type}.glb`} position={currentProp.position} changes={changes} scale={6} rotation={[0, 1.5, 0]} property={property} value={value} coord={coord} name={name} myHeroProps={myHeroProps[3]} newPosition={position}/>
        // <Model myGlb={require('assets/glb/big_model_rig_008.glb')} position={currentProp.position} changes={changes} scale={6} rotation={[0, 1.5, 0]} property={property} value={value} coord={coord} name={name} myHeroProps={myHeroProps[0]} newPosition={position}/>
          //////////////
          // <Model myGlb={require(`assets/glb/head_${myHeroProps[4].type}.glb`)}  position={position} zoom={currentProp.zoom} scale={scale}  myHeroProps={myHeroProps[4]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
          // <Model myGlb={require(`assets/glb/eye_${myHeroProps[5].type}.glb`)}  position={position}  zoom={currentProp.zoom} scale={scale}  myHeroProps={myHeroProps[5]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
          // <Model myGlb={require(`assets/glb/nose_${myHeroProps[6].type}.glb`)}  position={position} zoom={currentProp.zoom} scale={scale}   myHeroProps={myHeroProps[6]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
          // <Model myGlb={require(`assets/glb/mouth_${myHeroProps[7].type}.glb`)}  position={position}  zoom={currentProp.zoom} scale={scale} myHeroProps={myHeroProps[7]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
          // <Model myGlb={require(`assets/glb/ears_${myHeroProps[8].type}.glb`)}  position={position}  zoom={currentProp.zoom} scale={scale}  myHeroProps={myHeroProps[8]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
          // <Model myGlb={require(`assets/glb/eyebrows_${myHeroProps[9].type}.glb`)}  position={position}  zoom={currentProp.zoom} scale={scale}  myHeroProps={myHeroProps[9]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
          // {
          //   myHeroProps[3].type ?
          //   <Model myGlb={require(`assets/glb/hair_rig_${myHeroProps[3].type}.glb`)} position={position} zoom={currentProp.zoom} scale={scale}  myHeroProps={myHeroProps[3]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
          //   :''
          // }
          // <Model myGlb={require(`assets/glb/body_rig_${myHeroProps[0].type}.glb`)} position={position}   zoom={currentProp.zoom} scale={scale} myHeroProps={myHeroProps[0]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
          // {
          //   myHeroProps[1].type ?
          //   <Model myGlb={require(`assets/glb/topic_${myHeroProps[1].type}.glb`)}  position={position} zoom={currentProp.zoom} scale={scale}  myHeroProps={myHeroProps[1]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
    
          //   :''
          // }
          // {
          //   myHeroProps[2].type ?
          //   <Model myGlb={require(`assets/glb/pants_${myHeroProps[2].type}.glb`)}  position={position}  zoom={currentProp.zoom} scale={scale} rotation={[0, 1.5, 0]}  myHeroProps={myHeroProps[2]} changes={currChanges}  getStartPosition={getStartPosition} cameraProps={cameraProps}/>
    
          //   :''
          // }
        }
      {
        myHeroProps[1].type ?
        <Model myGlb={require(`assets/glb/body_${gender}_${myHeroProps[1].type}.glb`)}   scale={scale}  myHeroProps={myHeroProps[1]} changes={currChanges}  getStartPosition={getStartPosition} />
        :''
      }
      {
        myHeroProps[2].type ?
        <Model myGlb={require(`assets/glb/shirts_${gender}_${myHeroProps[2].type}.glb`)}  scale={scale}  myHeroProps={myHeroProps[2]} changes={currChanges}  getStartPosition={getStartPosition} />
        :''
      }
      {
        myHeroProps[3].type ?
        <Model myGlb={require(`assets/glb/pants_${gender}_${myHeroProps[3].type}.glb`)}  scale={scale}  myHeroProps={myHeroProps[3]} changes={currChanges}  getStartPosition={getStartPosition} />
        :''
      }
      {
        myHeroProps[4].type ?
        <Model myGlb={require(`assets/glb/hair_${gender}_${myHeroProps[4].type}.glb`)}   scale={scale}  myHeroProps={myHeroProps[4]} changes={currChanges}  getStartPosition={getStartPosition} />
      :''
      }
      <Model myGlb={require(`assets/glb/eyes_${gender}_${myHeroProps[5].type}.glb`)} scale={scale}  myHeroProps={myHeroProps[5]} changes={currChanges}  getStartPosition={getStartPosition} />
      <Model myGlb={require(`assets/glb/eyebrows_${gender}_${myHeroProps[6].type}.glb`)}  scale={scale}  myHeroProps={myHeroProps[6]} changes={currChanges}  getStartPosition={getStartPosition} />
      {
      myHeroProps[7].type ?
      <Model myGlb={require(`assets/glb/acc_${gender}_${myHeroProps[7].type}.glb`)}  scale={scale}  myHeroProps={myHeroProps[7]} changes={currChanges}  getStartPosition={getStartPosition} />
      :'' 
      }
      {
      myHeroProps[8].type ?
      <Model myGlb={require(`assets/glb/shoes_${gender}_${myHeroProps[8].type}.glb`)}   scale={scale}  myHeroProps={myHeroProps[8]} changes={currChanges}  getStartPosition={getStartPosition} />
      :'' 
      }
      </group>
    </DragControl>
  )

};



// <div className="my-text">Hello detka</div>
export default ModelCont;
