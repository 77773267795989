import React, {useState} from "react";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";


const NavigationCircle =  ({changeValue, defaultRight=0, defaultUp=0, defaultZIndex=0, stepX=0.25, stepY=0.25, stepZ=0.1, hideZindex}) => {
  return (
    <div>
      <h5>Change position</h5> 
      <InputNumberWithArrows type='x' value={defaultRight} changeValue={(val) => changeValue(val, 'right')} step={stepX}/>
      <InputNumberWithArrows type='y' value={defaultUp} changeValue={(val) => changeValue(val, 'up')} step={stepY}/>
      {
        !hideZindex ? 
        <div>
          <h5>Change order to</h5> 
          <InputNumberWithArrows type='z' value={defaultZIndex} changeValue={(val) => changeValue(val, 'zIndex')} step={stepZ} max={1000} />
        </div>:''
      }
    </div>
  );
};

export default NavigationCircle;
