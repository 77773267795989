import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import theme from "./config/colors";

import store from "./store/store";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "styles/reset.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <HelmetProvider>  {/* Wrap App with HelmetProvider */}
            <App />
          </HelmetProvider>
        </Provider>

      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,

);

reportWebVitals();
