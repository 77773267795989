import React from "react";
import {Vector3,CircleGeometry} from 'three';

const Point =  ({name, bone, selectBone, currentBone}) => {
  const bonePosition = new Vector3();
  bone.getWorldPosition(bonePosition);
  const color = name === currentBone ? 'red' : 'green';

  const handleClick = () => {
    selectBone(name);
  }
  let {x, y, z} = bonePosition;
 // console.log(name)
  if(name === 'pelvis') {
     y = y + 1;
  }

  return (
      <mesh>
        <mesh  position={[x, y - 1, z + 0.2]} scale={0.07 } onClick={handleClick}>
          <sphereGeometry attach="geometry" args={[1,32,32]}/>
          <meshBasicMaterial attach="material" color={color} transparent opacity={0.1}/>
        </mesh>
        <line  position={[x, y -1, z + 0.2]} scale={0.07 }>
          <edgesGeometry args={[new CircleGeometry(1, 32)]} />
          <lineBasicMaterial color={color} linewidth={2} />
        </line>
      </mesh>

  );
};
export default Point;
