export default [
  {
    background: '#ffffff',
    image: '',
    position: [{right: 0, up: 0}],
    text: [],
    hero: [],
    lines: [],
    areas: [],
    camera: [{x: 0, y:0, z:0}],
    points: [],
    objects: []
  }
];
