import React from "react";

function ConfirmationIcon(props) {
  return (
    <svg
      width="93"
      height="93"
      viewBox="0 0 93 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.5 0.25C20.97 0.25 0.25 20.97 0.25 46.5C0.25 72.03 20.97 92.75 46.5 92.75H69.625V83.5H46.5C26.4275 83.5 9.5 66.5725 9.5 46.5C9.5 26.4275 26.4275 9.5 46.5 9.5C66.5725 9.5 83.5 26.4275 83.5 46.5V53.1138C83.5 56.7675 80.2162 60.375 76.5625 60.375C72.9088 60.375 69.625 56.7675 69.625 53.1138V46.5C69.625 33.735 59.265 23.375 46.5 23.375C33.735 23.375 23.375 33.735 23.375 46.5C23.375 59.265 33.735 69.625 46.5 69.625C52.8825 69.625 58.71 67.035 62.8725 62.8262C65.8787 66.9425 71.0587 69.625 76.5625 69.625C85.6738 69.625 92.75 62.225 92.75 53.1138V46.5C92.75 20.97 72.03 0.25 46.5 0.25ZM46.5 60.375C38.8225 60.375 32.625 54.1775 32.625 46.5C32.625 38.8225 38.8225 32.625 46.5 32.625C54.1775 32.625 60.375 38.8225 60.375 46.5C60.375 54.1775 54.1775 60.375 46.5 60.375Z"
        fill="#76D549"
      />
    </svg>
  );
}

export default ConfirmationIcon;
