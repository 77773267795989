import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { Canvas } from '@react-three/fiber'
// import Model  from "./Model";

import { fetchStories } from "store/actions/storyActions";
import Item from "./Item";

const StoryList =  (props) => {
  const dispatch = useDispatch();
  const { storyList } = useSelector((state) => state.storyList);
  // console.log(list)
  useEffect(() => {
    dispatch(fetchStories());
  }, []);

  return (
    <div className="main-container list-cont">
      <div className="heroes-list">
      {
        storyList ?
        storyList.data.map((el, index) => {
          return <Item key={index}  story={el}  />
        })
        :''
      }
      </div>
    </div>
  );
};

export default StoryList;



// import List from 'react-virtualized/dist/commonjs/List';
// 
// import { Canvas } from '@react-three/fiber'
// import Model  from "./Model";

// import { fetchStories } from "store/actions/storyActions";
// import Item from "./Item";

// const StoryList =  (props) => {
//   const dispatch = useDispatch();
//   const { user } = useSelector((state) => state.user);
//   const { storyList } = useSelector((state) => state.storyList);
//   const ref = useRef('');
//   // console.log(list)
//   useEffect(() => {
//     dispatch(fetchStories(user.id));
//   }, []);

//   const rowRenderer = ({index}) => {
//     return <Item key={index}  story={storyList.data[index]}  />
//   }

//   return (
//     <div className="main-container list-cont">
//       <div className="heroes-list">
//       <List
//         ref={ref}
    
//         height={850}
//         overscanRowCount={16}
//         // noRowsRenderer={this._noRowsRenderer}
//         rowCount={storyList ? storyList.data.length: 0}
//         rowHeight={
//           50
//         }
//         rowRenderer={rowRenderer}
   
//         width={600}
//       />
     
//       </div>
//     </div>
//   );
// };

// export default StoryList;
