import React from "react";
import { useMediaQuery } from "react-responsive";

import SuccessMobile from "components/utils/SuccessMobile";
import Success from "./Success";

export default (props) => {
  return useMediaQuery({ maxWidth: 768 }) ? (
    <SuccessMobile
      buttonValue="Nice"
      message={2}
      buttonOption="green"
      {...props}
    />
  ) : (
      <Success {...props} />
    );
};
