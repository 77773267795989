import React, {useRef} from 'react';
import { DragControls} from "@react-three/drei";

import {Vector3, Matrix4} from 'three';

const DragControl =({ children, property, index, elRef, myStory, changeValue, isTexture}) => {
    const matrix = new Matrix4();
    const navArr = isTexture ? ['texture_right', 'texture_up']: ['right', 'up']

    const dragEnd = () =>{
        if(myStory && myStory[property][index]) {
            const newPos = new Vector3();
            elRef.current.getWorldPosition(newPos);
            changeValue([newPos.x, newPos.y], navArr, property, index, true);  
        }

    }

    return (
        changeValue? 
        <DragControls  onDragEnd={dragEnd} matrix={matrix} >
            {children}
        </DragControls>
        : children
    );
}

export default DragControl;