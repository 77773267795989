import React, { Suspense, useState, useEffect, useLayoutEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate  } from "react-router-dom";
import {playMovements} from "components/utils/helperFunctions";
import "styles/heroStyles.scss";
import ModelCanvas from "pages/CreateHero/ModelCanvas";
import Step from "./Step";
import Input from "components/utils/InputNew";
import Button from "components/utils/Btn";
import StepList from "./StepList";
import MovementSelection from "./MovementSelection";
import Leftbar from "components/layout/LeftBar";
import { saveMovement, editMovement, setMovement, getMovement } from "store/actions/movementActions";
import _ from 'lodash';
import EmotionsSelection from "./EmotionsSelection";

const MovementContent =  (props) => {
  // const startPosition = {"Armature rotation z 0": 0};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const obj = {name: '', description: '',  properties: null};

  const { movement } = useSelector((state) => state.movement);
  const startPosition = {'Armature rotation x 0': 0};
  const [viewMotions, setViewMotions] = useState([]);
  const [motions, setMotions] = useState([]);
  const [step, setStep] = useState(startPosition);
  const [currentBone, setCurrentBone] = useState('chest');
  const [currentNodes, setNodes] = useState(null);
  const [runWithPrev, setPrev] = useState(false);
  const [movemetData, setData] = useState(obj);
  const [index, setIndex] = useState(-1)
  const { id } = useParams ();


  useEffect(() => {
    if(id) {
      dispatch(getMovement(id));
      return;
    }
    dispatch(setMovement(null));
  }, [id]);

  useEffect(() => {
    if(movement && movement.data ) {
 
      const movements = [...movement.data.attributes.properties]
      let arr = []
      movements.forEach((el) => {
        for(let key in el) {
          arr.push({[key] : el[key]});

        }   
      })
      setViewMotions(arr);
      
      // setViewMotions(movement.data.attributes.properties);
      setMotions(movements);
      setData(movement.data.attributes);   

      return;
    }
    setViewMotions([]);
    setMotions([]);
    setData(obj);

  }, [movement]);

  const selectBone = (bone) => {
    setCurrentBone(bone);

  }

  const getStartPosition = (nodes) => {
    setNodes(nodes);
  }
  // console.log(currentNodes)
  const addStep = () => {
    const arr = [...viewMotions];
    arr.push(step);
    if(runWithPrev) {
      let newMotions = [...motions];
      const last = newMotions.length - 1;
      newMotions[last] = Object.assign(newMotions[last], step);
      setMotions(newMotions);
      // console.log(newMotions)
    } else{
      const temp = [...motions];
      temp.push(step);
      // console.log(motions)
      setMotions(temp);
    }


    setViewMotions(arr);
    setStep(null);
    setIndex(viewMotions.length + 1)
    // console.log(arr);
  }

  const removeStep = (index) => {
    let arr = [...viewMotions];
    const newArray = arr.slice(0, index).concat(arr.slice(index + 1));
    setViewMotions(newArray);
    setMotions(newArray);
    // setStep(null);
    // setIndex(newArray.length - 1)

    // if(selected === newArray.length) {
    //   // console.log(selected - 1, newArray)
    //   setPage(selected - 1);
    // }

  }


  const playAll = () => {
    // addStep();
    let last = motions.length - 1;
    let newMotions = !step || _.isEqual(step, motions[last]) ?[...motions]: Array.isArray(step) ? [...motions, ...step]: [...motions, step];
    if(runWithPrev) {
      const last = motions.length - 1;
      motions[last] = Object.assign(motions[last], step);
      newMotions = [...motions];
    }
    console.log(newMotions)
    playMovements(newMotions, playStep);

  }

  const playStep = (moveStep) => {
    setTimeout(() => {
      setStep(moveStep);
    }, 10)
  }

  const changeData = (value, name) => {
    let myNewData = {...movemetData};
    myNewData[name] = value;
    setData(myNewData);

  }


  const save = () => {
    let myNewData = {...movemetData};
    let last = motions.length - 1;
    let newMotions = !step || _.isEqual(step, motions[last]) ?[...motions]: Array.isArray(step) ? [...motions, ...step]: [...motions, step];
    myNewData.properties = newMotions;
    // /api/heroes


    dispatch(
      saveMovement({data: myNewData })
    ).then((json) => {
      // console.log(hero)
      if (json.error) {
        //show error
        // customToastError("Invalid username/password");
      } else {
        navigate(`/edit-movement/${json.payload.id}`);
      }
      // setSubmitting(false);
    });
  }


  return (
    <div  className="main-container">
      {!id ? 
      <StepList
        viewMotions={[...viewMotions, step]}
        setStep={setStep}
        index={index}
        setPropertyIndex={setIndex}
        addStep={addStep}
        removeStep={removeStep}
        currentBone={currentBone}
        save={save}
      />:<Leftbar/>
      }
      <div className="dashboard-style">
        {currentNodes ?
          <div  className="movement-nav">
            {
              index === -1 || id ?
              <div>
                <Input placeholder="Name" name="name" changeValue={changeData} disabled={id} defaultValue={movemetData.name}/>
                <Input placeholder="Description" name="description" changeValue={changeData} disabled={id} defaultValue={movemetData.description}/>

              </div>:
              <div>
                <Step 
                  index={index} 
                  viewMotions={viewMotions} 
                  step={viewMotions[index]} 
                  setStep={setStep} 
                  runWithPrev={runWithPrev} 
                  setPrev={setPrev} 
                  currentNodes={currentNodes} 
                  currentBone={currentBone} 
                  setCurrentBone={setCurrentBone} 
                  getStartPosition={getStartPosition} 
                  selectBone={selectBone}/>

              </div>
            }
            {
              // viewMotions.map((el, index) => {
              //   return <Step index={index + 1} viewMotions={viewMotions} key={index} runWithPrev={runWithPrev} setPrev={setPrev} step={el} setStep={setStep} currentNodes={currentNodes} />
              // })
            }
            {
              currentBone === 'face_head' ? 
              <EmotionsSelection setStep={setStep}/>
              :''
            }
            {
              currentBone === 'rh_handR' || currentBone === 'lh_handL' ? 
              <MovementSelection bone={currentBone} setStep={setStep} />:
              ''
            }

            <div>
              <Button className="new-movement-step" handleClick={playAll} text="Play all" />
            </div>
           
          </div>:''
        }

        <div className="model-wrapper">
         <ModelCanvas changes={step} getStartPosition={getStartPosition} />
        </div>
      </div>
    </div>

  );
};

export default MovementContent;

