import React from "react";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { extend, useLoader} from '@react-three/fiber';

const Watermark =  ({id}) => {
    const fontPath =  "helvetiker_regular.typeface.json";
    const font = new FontLoader().parse(require("assets/font/" + fontPath));
    const path ="message/b1.png";
    const texture = useLoader(TextureLoader, require("assets/" + path));


  const handleClick = () => {
    window.open(`/view-story/${id}`)
    
  }
  const textOptions = {
    font,
    size:  0.15,
    height: 0.01,
  };

  return (
    <mesh onClick={handleClick} scale={0.5}>
        <mesh position={[1.8, -2.5, 0]} > 
            <planeBufferGeometry args={[1.5, 1]} />
            <meshBasicMaterial color={0xf0f0f0} attach="material" map={texture} transparent={true}/>
        </mesh>
        <mesh position={[1.2, -2.5, 0]}>
            <textGeometry attach="geometry" args={['child of #' + id, textOptions] } />
            <meshBasicMaterial attach="material" color="#000000" transparent={true} />
        </mesh>
    </mesh>
    

  );
};
export default Watermark;
