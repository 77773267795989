import React, {useState} from "react";
import NavigationCircle from "components/utils/NavigationCircle";
import Dropdown from "components/utils/Dropdown";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";
import InputColor from "components/utils/InputColor";
import Item from "./Item";
import fonts from "config/fonts";
import Alignment from "./Alignment";


const TextSelection =  ({changeValue, text, index, remove}) => {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const changeText = (value, type) => {
    changeValue(value, type, 'text', index);
  }
  const changeArea = (value, type) => {
    changeValue(value, 'texture_' + type, 'text', index);
  }
  return (
    <div className="text-edit cont-item">
      <textarea value={text.content} onChange={(e) => changeText(e.target.value, 'content')}></textarea>
      
      <Dropdown options={fonts} selected={text.font} name="choose-fonts"  onChange={(val) => changeText(val, 'font')}/>
      <h6>Font</h6>
      <div className="font-section">
        
        <Alignment changeValue={changeText} selected={text.align}/>
        <InputColor value={text.color} changeValue={(val) => changeText(val, 'color')}/>
        <InputNumberWithArrows label=' ' value={text.size || 26} step={2} min={6} max={96}  changeValue={(val) => changeText(val, 'size')}/>        
      </div>
      
      
      <h6>Text</h6>
      <NavigationCircle changeValue={changeText} defaultUp={text.up} defaultRight={text.right} defaultZIndex={text.zIndex}/>
      <div className="bg-cont">
        <Item key={'none'} selected={false} value={`url(${require("assets/heroProps/none.jpg")})`} changeValue={() => changeText('', 'bg')}/>

        {
          arr.map((el, index) => {
            return <Item key={index} selected={el == text.bg} value={`url(${require('assets/message/b' + el +'.png')})`} changeValue={() => changeText(el, 'bg')}/>
          })
        }
      </div>
      <h6>Message box</h6>
      <InputColor label={'Background color'} value={text.bgcolor} changeValue={(val) => changeText(val, 'bgcolor')}/>
      <InputNumberWithArrows label='Width' value={text.width || 2} min={0.25}  changeValue={(val) => changeText(val, 'width')}/>
      <InputNumberWithArrows label='Height' value={text.height || 1} min={0.25}  changeValue={(val) => changeText(val, 'height')}/>
      <NavigationCircle changeValue={changeArea} defaultUp={text.texture_up} defaultRight={text.texture_right} defaultZIndex={text.texture_zIndex}/>
    </div>
  );
};

export default TextSelection;
