import React from "react";
import Header from "components/layout/Header";
import HeroModel from "./HeroModel";

export default (props) => {

  return (
      <div>
         <Header  />
         <HeroModel />
      </div>
  );
};
