import React, { useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "store/actions/userActions";
// import Referral from "./Referral";
import LogOutIcon from "components/iconsAndLogos/LogOutIcon";
import UserInfo from "./UserInfo";
import ContactUs from "./ContactUs";
import { Link } from "react-router-dom";


const AccountPage =  (props) => {
  const { user } = useSelector((state) => state.user); // role_id: '1' is equals to Creater
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuItems = [{name: 'Forgot password', link: '/forgot-password'}, {name: 'Terms and conditions', link: '/terms-and-conditions'}, {name: 'How to use', link: '/how-to-use'}, {name: 'Give feedback', link: '/give-feedback'}];


  const handleSignOut = useCallback(() => {
    dispatch(logoutUser()).then(() => {
      navigate("/");
    });
  }, [dispatch]);

  return (
    <div className="account-page">
      <div className="account-bg">
        
      </div>
      <div className="account-body">
          {`Hi  ${user ? user.username : 'detka'},`}
          <UserInfo />
          {
            menuItems.map((item, index,) => {
              return <Link key={index} to={item.link} style={{ textDecoration: 'none' }}>
                        <div >{item.name}</div>
                      </Link>
            })
          }

          {/* <Referral /> */}
          <LogOutIcon onClick={handleSignOut} />
          <ContactUs />
          
        </div>
        
    </div>
      
  );
};

export default AccountPage;
