import React, { useState } from "react";
import Button from "components/utils/Btn";
import { useSelector} from "react-redux";




const UserInfo =  (props) => {
  const { user } = useSelector((state) => state.user); // role_id: '1' is equals to Creater
  const [isShown, show] = useState(false);

  return (
      <div>
        <div onClick={() => show(!isShown)} text="Referal">UserInfo</div>
        {
          isShown ?
            <div>
                <div>Name: {user.username}</div>
                <div>Email: {user.email}</div>
                <div>Created at: {user.createdAt}</div>
            </div>
          :''
        }

      </div>
  );
};

export default UserInfo;
