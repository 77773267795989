import React from "react";
import Item from './Item'
import SocialMedia  from "./SocialMediaLinks";

const LeftBar = (props) => {
  const menuItems = [{name: 'Stories', link: '/stories'},  {name: 'New story', link: '/create-story'}, {name: 'Characters', link: '/characters'},{name: 'New Character', link: '/create-character'},   , {name: 'Movements', link: '/movements'}, {name: 'New Movement', link: '/create-movement'}, {name: 'How to use', link: '/how-to-use'}, {name: 'Give feedback', link: '/give-feedback'}];

  return (
    <div className="left-container">
       <div className="sidebar-brand"><h3>User menu</h3></div>
       <ul id="sidebar-nav" className="sidebar-nav">
         {
           menuItems.map((el, index,) => {
             return <Item item={el} key={index}/>
           })
         }
       </ul>
       <SocialMedia />
       
    </div>
  );
};

export default LeftBar;
