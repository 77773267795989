import React, {useState} from "react";
import PopupContent from "./PopupContent";
import ArrowLeftIcon from "./iconsAndLogos/ArrowLeftIcon"
import ArrowRightIcon from "./iconsAndLogos/ArrowRightIcon"

const PropertiesBar =  ({properties, index, setPropertyIndex, plusIndex = 1}) => {
  const [show, showPopup] = useState(false);
  const currentProperty = index === -plusIndex ? 'About' : properties[index].display_name;
  const aboutClass = index === -plusIndex ? "sidebar-item selected": "sidebar-item"

  return (
      <div className="properties-list">
       <div className={aboutClass} key={-plusIndex} onClick={() => setPropertyIndex(-plusIndex)}>About</div>
        {
          properties.map((el, i) => {
            const className = i === index ? "sidebar-item selected": "sidebar-item"
            return <div className={className} key={i} onClick={() => setPropertyIndex(i)}>{el.display_name}</div>
          })
        }
     
      </div>
  );
};

export default PropertiesBar;
