import React, { useRef } from "react";
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { useLoader} from '@react-three/fiber';
import DragControl from "./Dragable";
import { Text} from '@react-three/drei'



const Textt =  ({textProps, index, myStory, changeValue}) => {
  const fontPath = textProps.font || "DeliusSwashCaps-Regular.ttf";
  // const font = new FontLoader().parse(require("assets/font/" + fontPath));
  const path = textProps.bg ? "message/b" + textProps.bg + ".png" : 'heroProps/none.jpg';
  const texture = useLoader(TextureLoader, require("assets/" + path));
  const width = textProps.width || 2;
  const height = textProps.height || 1;
  const right = +textProps.right || 0;
  const up = textProps.up || 0;
  const textureRight = +textProps.texture_right || 0;
  const textureUp = textProps.texture_up || 0;
  const zIndex = +textProps.zIndex || 0.1;
  const texturezIndex = +textProps.texture_zIndex || 0;
  const textureRef = useRef('');
  const textRef = useRef('');


  const formatText = () => {
    let txt1 = textProps.content || '';
    // console.log(txt1)
    // if(txt1.length > 6) {
    //   return txt1.slice(0, 6) + "\n" + txt1.slice(3);
    // }
    return txt1;
  }
  return (
      <mesh>
        {
          textProps.bg ?
          <DragControl  property='text' elRef={textureRef} index={index} myStory={myStory} changeValue={changeValue} isTexture={true}>
            <mesh  position={[textureRight, textureUp, texturezIndex]} ref={textureRef}> 
              <planeGeometry args={[width, height]} />
              <meshBasicMaterial color={textProps.bgcolor || 0xf0f0f0} attach="material" map={texture} transparent={true}/>
            </mesh>
          </DragControl>
          :
          ''
        }
        <DragControl  property='text' elRef={textRef} index={index} myStory={myStory} changeValue={changeValue}>
        <mesh position={[right, up, zIndex]} ref={textRef} >
          <Text
              font={require("assets/font/" + fontPath)}
              fontSize={textProps.size / 100 || 0.25}          // Font size of the text
              color={textProps.color || 0x0000}          // Color of the text
              maxWidth={2}         // Maximum width of the text block
              lineHeight={1.2}      // Line height of the text
              letterSpacing={0}     // Space between letters
              textAlign={textProps.align || "center"}    // Horizontal alignment (left, right, center, justify)
              anchorX="center"      // X anchor: (left, center, right)
              anchorY="middle"      // Y anchor: (top, middle, bottom, baseline)
            >
              {formatText()}
            </Text>

        </mesh>
        
      
          
           
        </DragControl>

      </mesh>
  );
};

export default Textt;
