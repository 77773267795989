import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignCenter, faAlignLeft, faAlignRight} from '@fortawesome/free-solid-svg-icons';

const AlignmentSection =  ({changeValue, selected}) => {
    const values = [{icon: faAlignLeft, value: 'left'}, {icon: faAlignCenter, value: 'center'}, {icon: faAlignRight, value: 'right'}]
    return (
        <div className="alignment-section">
            {
                values.map((el, index) => {
                    const className = selected  === el.value? 'selected alignment-buttons' : 'alignment-buttons';
                    return <div key={index} onClick={() => changeValue(el.value, 'align')} className={className}>
                        <FontAwesomeIcon icon={el.icon}/>
                    </div>
                })
            }

        </div>
    )
  
};

export default AlignmentSection;
