import endPoint from "config/endPoint";
import { movementActions } from "../types";


export const saveMovement = (data) => {
  const fetchMovementFailed = () => ({
    type: movementActions.FETCH_MOVEMENT_FAILED,
    payload: data,
  });

  const fetchMovementSuccess = (movement) => ({
    type: movementActions.FETCH_MOVEMENT_SUCCESS,
    payload: movement,
  });

  const fetchMovementPending = () => ({
    type: movementActions.FETCH_MOVEMENT_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchMovementPending());
    return fetch(`${endPoint.endpoint}/movements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
          return dispatch(fetchMovementSuccess(json));
      })
      .catch(() => dispatch(fetchMovementFailed));
  };
};

export const setMovement = (movement) => {
  return {
    type: movementActions.FETCH_MOVEMENT_SUCCESS,
    payload: movement
  }
}

export const editMovement = (data, id) => {
  const fetchMovementFailed = () => ({
    type: movementActions.FETCH_MOVEMENT_FAILED,
    payload: data,
  });

  const fetchMovementSuccess = (movement) => ({
    type: movementActions.FETCH_MOVEMENT_SUCCESS,
    payload: movement,
  });

  const fetchMovementPending = () => ({
    type: movementActions.FETCH_MOVEMENT_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchMovementPending());
    return fetch(`${endPoint.endpoint}/movements/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
          return dispatch(fetchMovementSuccess(json));
      })
      .catch(() => dispatch(fetchMovementFailed));
  };
};

export const fetchMovements = (id) => {

  const fetchMovementList = (list) => ({
    type: movementActions.FETCH_MOVEMENT_LIST,
    payload: list,
  });


  return async (dispatch) => {
    return fetch(`${endPoint.endpoint}/movements?filters[owner][id][$eq]=4&filters[owner][id][$eq]=${id}`, {
    // return fetch(`${endPoint.endpoint}/movements`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
      .then((res) => res.json())
      .then((json) => {
          return dispatch(fetchMovementList(json));
      })
      .catch(() => dispatch(fetchMovementList([])));
  };
};

export const getMovement = (id) => {
  const fetchMovementFailed = () => ({
    type: movementActions.FETCH_MOVEMENT_FAILED,
    payload: null,
  });

  const fetchMovementSuccess = (movement) => ({
    type: movementActions.FETCH_MOVEMENT_SUCCESS,
    payload: movement,
  });

  const fetchMovementPending = () => ({
    type: movementActions.FETCH_MOVEMENT_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchMovementPending());
    return fetch(`${endPoint.endpoint}/movements/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((json) => {
          return dispatch(fetchMovementSuccess(json));
      })
      .catch(() => dispatch(fetchMovementFailed));
  };
};
