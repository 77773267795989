import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

// browser components

import Landing from "pages/HomePage";
import Registration from "pages/Registration";
import Login from "pages/Login";
import ForgotPassword from "pages/ForgotPassword";
import NewPassword from "pages/NewPassword";
import NotFound from "pages/NotFound";
import ErrorPleaseSignIn from "pages/ErrorPleaseSignIn";
import Success from "pages/Success";
import Confirmation from "pages/Confirmation";
import Profile from "pages/Profile";
import CreateStory from "pages/CreateStory";
import CreateHero from "pages/CreateHero";
import CreateMovement from  "pages/CreateMovement";
import ViewStory from "pages/CreateStory/ViewStory";
import Account from  "pages/Account";
import HeroesList from  "pages/HeroesList";
import MovementsList from  "pages/MovementsList";
import StoriesList from  "pages/StoriesList";
import HowToUse from  "pages/HowToUse";
import GiveFeedback from  "pages/GiveFeedback";
import Terms from  "pages/Terms";

//



const userRoutes = [
  {
    path: "/account",
    component: <Account />,
  },

  {
    path: "/create-character",
    component: <CreateHero />,
  },

  {
    path: "/create-story",
    component: <CreateStory />,
  },

  {
    path: "/edit-movement/:id",
    component: <CreateMovement />,
  },

  {
    path: "/edit-character/:id",
    component: <CreateHero />,
  },

  {
    path: "/edit-story/:id",
    component: <CreateStory />,
  },

  {
    path: "/view-story/:id",
    component: <ViewStory />,
  },

  {
    path: "/create-movement",
    component: <CreateMovement />,
  },

  {
    path: "/characters",
    component: <HeroesList />,
  },

  {
    path: "/movements",
    component: <MovementsList />,
  },

  {
    path: "/stories",
    component: <StoriesList />,
  },

  {
    path: "/give-feedback",
    component: <GiveFeedback />,
  },
  {
    path: "/edit-profile",
    component:  <Profile />,
  }
];

const publicRoutes = [
  {
    path: "/",
    exact: true,
    component:  <Landing />,
  },
  {
    path: "/how-to-use",
    component: <HowToUse />,
  },

  {
    path: "/home",
    component:  <Landing />,
  },

  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },

  {
    path: "/reset-password",
    component: <NewPassword />,
  },

  {
    path: "/confirmation",
    component: <Confirmation />,
  },
  {
    path: "/success",
    component: <Success />,
  },
  {
    path: "/landing",
    component: <Landing />,
  },
  {
    path: "/not-found",
    component:  <NotFound />,
  },

  {
    path: "/terms-and-conditions",
    component: <Terms />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Registration />,
  },

];


export default function () {
  const { user } = useSelector((state) => state.user);
  //

  return (
    <div className="page-container">
      <div className="content-wrap">
        <Routes>
          {publicRoutes.map(({ exact, path, component }) => (
            <Route  key={path} exact={exact} path={path} element={component} />
          ))}

          {user &&
            userRoutes.map(({ exact, path, component }) => (
              <Route
                key={path}
                exact={exact}
                path={path}
                element={component}
              />
            ))}
    
            {!user &&
            userRoutes.map(({ exact, path }) => (
              <Route
                key={path}
                exact={exact}
                path={path}
                element={<ErrorPleaseSignIn />}
              />
            ))}
            
          <Route  path="*" element={<NotFound />}/>

        </Routes>
      </div>
    </div>
  );
}
