export default [
  {display_name:"Character", values: ['red', 'blue', 'orange', 'green', '#a01313']},
  {display_name:"Background",  images_count: 14},
  // {display_name:"Background",  colors: ['#e6dfdf', '#12adf1', '#568212'], images_count: 14},
  {display_name:"Text",  values: ['1', '2']},
  {display_name:"Lines",  values: ['1', '2']},
  {display_name:"Areas and effects",  values: ['1', '2']},
  {display_name:"Camera",  values: ['1', '2']},
  {display_name:"Add object",  values: ['1', '2']},
  // {display_name:"Templates",  values: ['1', '2']},
  {display_name:"Interactive points",  values: ['1', '2']}
  
]
