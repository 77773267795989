import React from "react";
import Header from "components/layout/Header";
import List from "./List";
import Leftbar from "components/layout/LeftBar";



export default (props) => {

  return (
      <div>
         <Header  />
         <Leftbar />
         <List />
      </div>
  );
};
