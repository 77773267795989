export function deepCopy(obj) {
  let copy;

  // Handle primitive types
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle objects
  copy = {};
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }
  return copy;
};

export function playMovementsInterval(newMotions, playStep) {
  // console.log(newMotions)
  // resetAll && resetAll(newMotions);

  resetAll(newMotions, playStep);
  let moveStep = 0.05;
  newMotions.forEach((item, i) => {
    let finishedTemp = {...item};
    const temp = {};
    const intervalId = setInterval(() => {
      // incrementValue();

      for(let key in item) {
        let arr = key.split(' ');
        let startValue = +arr[3];
        const newMotion = {...item};

        let newVal = temp[key] || startValue;

        if( startValue < newMotion[key]) {
          if(newVal >= newMotion[key]) {
            // finishedTemp[key] = false;
            delete finishedTemp[key];
            continue;
          }
          newVal += moveStep;
        //   for(let j = startValue; j < newMotion[key]; j += 0.0001) {
        //     playStep(key, j);
          // }
        } else {
          if(newVal <= newMotion[key]) {
            delete finishedTemp[key];
            continue;
          }
          newVal -= moveStep;
        //   for(let j = startValue; j > newMotion[key]; j -= 0.0001) {
        //     playStep(key, j);
        //   }
        }
        // console.log(newVal)

        temp[key] = newVal;

      }
      if(!Object.keys(finishedTemp).length) {
        return () => clearInterval(intervalId);
      }

      playStep({...temp});

    }, 10);
  });
};

export function playMovementsChrome(newMotions, playStep) {

  resetAll(newMotions, playStep);
  setTimeout(() => {
    let moveStep = 0.001;
    newMotions.forEach((item, i) => {
      let finished = true;
      let finishedTemp = {...item};
      const temp = {};
      while(finished) {
        for(let key in item) {
          let arr = key.split(' ');
          let startValue = +arr[3];
          const newMotion = {...item};

          let newVal = temp[key] || startValue;

          if( startValue < newMotion[key]) {
            if(newVal >= newMotion[key]) {
              delete finishedTemp[key];
              continue;
            }
            newVal += moveStep;
          } else {
            if(newVal <= newMotion[key]) {
              delete finishedTemp[key];
              continue;
            }
            newVal -= moveStep;
          }

          temp[key] = newVal;

        }
        if(!Object.keys(finishedTemp).length) {
          finished = false;
        }

        playStep({...temp});

      }
    });
  },100);
};


export function playMovements(newMotions, playStep) {
  const browser = detectBrowser();
  if(browser === 'Chrome' || browser === 'Edge') {
    playMovementsChrome(newMotions, playStep)
  } else {
    playMovementsInterval(newMotions, playStep)
  }

};

const detectBrowser =() => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Chrome")) {
        return "Chrome";
    } else if (userAgent.includes("Firefox")) {
        return "Mozilla Firefox";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        return "Apple Safari";
    } else if (userAgent.includes("Edge")) {
        return "Edge";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        return "Opera";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
        return "Internet Explorer";
    } else {
        return "Unknown browser";
    }
}

const resetAll = (newMotions, playStep) => {
  let reseted = {};
  newMotions.forEach((item, i) => {
    for(let key in item) {
      let arr = key.split(' ');
      let startValue = +arr[3];
      reseted[key] = startValue;
    }
  });

  playStep(reseted);
}

