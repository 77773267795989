import React, { Suspense, useEffect } from "react";
import { OrbitControls, Line, Star} from "@react-three/drei";
import * as THREE from 'three';
// import { extend, useLoader} from '@react-three/fiber';
import { hilbert2D } from 'three/examples/jsm/utils/GeometryUtils';

// import { Text, Image, Text3D } from '@react-three/drei'

// extend({ GeometryUtils })


const Lines =  ({linesProps}) => {
  const zIndex = +linesProps.zIndex || 0;
  return (
      <mesh>
        <Line  points={linesProps.points} color={linesProps.color || "#000"} lineWidth={linesProps.weight || 5}  position={[zIndex,12, 0]} />
      </mesh>
  );
};
export default Lines;
