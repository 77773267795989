import React, {useState, useRef} from "react";
import NavigationCircle from "components/utils/NavigationCircle";
import SelectionCont from "./Selections";
import InputColor from "components/utils/InputColor";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";
import {deepCopy} from "components/utils/helperFunctions";
import { compileString } from "sass";


const PointChangeBodyChange =  ({changeValue, index, point, add, changeBg, page, fullStory, setStory, remove}) => {
  const [funcProperties, setFuncProperties] = useState([]);
  const [funcName, setFuncName] = useState([]);
  const myStory = useRef(deepCopy(fullStory))
  const [mode, chnageModeState] = useState(myStory.current[page].points[index].mode || 'jumpPage');
  
  const changePoints = (value, type) => {
    changeValue(value, type, 'points', index);
  }
  // console.log(PointChangeBodyChange)
  
  const changeFunction = (value, type, property, index) => {
    const newName = [...funcName];
    const newProps = [...funcProperties];
    if(!type || type === 'image') {
      
      newName.push('changeBg');
      newProps.push([value, type]);

      
      changeBg(value, type);
      
    } else {
      newName.push('changeValue');
      newProps.push([value, type, property, index]);
      changeValue(value, type, property, index);
    }
    setFuncProperties(newProps);
    setFuncName(newName);

  }

  const save = () => {
    // console.log(setStory)
      let current = myStory.current;
      // console.log(fullStory[page])
      current[page].points[index] = fullStory[page].points[index];
      current[page].points[index].func= {name: funcName, properties: funcProperties};
      // properties
      setStory(current)
      // changeValue(() => changeValue(...funcProperties), 'func', 'points');

  }
// changeValue, defaultChecked, labelText


  const chnageMode = (currentMode)=> {
    chnageModeState(currentMode);
    changePoints(currentMode, 'mode')
  }
  
  return (
    <div className="point-cont cont-item">
      <NavigationCircle changeValue={changePoints} defaultUp={point.up} defaultRight={point.right}  defaultZIndex={point.zIndex} />
      <InputNumberWithArrows label='Scale' value={point.scale || 0.25}  changeValue={(val) => changePoints(val, 'scale')}/>
      <InputColor label={'Color'} value={point.color || '#ff0000'} changeValue={(val) => changePoints(val, 'color')}/>

      <div>
        <button onClick={(e) => changePoints(!point.hidden, 'hidden')}>{point.hidden ? 'Show': 'Hide'}</button>
      </div>
      <ul className="tab-header">
        <li className={mode === 'jumpPage' ? 'active' : ''} onClick={() => chnageMode('jumpPage')}>Jump to page</li>
        <li className={mode === 'showText' ? 'active' : ''} onClick={() => chnageMode('showText')}>Show text on click</li>
        <li className={mode === 'continue' ? 'active' : ''} onClick={() => chnageMode('continue')}>Continue</li>
        <li className={mode === 'addElem' ? 'active' : ''} onClick={() => chnageMode('addElem')}>Add element</li>
      </ul>
      {
        mode === 'jumpPage'?
        <div>
          <InputNumberWithArrows label='Jump to story id' value={point.storyId} step={1} changeValue={(val) => changePoints(val, 'storyId')}/>
        </div>
        
        : mode === 'addElem' ?
        <div>
          <h6>Function</h6>
          <SelectionCont changeBg={changeFunction} changeValue={changeFunction} add={add} remove={remove} myStory={fullStory[page]} isPoint={true}/>
          <button onClick={save}>Save</button>
        </div>
        :''
        
      }
      
     
      
    </div>

  );
};

export default PointChangeBodyChange;
