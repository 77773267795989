import React, {useRef} from "react";
import DragControl from "./Dragable";
import { useParams } from "react-router-dom";

const Point =  ({pointProps, functions, showPopup, index, myStory, changeValue}) => {
  const right = +pointProps.right || 0;
  const up = pointProps.up || 0;
  const elRef = useRef('');
  const { id } = useParams ();

  const handleClick = () => {
    if(pointProps.mode === 'continue' && id) {
      window.open(`/create-story/?parent=${id}`)
      
      return;
    }
    if(pointProps.mode === 'jumpPage') {
      if(pointProps.storyId) {
        window.open(`/view-story/${pointProps.storyId}`)
      }
      return;
    }
    if(pointProps.mode === 'showText') {
      showPopup({...pointProps});
      return;
    }
    if( pointProps.func && pointProps.func.name.length) {
      pointProps.func.name.forEach((el, i) => {
        functions[el](...pointProps.func.properties[i]);
      })
      
    }
    
  }

  return (
    <DragControl  property='points' elRef={elRef} index={index} myStory={myStory} changeValue={changeValue}>
        <mesh ref={elRef} position={[right, up, 0]} scale={pointProps.scale || 0.25 } onClick={handleClick}>
          <circleGeometry attach="geometry" args={[1,32, 32]}/>
          <meshBasicMaterial attach="material" color={pointProps.color || "red"} transparent={true} opacity={!pointProps.hidden}/>
        </mesh>
      </DragControl>
  );
};
export default Point;
