import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      // height="682"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 28 52"
      fill="#928a8a"
    >
      <g>
        <path d="M11 48L10 45 15 35 21 26 15 16 10 7 11 3 13 0 28 26 13 52z"></path>
        <path d="M2 48L0 45 6 35 11 26 6 16 0 7 2 3 15 26z"></path>
      </g>
    </svg>
  );
}

export default Icon;
