import React, { useRef, useCallback } from "react";
import styled from "styled-components";
import ForgotPasswordBottom from "./ForgotPasswordBottom";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import { forgotPassword } from "store/actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import Input from "components/utils/Input";
import StyledButton from "components/utils/StyledButton";
import Header from "components/layout/Header";
import { useState } from "react";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  width: 440px;
  height: 290px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 15px 30px rgba(134, 117, 79, 0.12);
  border-radius: 0px 0px 5px 5px;
  @media (max-width: 767px) {
    width: 330px;
  }
`;

const FormInnerWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 40px;
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 767px) {
      width: 280px;
    }
  }
  @media (max-width: 767px) {
    width: 280px;
  }
`;



function ForgotPassword(props) {
  let btnRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      // console.log(data)
      dispatch(
        forgotPassword(data.email, navigate)
        // registerUser({ username: data.username, password: data.password, email: data.email })
      ).then((json) => {
        console.log('uuf',json)
        if (json.error) {
       
          // customToastError(json.error.message);

   
        } else {
          // const path = localStorage.getItem('path');
          // localStorage.removeItem('path');
          // navigate(path || "/account")
          navigate("/confirmation");
        }
        setSubmitting(false);
      });
    },
    [dispatch]
  );

  return (
    <>
      <Header />
      <div className="wrapper-center">
        <div className="form-wrapper">
          <h3>You forgot something?</h3>
          <h6>Enter you E-Mail and reset Password</h6>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values) => {
              if(currentEmail !== values.email) {
                setLoading(true);
                forgotPassword(values.email).then(() => setLoading(false) || setCurrentEmail(values.email));
              }
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required("Required"),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <div className="form-inner">
                  <form onSubmit={handleSubmit}>
                    <input
                      // mediaWidth="280px"
                      // margin="0 0 20px 0"
                      name="email"
                      type="text"
                      placeholder="E-mail"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.email && touched.email && "error"}
                    />
                    {errors.email && touched.email && (
                      <div
                        style={{ alignSelf: "flex-start" }}
                        className="input-feedback"
                      >
                        {errors.email}
                      </div>
                    )}
                    <StyledButton
                      ref={btnRef}
                      mediaWidth="280px"
                      type="submit"
                      disabled={loading}
                      fontSize="14px"
                      width="173px"
                      height="40px"
                      margin="0px"
                      alignSelf="flex-end"
                    >
                      Reset Password
                    </StyledButton>
                  </form>
                </div>
              );
            }}
          </Formik>
        </div>
        <ForgotPasswordBottom />
      </div>
    </>
  );
}

export default ForgotPassword;
