import { movementActions } from "../types";
// import myHero from "config/myHeroProps";

const initialState = {
  // hero: {properties: myHero}
  movement: null,
  movementList: null
};

const movementDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case movementActions.FETCH_MOVEMENT_PENDING:
      return {
        ...state,
        movement: null,
      };
    case movementActions.FETCH_MOVEMENT_SUCCESS:
      return {
        ...state,
        movement: action.payload || null,
      };
    case movementActions.FETCH_MOVEMENT_FAILED:
      return {
        ...state,
        movement: null,
      };
    case movementActions.FETCH_MOVEMENT_LIST:
      return {
        ...state,
        movementList: action.payload || null,
      };
    default:
      return state;
  }
};

export default movementDataReducer;
