import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

import HeaderMobile from "./HeaderMobile";
import HeaderMain from "./HeaderMain";

const links = [
   { name: "Stories", url: "/stories" },

];

const Header = ({ mobileDisabled, children, ...props }) => {
  const { user } = useSelector((state) => state.user);
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  return (
    <>
      {isMobile && !mobileDisabled && (
        <HeaderMobile
          links={links}
          userId={user ? user.id : null}
          children={children}
          {...props}
        />
      )}

      {!isMobile && (
        <HeaderMain
          links={links}
          userId={user ? user.id : null}
          {...props}
        />
      )}
    </>
  );
};

Header.propTypes = {
  mobileDisabled: PropTypes.bool,
  children: PropTypes.node,
};

export default Header;
