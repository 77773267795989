import React, {useState} from "react";
import AreaBody from "./AreaBody";
import AddBtn from "components/utils/AddBtn";
import RemoveIcon from "components/utils/RemoveIcon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';



const PointsCont =  ({changeValue, add, areas, area, remove}) => {
  const [selected, setSelected] = useState(0);

  const addItem = () => {
    add('areas');
    setSelected(areas.length);
  }

  const removeItem = () => {
    remove('areas', selected);
    setSelected(areas.length - 1);
  }
  const renderHeaderArea = () => {
    return areas.map((el, index) => {
      let isSelected = index === selected;
      let liClassname = isSelected ? "selected li-tab-header": 'li-tab-header';
      let className = isSelected ? "selected tab-header": 'tab-header';
      let icon = isSelected ? faChevronDown :faChevronUp;
      return <li key={index} className={liClassname} onClick={() => {setSelected(index)}}>
                <div  className={className}>
                  <span>Area {index}</span>
                  <div className="icons">
                    <RemoveIcon remove={removeItem}/>
                    <FontAwesomeIcon  icon={icon} />
                  </div>

                </div>

                {
                  isSelected ?
                  <AreaBody key={index} changeValue={changeValue} area={el}  index={index} add={add}/>
                  :''
                }
              </li>
    })
  }
  return (
    <div>
      <ul>
        {renderHeaderArea()}
      </ul>

      <AddBtn handleClick={addItem}/>
    </div>

  );
};

export default PointsCont;
