import React, {useState} from "react";

const CheckBox = ({changeValue, defaultChecked, labelText}) => {
  const [checked, setChecked] = useState(defaultChecked || false);
  const handleChange = (e) => {
    changeValue(!checked);
    setChecked(!checked);
  }

  return (
    <label className="checkbox-cont">
      <input type="checkbox" checked={checked} onChange={handleChange}/>
      <span>{labelText}</span>
    </label>
  );
}

export default CheckBox;
