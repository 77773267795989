import { combineReducers } from "redux";

import userReducer from "./reducers/userReducer";
import heroReducer from "./reducers/heroReducer";
import movementReducer from "./reducers/movementReducer";
import storyReducer from "./reducers/storyReducer";


const rootReducer = combineReducers({
  user: userReducer,
  hero: heroReducer,
  list: heroReducer,
  movement: movementReducer,
  movementList: movementReducer,
  story: storyReducer,
  storyList: storyReducer
});

export default rootReducer;
