import React, {useState, useEffect} from "react";

const Input = ({changeValue, type, step, defaultValue, placeholder,customClassName, name, disabled}) => {
  const [value, setValue] = useState('');
  const handleChange = (e) => {
    setValue(e.target.value);
    changeValue(e.target.value, name);
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue]);
  return (
    <input
      type={type || "text"}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      className={customClassName}
      name={name}
      disabled={disabled}
    />
  );
}

export default Input;
