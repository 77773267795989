import React, {useState} from "react";
import NavigationCircle from "components/utils/NavigationCircle";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";
import InputColor from "components/utils/InputColor";

const LineChange =  ({changeValue, line, index}) => {
  // const [points, change] = useState([[0, 0, -35], [0, 0, 35]]);
  const points = [...line.points];
  const changePoints = (value, changeProperties) => {
    points[changeProperties.point][changeProperties.coord] = value;
    changeValue(points, 'points', 'lines', index);
  }
  return (
    <div  className="cont-item">
      <InputColor label={'Line color'} value={line.color} changeValue={(val) => changeValue(val, 'color','lines', index)}/>
      <div>
        <h6>Start points coordinates</h6>
        <InputNumberWithArrows label='X coordinate' value={points[0][0]} changeProperties={{point: 0, coord: 0}} changeValue={changePoints}/>
        <InputNumberWithArrows label='Y coordinate' value={points[0][1]} changeProperties={{point: 0, coord: 1}} changeValue={changePoints}/>
        <InputNumberWithArrows label='Z coordinate' value={points[0][2]} changeProperties={{point: 0, coord: 2}} changeValue={changePoints}/>
      </div>
      <div>
        <h6>End points coordinates</h6>
        <InputNumberWithArrows label='X coordinate' value={points[1][0]} changeProperties={{point: 1, coord: 0}} changeValue={changePoints}/>
        <InputNumberWithArrows label='Y coordinate' value={points[1][1]} changeProperties={{point: 1, coord: 1}} changeValue={changePoints}/>
        <InputNumberWithArrows label='Z coordinate' value={points[1][2]} changeProperties={{point: 1, coord: 2}} changeValue={changePoints}/>
      </div>
      <InputNumberWithArrows label={'Weight'} value={line.weight || 5} changeValue={(val) => changeValue(val, 'weight','lines', index)} step={0.3}/>
      <InputNumberWithArrows label={'zIndex'} value={line.zIndex || 0} changeValue={(val) => changeValue(val, 'zIndex','lines', index)} step={0.1}/>
    </div>

  );
};
  // <input type="number" onChange={(e) => changeValue(e.target.value, 'scale')} defaultValue={6} step={0.25} min={0.01}/>

export default LineChange;
