import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import Header from "components/layout/Header";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getStory } from "store/actions/storyActions";
import ModelCanvas from "pages/CreateHero/ModelCanvas";
import storyDef from "config/myStory";
import Leftbar from "components/layout/LeftBar";

const ViewStory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { story } = useSelector((state) => state.story);
  const [showAll, show] = useState(true);
  const [file, setFileToShare] = useState('');
  const [myStory, setStory] = useState(storyDef);
  const [parent, setParent] = useState('');

  useEffect(() => {
    if (id !== 'undefined') {
      dispatch(getStory(id));
      return;
    }
  }, [id]);

  useEffect(() => {
    if (story && story.data) {
      setParent(story.data.attributes.parent);
      setStory(story.data.attributes.properties);

      const canvases = document.querySelectorAll('.hero-cont canvas');

      const heroCanvas = canvases[0];
      console.log(heroCanvas)
      if (heroCanvas) {
        heroCanvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'hero-image.png', { type: 'image/png' });
            setFileToShare(file);
          }
        });
      }
      return;
    }
    setStory(storyDef);
  }, [story]);

  const share = () => {
    if (navigator.share) {
      navigator.share({
        title: story.data.attributes.name,
        text: story.data.attributes.description,
        url: window.location.href,
        files: [file],
      })
        .then(() => console.log('Content shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Sharing is not supported in this browser.');
    }
  }

  return (
    <div className="view-story-cont">
      <Helmet>
        <title>{'Default Title'}</title>
        <meta name="description" content={'Default description'} />
        <meta name="image" content={'/assets/message/b1.png'} />
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content={'Default Title'} />
        <meta property="og:description" content={'Default description'} />
        <meta property="og:image" content={'/assets/message/b1.png'} />
        <meta property="og:url" content={window.location.href} />
        {/* Twitter meta tags for social sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={'Default Title'} />
        <meta name="twitter:description" content={'Default description'} />
        <meta name="twitter:image" content={'/assets/message/b1.png'} />
      </Helmet>

      <Header />
      <Leftbar />
      <div className="main-container list-cont">
        {id === 'undefined' ? <button className="view-story" onClick={() => navigate(`/create-story/`)}>Back</button> : ''}
        {showAll ?
          <div>
            {myStory.map((el, index) => (
              <div key={index} className="model-wrapper">
                <ModelCanvas myStory={myStory[index]} page={index} parent={index === 0 && parent ? parent : ''} />
              </div>
            ))}
          </div> :
          <div onClick={() => show(true)} className="model-wrapper">
            <ModelCanvas myStory={myStory[0]} page={0} />
          </div>
        }
        <div className="footer-share">
          <h6 onClick={share}>Share this story</h6>
        </div>
      </div>
    </div>
  );
}

export default ViewStory;
