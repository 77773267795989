import styled from "styled-components"

export const SectionTitle = styled.span`
  font-size: 36px;
  line-height: 1.2;
  font-weight: 800;
  font-style: normal;
  color: ${({ theme }) => theme.dark_Blue};
`;

export const H2Title = styled.span`
  font-size: 24px;
  line-height: 1.2;
  font-weight: 800;
  font-style: normal;
  color: ${({ theme }) => theme.dark_Grey};
`;
