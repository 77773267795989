import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate  } from "react-router-dom";
import Pages from "./Pages";
import SelectionCont from "./Selections"
import ModelCanvas from "pages/CreateHero/ModelCanvas";
import storyDef from "config/myStory";
import {deepCopy} from "components/utils/helperFunctions";
import { saveStory, editStory, setCurrentStory, getStory } from "store/actions/storyActions";

const MainContent = () => {
  const dispatch = useDispatch();
  const { id} = useParams ();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const obj = {name: '', description: '', author: user.username, properties: null};
  const { story } = useSelector((state) => state.story);
  const { list } = useSelector((state) => state.list);
  const { movementList } = useSelector((state) => state.movementList);
  const [myStory, setNewStory] = useState(storyDef);
  const [page, setPage] = useState(0);
  const [storyData, setData] = useState(obj);
  const isVerified = storyData.name && storyData.description;
  const searchParams = new URLSearchParams(window.location.search);
  const parent = searchParams.get('parent');
  const currentParent = storyData.parent || parent;
  
  const setStory = (data) => {
    let myNewData = deepCopy(storyData);
    // console.log(storyData)
    myNewData.properties = data;
    dispatch(setCurrentStory({data:{id:id, attributes: myNewData}}));

  }

  useEffect(() => {
    if(id) {
      dispatch(getStory(id));
      return;
    }

    if(story && story.data.id) {
       dispatch(setCurrentStory(null));
    }
    //
  }, [id]);

  useEffect(() => {
    if(story && story.data) {
      setNewStory(story.data.attributes.properties);
      setData(story.data.attributes);
      return;
    }
    if(!id) {
      setNewStory(storyDef);
      setData(obj);
    }


  }, [story]);

  useEffect(() => {
    if(myStory && !myStory[page]) {
      setPage(page - 1);
    }
  }, [myStory]);
  const changeBg = (value, type = 'background') => {
    let myNewObj = deepCopy(myStory);
    const val = type === 'image' && value ? `url(${require('assets/bg/bg_' + value +'.png')})` : value;
    myNewObj[page][type] = val;


    setStory(myNewObj);
  }

  const changeValue = (value, type, property, index, isArr) => {
    let myNewObj = deepCopy(myStory);
    console.log(value, type, property, index)
    // console.log(myNewObj)
    let idx = index || 0;
    if(!myNewObj[page][property][idx]) {
      myNewObj[page][property][idx] = {};
    }
    if(isArr) {
      myNewObj[page][property][idx][type[0]] = value[0];
      myNewObj[page][property][idx][type[1]] = value[1];
      setStory(myNewObj);
      return;
    }
    myNewObj[page][property][idx][type] = value;
    if(type === 'properties_index') {
      myNewObj[page][property][idx].properties = list.data[value].attributes.properties
    } else if(type === 'movement_index') {
      // console.log(movementList.data[value].attributes.properties)
      myNewObj[page][property][idx].movement = movementList.data[value].attributes.properties
    }
    setStory(myNewObj);
  }


  const add = (type) => {
    let myNewObj = deepCopy(myStory);
    let pushObj = type === 'lines' ?  {points: [[-3.25, -11, 0], [3.25, -11, 0]]}: {};
    // console.log(myStory)
    myNewObj[page][type].push(pushObj);
    setStory(myNewObj);
  }

  const remove = (type, index) => {
    let myNewObj = deepCopy(myStory);
    let arr = myNewObj[page][type];
    myNewObj[page][type] = arr.slice(0, index).concat(arr.slice(index + 1));
    setStory(myNewObj);
  }
  const changeData = (value, name) => {
    let myNewData = deepCopy(storyData);
    myNewData[name] = value;
    // console.log(myNewData)
    // setData(myNewData);
    setData(myNewData);

  }
  const save = () => {
    let myNewData = deepCopy(storyData);
    myNewData.properties = myStory;

    // /api/heroes

    if(story && story.data && story.data.id) {
      dispatch(
        editStory({data: myNewData }, story.data.id)
      )
      return;
    }
    if(parent) {
      myNewData.parent = parent;
    }
    
    dispatch(
      
      saveStory({data: myNewData })
    ).then((json) => {
      // console.log(hero)
      if (json.error) {
        //show error
        // customToastError("Invalid username/password");
      } else {
        navigate(`/edit-story/${json.payload.id}`);
      }
      // setSubmitting(false);
    });
  }

  const showStory = () => {
    // id

    // if(!id) {
    //   // save();
    //
    //   let myNewData = deepCopy(storyData);
    //   myNewData.properties = myStory;
    //   dispatch(setCurrentStory({data:{attributes:myNewData}}));
    //   // navigate(`/view-story/`)
    //   // return;
    // }
    save();
    navigate(`/view-story/${id}`)
  }

  return(
    <div className="main-container create-story">
      {
        <div>
          <Pages parent={currentParent} story={myStory} setStory={setStory} setPage={setPage} selected={page} showStory={showStory} isVerified={isVerified} save={save}/>
          <div className="dashboard-style">
            <div  className="selection-bar">
              <SelectionCont changeBg={changeBg} changeValue={changeValue} storyData={storyData} changeData={changeData} add={add} remove={remove} myStory={myStory[page] || myStory[page - 1]} page={page} fullStory={myStory} setStory={setStory}/>
              {
                isVerified ?
                ''
                :<div>Please fill in all fields before saving.</div>
              }
            </div>
            <div className="model-wrapper">
             <ModelCanvas myStory={myStory[page]} page={page} parent={page === 0 && currentParent ? currentParent: ''} changeValue={changeValue} />
            </div>
          </div>
        </div>
      }

    </div>
  )
}

export default MainContent;
