import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50"
      width="150"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 500 100"
    >
      <path
        fill="#00a758"
        d="M71.5-.5h12c5.793 9.737 2.96 15.07-8.5 16-8.455.025-11.122-3.975-8-12 1.397-1.57 2.897-2.904 4.5-4z"
        opacity="0.945"
      ></path>
      <path
        fill="#00a758"
        d="M-.5 64.5v-18a984.964 984.964 0 004-46 60.937 60.937 0 0111 .5c5.25 1.691 7.583 5.358 7 11a50.427 50.427 0 00-.5 14.5c3.155-5.14 7.822-7.474 14-7 10.133.229 16.467 5.229 19 15 3.259 14.554.426 27.72-8.5 39.5-10.542 7.068-21.875 8.734-34 5-6.453-2.94-10.453-7.773-12-14.5zm27-30c3.005-.247 5.505.753 7.5 3 2.41 8.597 1.743 16.93-2 25-3.732 3.55-7.732 3.883-12 1-3.153-7.629-3.153-15.295 0-23 1.436-2.942 3.603-4.942 6.5-6zM285.5.5a40.83 40.83 0 0114 1.5c1.987 1.319 3.154 3.152 3.5 5.5a723.532 723.532 0 01-3.5 51.5c.042 1.967.542 3.8 1.5 5.5 2.137.979 3.971 2.312 5.5 4-2.503 11.905-9.503 15.405-21 10.5-2.681-2.194-4.181-5.027-4.5-8.5a2463.112 2463.112 0 014.5-70z"
        opacity="0.968"
      ></path>
      <path
        fill="#00a758"
        d="M191.5 5.5c11.048-1.113 15.048 3.72 12 14.5a84.94 84.94 0 0013 .5c.29 4.436-.21 8.77-1.5 13l-1.5 1.5a60.94 60.94 0 01-11 .5 281.873 281.873 0 00-1.5 29 3.942 3.942 0 002 1c8.102-4.92 10.769-2.586 8 7-2.46 6.24-7.127 9.24-14 9a30.746 30.746 0 01-11.5-3.5c-2.636-3.742-3.97-7.909-4-12.5a780.642 780.642 0 013-29 13.118 13.118 0 00-7-1 31.819 31.819 0 011.5-12 21.74 21.74 0 016.5-4 178.594 178.594 0 016-14zM123.5 100.5h-18c-4.092-1.298-7.925-3.298-11.5-6-4.97-10.235-1.803-15.068 9.5-14.5 4.274 6.42 9.941 8.086 17 5 4.361-4.279 6.194-9.445 5.5-15.5-1.981 3.245-4.814 5.411-8.5 6.5-13.619 3.02-22.119-2.146-25.5-15.5-2.13-12.352.203-23.686 7-34 9.761-8.329 19.927-8.995 30.5-2 .929-1.189 1.262-2.522 1-4 5.242-.477 10.242.356 15 2.5a9.457 9.457 0 011.5 2.5 281.495 281.495 0 01-4 57c-2.893 10.065-9.393 16.065-19.5 18zm-4-66c7.148.428 10.148 4.261 9 11.5 1.005 10.655-3.662 15.655-14 15-2.608-2.565-3.942-5.731-4-9.5a42.11 42.11 0 013.5-13c1.5-1.969 3.333-3.302 5.5-4z"
        opacity="0.963"
      ></path>
      <path
        fill="#00a758"
        d="M241.5 19.5c7.652-.683 14.985.484 22 3.5 5.349 4.365 7.682 10.032 7 17a229.443 229.443 0 01-2 19c.088 3.689 1.921 5.856 5.5 6.5.392 12.927-5.775 17.76-18.5 14.5-2.249-1.746-3.916-3.912-5-6.5-8.274 8.101-17.608 9.601-28 4.5-8.534-15.95-3.867-26.283 14-31a57.342 57.342 0 0115-1.5c1.353-9.164-2.147-12.164-10.5-9a75.993 75.993 0 00-3.5 7.5c-13.577 1.414-17.41-4.086-11.5-16.5 4.361-4.43 9.528-7.096 15.5-8zm7 35c1.086.12 1.753.787 2 2-1.14 6.984-5.14 9.817-12 8.5-1.624-3.206-.958-5.872 2-8 2.81-.782 5.477-1.616 8-2.5z"
        opacity="0.964"
      ></path>
      <path
        fill="#00a658"
        d="M362.5 29.5v14c-8.14 10.059-18.807 14.392-32 13 .825 7.993 5.158 11.16 13 9.5 4.466-6.614 9.299-6.78 14.5-.5 1.297 4.617.13 8.45-3.5 11.5-11.873 6.11-23.54 5.778-35-1a25.569 25.569 0 01-6.5-8.5c-3.441-13.573-1.441-26.24 6-38 11.351-10.575 24.184-12.742 38.5-6.5 2.05 1.965 3.717 4.132 5 6.5zm-25 5c5.956.587 7.789 3.587 5.5 9-3.788 2.993-7.955 3.993-12.5 3 .865-5.008 3.198-9.008 7-12z"
        opacity="0.967"
      ></path>
      <path
        fill="#00a758"
        d="M66.5 20.5c5.242-.477 10.242.356 15 2.5a9.468 9.468 0 011.5 2.5c.01 18.03-.823 36.03-2.5 54a84.938 84.938 0 01-13-.5c-2.167-.833-3.667-2.333-4.5-4.5a489.892 489.892 0 013.5-54z"
        opacity="0.96"
      ></path>
    </svg>
  );
}

export default Logo;
