import React from "react";
import NavigationCircle from "components/utils/NavigationCircle";
import Item from "./Item";
import Dropdown from "components/utils/Dropdown";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";
import InputColor from "components/utils/InputColor";

const CharacterCont =  ({changeValue, index, area}) => {
  const categories = [{name: 0, display_name: 'bg', count: 14}, {name: 1, display_name: 'effect', count: 3}]
  const category = categories[area.category] || categories[0];
  const {display_name, count} = category;
  const arr = Array.from({ length: count}, (_, index) => index + 1);

  const changeAreaProps = (value, type) => {
    changeValue(value, type, 'areas', index);
  }


  return (
    <div className="cont-item">
      <NavigationCircle changeValue={changeAreaProps} defaultUp={area.up} defaultRight={area.right} defaultZIndex={area.zIndex}/>
      <InputNumberWithArrows label='Width' value={area.width || 4}  min={0.25}  changeValue={(val) => changeAreaProps(val, 'width')}/>
      <InputNumberWithArrows label='Height' value={area.height || 2}  min={0.25}  changeValue={(val) => changeAreaProps(val, 'height')}/>
      <InputColor label={'Color'} value={area.color} changeValue={(val) => changeAreaProps(val, 'color')}/>
      {/* <Dropdown options={categories} name="choose-category" selected={display_name} onChange={(val) => changeAreaProps(val, 'category')}/> */}

      <div className="bg-cont">
        {
          arr.map((el, index) => {
            return <Item key={index} selected={el === area.bg} value={`url(${require('assets/bg/' + display_name +'_' + el +'.png')})`} changeValue={() => changeAreaProps(el, 'bg')}/>
          })
        }
      </div>

    </div>

  );
};

export default CharacterCont;
