import React from "react";
import Btn from "./Btn"

const Add =  ({handleClick}) => {
  return (
    <Btn className="plus-button" handleClick={handleClick} text="+"/>
  );
};

export default Add;
