import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";

import CheckBox from "components/utils/CheckBox";
import FormikControl from "./FormikControl";
import Select from "react-select";
import RegisterBottom from "./RegisterBottom";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
import endPoint from "../../config/endPoint";
import {registerUser, fetchUser } from "../../store/actions/userActions";
import { toast } from "react-toastify";
import Input from "components/utils/Input";
import StyledButton from "components/utils/StyledButton";
import customToastError from "components/utils/CustomToast/customToastError";
import { Link } from 'react-router-dom';




const Span2 = styled.span`
  height: 21px;
  font-family: "Avenir";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #202020;
  margin: 10px 0;
`;

const link = styled.a`
height: 21px;
font-family: "Avenir";
font-style: normal;
`;
const CheckboxButtonWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const options = [
  { key: "Gender", value: "" },
  { key: "Male", value: "male" },
  { key: "Female", value: "female" },
];

function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data, { setSubmitting }) => {
      // console.log(data)
      dispatch(
        registerUser({ username: data.username, password: data.password, email: data.email })
      ).then((json) => {

        if (json.error) {
       
          customToastError(json.error.message);

   
        } else {
          const path = localStorage.getItem('path');
          localStorage.removeItem('path');
          navigate("/confirmation")
          // navigate("/home");
        }
        setSubmitting(false);
      });
    },
    [dispatch]
  );

  return (
      <div className="form-wrapper">
        <h6>Nice to meet you, fill in your data</h6>
          <Formik
            initialValues={{
              // gender: "",
              email: "",
              password: "",
              repeatPassword: "",
              username: "",
              termsAndConditions: false,
              privacyPolicy: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
              // gender: Yup.string().required("Required"),
              username: Yup.string()
                .min(1, "Full Name  is too short - should be 1 chars minimum.")
                .max(
                  50,
                  "Full Name  is too long - should be no more than 50 chars."
                )
                .required("Required"),
              email: Yup.string().email().required("Required"),
              password: Yup.string().required("No password provided."),
                // .matches(
                //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1}).*$/,
                //   "Password should consist of at least 8 characters including at least 1 digit and at least 1 special character."
                // ),
              // .min(8, "Password is too short - should be 8 chars minimum.")
              // .matches(/(?=.*[0-9])/, "Password must contain a number."),

              repeatPassword: Yup.string()
                .when("password", {
                  is: (val) => (val && val.length > 0 ? true : false),
                  then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                  ),
                })
                .required("Both password need to be the same"),
              termsAndConditions: Yup.boolean().oneOf(
                [true],
                "You should accept Terms and conditions"
              ),
              //
              // privacyPolicy: Yup.boolean().oneOf(
              //   [true],
              //   "You should accept Privacy policy"
              // ),
            })}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                {
                  // <FormikControl
                  //   control="select"
                  //   label="select a gender"
                  //   name="gender"
                  //   mediawidth="100%"
                  //   options={options}
                  // />
                  // {errors.gender && touched.gender && (
                  //   <div className="input-RegisterFeedback">
                  //     {errors.gender}
                  //   </div>
                  // )}
                }
                  <input
                    type="text"
                    placeholder="Username"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.username && touched.username && "error"}
                  />
                  {errors.username && touched.username && (
                    <div className="input-RegisterFeedback">
                      {errors.username}
                    </div>
                  )}
                  <input
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.email && touched.email && "error"}
                  />
                  {errors.email && touched.email && (
                    <div className="input-RegisterFeedback">{errors.email}</div>
                  )}
                  <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.password && touched.password && "error"}
                  />
                  {errors.password && touched.password && (
                    <div className="input-RegisterFeedback">
                      {errors.password}
                    </div>
                  )}
                  <input
                    name="repeatPassword"
                    type="password"
                    placeholder="Repeat Password"
                    value={values.repeatPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.repeatPassword && touched.repeatPassword && "error"
                    }
                  />
                  {errors.repeatPassword && touched.repeatPassword && (
                    <div className="input-RegisterFeedback">
                      {errors.repeatPassword}
                    </div>
                  )}
                  <PasswordStrengthMeter
                    // mediawidth="calc(100% - 100px)"
                    // width="calc(100% - 100px)"
                    password={values.password}
                  />
                  <span>
                    Your password should consist of at least 8 characters
                    including at least 1 digit and at least 1 special character.
                  </span>
                  <CheckboxButtonWrapper>
                    <Span2>    
                      <CheckBox
                        onChange={handleChange}
                        value={values.termsAndConditions}
                        type="checkbox"
                        name="termsAndConditions"
                        border="2px solid #002052"
                        margin="0px 20px 0px 0"
                      />
                      Accept <Link to={"/terms-and-conditions"} style={{ textDecoration: 'underline', marginLeft: '2px' }}> Terms and conditions</Link>
                    </Span2>
                    {errors.termsAndConditions &&
                      touched.termsAndConditions && (
                        <span className="input-RegisterFeedback">
                          {errors.termsAndConditions}
                        </span>
                      )}
                      
                  </CheckboxButtonWrapper>

                    <StyledButton
                      fontSize="16px"
                      width="50%"
                      height="52px"
                      type="submit"
                      mediaWidth="100%"
                      // disabled={isSubmitting}
                    >
                      Register
                    </StyledButton>
                </form>
              );
            }}
          </Formik>
      </div>
  );
}

export default Register;
