import styled from "styled-components";

const Input = styled.input`
  ${({
    backgroundColor,
    boxShadow,
    borderRadius,
    border,
    color,
    display,
    height,
    margin,
    name,
    placeholder,
    type,
    width,
    fontWeight,
    maxWidth,
    outline,
  }) => {
    return {
      fontWeight,
      border,
      type,
      width,
      height,
      margin,
      placeholder,
      name,
      borderRadius,
      boxShadow,
      color,
      backgroundColor,
      display,
      maxWidth,
      outline,
    };
  }}
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
    font-weight: ${({ placeholderWeight }) => placeholderWeight || 800};
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme, placeholderColor }) =>
      placeholderColor || theme.dark_Grey};
    font-weight: ${({ placeholderWeight }) => placeholderWeight || 800};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme, placeholderColor }) =>
      placeholderColor || theme.dark_Grey};
    font-weight: ${({ placeholderWeight }) => placeholderWeight || 800};
  }

  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: start;
  padding: 0 15px;
  width: ${({ width }) => width || "100%"};

  @media (max-width: 767px) {
    width: ${({ mediaWidth }) => mediaWidth || "240px"};
    height: ${({ mediaHeight, height }) => mediaHeight || height};
  }
`;

Input.defaultProps = {
  border: "1px solid rgba(134, 117, 79, 0.2)",
  backgroundColor: "fff",
  boxShadow: "",
  borderRadius: "6px",
  color: "#000",
  height: "56px",
  margin: "0px",
  name: "",
  placeholder: "",
  type: "text",
  width: "360px",
  fontWeight: "1000",
  maxWidth: "none",
  outline: "0px",
};

export default Input;
