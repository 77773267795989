import React, { useState } from "react";

const ContactUs =  (props) => {
  
  return (
    <div className="footer-share" >
        <a href="mailto:gor@bigtale.me?subject=Tell me more about Big Tale" >
            <div className="nav_button">
                <h4>Contact us</h4>
            </div>
        </a>
    </div>

  );
};

export default ContactUs;



