export default
  {
    // background: {color:'#e6dfdf', image:''},
    background:'#ffffff',
    image: '',
    position: [{right: 0, up: 0}],
    text: [],
    hero: [],
    lines: [],
    areas: [],
    camera: [{x: 0, y:0, z:0}],
    points: [],
    objects: []
  };
