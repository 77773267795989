import React, { Suspense, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams,useNavigate  } from "react-router-dom";
import PropertiesBar from "components/SelectionBar";
import PropertiesBody from "./PropertiesBody";
import ModelCanvas from "./ModelCanvas";
import PropertiesList from "./PropertiesList";
// import Leftbar from "components/layout/LeftBar";
import Input from "components/utils/InputNew";
import Button from "components/utils/Btn";
import heroProperties from "config/heroProperties";
import myHero from "config/myHeroProps";
import { saveHero, editHero, setHero, getHero } from "store/actions/heroActions";

const HeroModel =  (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams ();
  const { hero } = useSelector((state) => state.hero);
  const { list } = useSelector((state) => state.list);


  const obj = {name: '', description: '',  properties: null};;
  const [currentPropertyIndex, setPropertyIndex] = useState(-1);
  const [myHeroProps, setHeroProps] = useState(myHero);
  const [heroData, setData] = useState(obj);
  const currentProp = heroProperties[currentPropertyIndex];

  useEffect(() => {
    if(id) {
      dispatch(getHero(id));
      return;
    }
    dispatch(setHero(null));
  }, [id]);

  useEffect(() => {
    if(hero && hero.data) {
      setHeroProps(hero.data.attributes.properties);
      setData(hero.data.attributes);
      return;
    }
    setHeroProps(myHero);
    setData(obj);

  }, [hero]);

  const setNewPropertyIndex = (index) => {
    let propertiesLength = heroProperties.length;
    let newIndex = index < -1 ? -1 : index >= propertiesLength  ? propertiesLength - 1 : index;
    setPropertyIndex(newIndex);
  }

  const changeValue = (value, name) => {
    let myNewObj = [...myHeroProps];

    myNewObj[currentProp.index][name] = value;
    // if((currentProp.index === 5 || currentProp.index === 1) && name === 'color' ) {
  
    //   myNewObj[7].color = value;
    //   myNewObj[9].color = value;
    //   myNewObj[5].color = value;

      
    // } else 
    if(currentProp.index === 4 && name === 'color') {
      myNewObj[6].color = value;
    }
    setHeroProps(myNewObj);
  }

  const changeData = (value, name) => {
    let myNewData = {...heroData};
    myNewData[name] = value;
    // console.log(myNewData)
    // setData(myNewData);
    setData(myNewData);

  }

  const saveHeroData = () => {
    let myNewData = {...heroData};
    myNewData.properties = myHeroProps;
    // /api/heroes

    if(hero && hero.data && hero.data.id) {
      dispatch(
        editHero({data: myNewData }, hero.data.id)
      )
      return;
    }
    dispatch(
      saveHero({data: myNewData })
    ).then((json) => {
      // console.log(hero)
      if (json.error) {
        // customToastError("Invalid username/password");
      } else {
        navigate(`/edit-character/${json.payload.id}`);
      }
      // setSubmitting(false);
    });
    // console.log(JSON.stringify(myNewData))
  }

  return (
    <div className="main-container">
      <PropertiesList
        index={currentPropertyIndex}
        setPropertyIndex={setNewPropertyIndex}
        heroProperties={heroProperties}
        buttonDisabled={!heroData.name || !heroData.description}
        save={saveHeroData}
      />
      <div className="dashboard-style">

        <div className="selection-bar">
          {/* <PropertiesBar
            index={currentPropertyIndex}
            setPropertyIndex={setNewPropertyIndex}
            properties={heroProperties}
          /> */}
          {
            currentPropertyIndex === -1 ?
            <div>
              <Input placeholder="Name" name="name" changeValue={changeData} defaultValue={heroData.name}/>
              <Input placeholder="Description" name="description" changeValue={changeData} defaultValue={heroData.description}/>

            </div> :
            <PropertiesBody
              currentProperty={currentProp}
              defaultValue={myHeroProps[currentProp.index]}
              gender={myHeroProps[0].type}
              changeValue={changeValue}
            />
          }

          {/* <Button disabled={!heroData.name || !heroData.description} handleClick={saveHeroData} text="Save"/> */}
        </div>
        <div className="model-wrapper">
          <ModelCanvas  currentProp={currentProp} myHeroProps={myHeroProps}/>
        </div>
      </div>
    </div>
  );
};

export default HeroModel;
