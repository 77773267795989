import React from "react";
import InputNumberWithArrows from "components/utils/InputNumberWithArrows";

const CameraCont =  ({changeValue, camera}) => {
  return (
    <div>
      <InputNumberWithArrows label={'X'} value={camera.x} changeValue={(val) => changeValue(val, 'x','camera')}/>
      <InputNumberWithArrows label={'Y'} value={camera.y} changeValue={(val) => changeValue(val, 'y','camera')}/>
      <InputNumberWithArrows label={'Z'} value={camera.z} changeValue={(val) => changeValue(val, 'z','camera')}/>
      <InputNumberWithArrows label={'Zoom'} value={camera.zoom || 3} changeValue={(val) => changeValue(val, 'zoom','camera')}/>
    </div>

  );
};

export default CameraCont;
