import { storyActions } from "../types";

const initialState = {
  story: null,
  storyList: null
};

const storyDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case storyActions.FETCH_STORY_PENDING:
      return {
        ...state,
        story: {...state.story},
      };
    case storyActions.FETCH_STORY_SUCCESS:
      return {
        ...state,
        story: action.payload || null,
      };
    case storyActions.FETCH_STORY_FAILED:
      return {
        ...state,
        story: null,
      };
    case storyActions.FETCH_STORY_LIST:
      return {
        ...state,
        storyList: action.payload || null,
      };
    default:
      return state;
  }
};

export default storyDataReducer;
