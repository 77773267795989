import React, { useState, useRef, useMemo, useEffect } from "react";
import {useLoader, useThree, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {clone} from 'three/examples/jsm/utils/SkeletonUtils';
import { MeshStandardMaterial } from 'three';

const Model =  ({myGlb,  scale, myHeroProps, changes, getStartPosition}) => {
  const { scene, nodes, materials, animations } = useLoader(GLTFLoader, myGlb);
  // const skeleton = scene.getObjectByName('yourRiggedPrimitive').skeleton;

  const ref = useRef();
  const [properties, setProperties] = useState({});
  const movement = useRef({});
  const [runMove, setRunMove] = useState({});
  
  
  // let array = []
  // for (let key in nodes) {
  //   if(nodes[key].isBone) {
  //       array.push({name: key, display_name: key})
  //   }
  
  // }
  // console.log(array)


  const handleClick = (a) => {
    // console.log('a',a)
    // console.log(changes);
  }
  
  useMemo(() => {
    
    setProperties({});
    scene.traverse( function( object ) {
        object.frustumCulled = false;
    });


  }, [myGlb]); //myHeroProps.color, changes,


  useEffect(() => {
    if(getStartPosition) {
      getStartPosition({...nodes});
    }
  }, []);


  const changeMaterial = (currentChild, matVar) => {
    let newProperties = {...properties};
    currentChild.forEach((item, i) => {
      if(item.material && item.material.name === 'color') {
        let newMaterial = item.material.clone();
        newMaterial.color.set(myHeroProps.color);
        let materialVar = `${matVar}${i}-material`;
        newProperties[materialVar] = newMaterial;
        setProperties(newProperties);
      //} else if(item.isGroup ) {
      } else  if(item.children.length ) {
        changeMaterial(item.children, `${matVar}${i}-children-`)
      }
    });



  }

  const changeMovement = (currentChild, moveVar, boneName, parameter, dir, value) => {
    let newMovement = {...movement.current};

    currentChild.forEach((item, i) => {
      if(!item.isMesh) {
        if(item.name === boneName) {
          let movementVar = `${moveVar}${i}-${parameter}-${dir}`;
          newMovement[movementVar] = value;
          movement.current = newMovement;
        } else if(item.children.length) {
          changeMovement(item.children, `${moveVar}${i}-children-`, boneName, parameter, dir, value)
        }

  
      }
    
    });

  }

  useEffect(() => {
    if(myHeroProps && materials.color && ref.current)  {

      changeMaterial(ref.current.children,'children-');
      
    }
  }, [myHeroProps.color, myGlb]); //if changed color and type

  useEffect(() => {
    // console.log(changes)
    if(changes) {
      let isArray = Array.isArray(changes);
      if(isArray) {
        movement.current = {};
      }
      let changesArray = isArray ? changes: [changes];
      changesArray.forEach((item, i) => {
        formatChanges(item)
      });


      setRunMove(movement.current)



    }
  }, [changes]);
  // scene.uuid = 'aa'
  // const a = scene.clone();
  // a.parent = scene.parent;

  // const copy4 = window.structuredClone(scene)
  // console.log(Object.assign(a, scene))
  // const a  = () => {
  //
  //   return
  // }

  const formatChanges = (changes) => {
    for(let key in changes) {
      let arr = key.split(' ');

      if(nodes[arr[0]]) {
    
        changeMovement(ref.current.children,'children-', arr[0], arr[1], arr[2], changes[key]);

      } else if(arr[0] === 'emotion') {
        if(scene.children[0].children[1] && scene.children[0].children[1].morphTargetInfluences) {
          let newMovement = {...movement.current};
          const morphTargetInfluences = [...scene.children[0].children[1].morphTargetInfluences];
          morphTargetInfluences[arr[1]] = changes[key];
          let movementVar = 'children-0-children-1-morphTargetInfluences';
          newMovement[movementVar] = morphTargetInfluences;
          movement.current = newMovement;
        }
        
      }
    }
  }
  // console.log(change)
  return (
    <>
      <primitive
          ref={ref}
          key={myGlb}
          object={ clone(scene) }
          type="mesh"
          // position={position}
          scale={scale}
          {...properties}
          {...runMove}

          // children-0-children-1-morphTargetInfluences={[0.5,0]}
          // children-0-children-0-scale={Object.values(changes)[0]}
          // {u}={new MeshStandardMaterial({color: myHeroProps.color})}


          // children-0-material-thickness={{value: 3.12, min: 0, max: 5, step: 0.01}}
          // onClick={() => console.log('onClick')}
          // onPointerDown={handleClick}
          // rotation={rotation}
          // name={'aa'}
          // clipPlanes={[new THREE.Plane(new THREE.Vector3(0.001, 0, 0), 0.25)]}

          receiveShadow={true}
          castShadow
          // dispose={false}
          // frustumCulled={false}

      />

    </>
  );
};

export default Model;

