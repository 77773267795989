import React, {useState} from "react";
import RemoveIcon from "components/utils/RemoveIcon";
import Line from './LineChangeBody';
import AddBtn from "components/utils/AddBtn";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

const LinesCont =  ({changeValue, add, lines, remove}) => {
  const [selected, setSelected] = useState(0);

  const addItem = () => {
    add('lines');
    setSelected(lines.length);
  }

  const removeItem = () => {
    remove('lines', selected);
    setSelected(lines.length - 1);
  }
  const renderHeaderArea = () => {
    return lines.map((el, index) => {
      let isSelected = index === selected;
      let liClassname = isSelected ? "selected li-tab-header": 'li-tab-header';
      let className = isSelected ? "selected tab-header": 'tab-header';
      let icon = isSelected ? faChevronDown :faChevronUp;
      return <li key={index} className={liClassname} onClick={() => {setSelected(index)}}>
                <div  className={className}>
                  <span>Line {index}</span>
                  <div className="icons">
                    <RemoveIcon remove={removeItem}/>
                    <FontAwesomeIcon  icon={icon} />
                  </div>

                </div>

                {
                  isSelected ?
                  <Line key={index} changeValue={changeValue} line={el} index={index}/>
                  :''
                }
              </li>
    })
  }
  return (
    <div>
      <ul>
        {renderHeaderArea()}
      </ul>
      <AddBtn handleClick={addItem}/>
    </div>

  );
};
  // <input type="number" onChange={(e) => changeValue(e.target.value, 'scale')} defaultValue={6} step={0.25} min={0.01}/>

export default LinesCont;
