import React, { useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import LogOutIcon from "components/iconsAndLogos/LogOutIcon";

import { logoutUser } from "store/actions/userActions";
import { SectionTitle } from "components/utils/Typography";
import Container from "components/utils/Container";
import EditForm from "./EditForm";
import BlackArrowLeft from "components/iconsAndLogos/BlackArrowLeft";
import { useMediaQuery } from "react-responsive";
import Header from "components/layout/Header";

const MainWrapper = styled.div`
  background-color: ${({ theme }) => theme.white};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 100px;
  }

  @media (min-width: 1440px) {
    margin-top: 100px;
  }
`;

const MainTitle = styled.div`
  margin-top: 25px;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 32px;

  @media (min-width: 1024px) {
    width: 100%;
    max-width: 480px;
    margin: 0 0 0 30px;
  }
`;

const FormWrapper = styled.div`
  margin-top: 26px;

  @media (min-width: 1024px) {
    margin-top: 34px;
  }

  @media (min-width: 1440px) {
    margin-top: 30px;
  }
`;

const BackArrowAndHeaderWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
`;

function Profile() {
  const { user } = useSelector((state) => state.user); // role_id: '1' is equals to Creater
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLaptop = useMediaQuery({ maxWidth: 1024 });

  const handleSignOut = useCallback(() => {
    dispatch(logoutUser()).then(() => {
      navigate("/");
    });
  }, [dispatch]);

  return (
    <>
      <Header mobileDisabled />
      <MainWrapper>
        <Container>
          <Content>
            {isLaptop && (
              <BackArrowAndHeaderWrapper>
                <BlackArrowLeft onClick={() => navigate("/account")} />
              </BackArrowAndHeaderWrapper>
            )}

            <MainTitle>
              <SectionTitle>Your Profile</SectionTitle>
            </MainTitle>

            <ContentWrapper>
              <LogOutIcon onClick={handleSignOut} />

              <FormWrapper>
                <EditForm user={user}/>
              </FormWrapper>
            </ContentWrapper>
          </Content>
        </Container>
      </MainWrapper>
    </>
  );
}

export default Profile;
