import { useFrame } from '@react-three/fiber'


const Camera =  ({cameraProps, zoom}) => {
    useFrame(({camera}) => {
        camera.zoom = cameraProps && cameraProps.zoom ? cameraProps.zoom : zoom;
        if(cameraProps) {
            camera.lookAt(cameraProps.x, cameraProps.y,cameraProps.z);
        }
        camera.updateProjectionMatrix();
    });
};
export default Camera;
