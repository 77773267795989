import React, { Component } from 'react';

export class Popup extends Component {
  componentDidMount = () => {
    // document.removeEventListener("mousedown", this.hide);
    document.addEventListener("mousedown", this.hide);
    setTimeout(function() {
      this.popup && this.popup.focus();
    }.bind(this), 0);
  }

  keyDownHandler = (e) => {
    if (e.keyCode === 27) { // Esc
      this.props.onClose(e);
    }
  }

  hide = (e)  => {
    this.props.onClose();
  }

  render = () => {
    const { show, caption, onClose, children, width, height, zIndex, className } = this.props;
    const w = width || 600;
    const h = height || 270;
    const popupStyles = {
      display: 'block',
      width: w,
      height: h,
      left: `calc(50% - ${w / 2}px)`,
      top: `calc(50% - ${h / 2}px)`
    };

    if(zIndex) {
      popupStyles.zIndex = zIndex;
    }

    if (!show) {
      return null;
    }

    return (
          <div ref={(p) => {this.popup = p}} onMouseDown={(e) => {e.stopPropagation()}} tabIndex='-1' onKeyDown={this.keyDownHandler} className={(className || '') + ' popup-body drag'} role="dialog" style={popupStyles}>
            <div className='canvas-caption'>{caption}</div>
            <button className='close' aria-label="close" onClick={onClose}>X</button>
            {children}
          </div>
    );
  }
}
