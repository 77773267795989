export default [
  {color: '#d2a668', type: 'g', name:'gender' },
  {color: '#d2a668', type: '0', name:'body'},
  {color: '#123652', type: '0', name:'shirts'},
  {color: '#d2a699', type: '0', name:'pants'},
  {color: '#EF1F1F', type: '0', name:'hair'},
  {color: '#f00265', type: '0', name:'eyes'},
  {color: '#000000', type: '0', name:'eyebrows'},
  {color: '#000000', type: '', name:'acc'},//accessories
  {color: '#000000', type: '', name:'shoes'},//shoes
  // {color: '#d2a668', type: '0', name:'nose'},
  // {color: '#a01313', type: '0', name:'mouth'},
  // {color: '#d2a668', type: '0', name:'ears'},
  // {color: '#d2a668', type: '0', name:'head'},

];
