import React from "react";

function SuccessIcon(props) {
  return (
    <svg
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4767 0.148682V0.247729V10.1525C76.2177 12.8268 91.6196 32.5868 88.9453 54.3277C86.6672 72.3544 72.5034 86.6668 54.4767 88.7963V98.7011C81.7148 95.9772 101.524 71.8096 98.8006 44.5715C96.572 21.0477 77.9015 2.4763 54.4767 0.148682ZM44.5715 0.297311C34.9144 1.23826 25.7029 4.95255 18.1753 11.1925L25.2572 18.5221C30.8039 14.0649 37.4896 11.1925 44.5715 10.2021V0.297311ZM11.1928 18.1753C4.95281 25.7029 1.23852 34.8648 0.248047 44.5715H10.1528C11.0938 37.5391 13.8671 30.8534 18.2747 25.2572L11.1928 18.1753ZM66.8581 32.1906L42.6905 56.3582L32.1915 45.8592L26.9419 51.1087L42.6905 66.8573L72.1077 37.4401L66.8581 32.1906ZM0.297444 54.4763C1.28792 64.183 5.10125 73.3449 11.2422 80.8725L18.2746 73.7906C13.9165 68.1944 11.0936 61.5087 10.2022 54.4763H0.297444ZM25.2572 81.0705L18.1753 87.8553C25.6534 94.0953 34.8648 98.0077 44.5715 99.0477V89.1429C37.5391 88.2515 30.8534 85.4286 25.2572 81.0705Z"
        fill="#76D549"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="100"
        height="100"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.4767 0.148682V0.247729V10.1525C76.2177 12.8268 91.6196 32.5868 88.9453 54.3277C86.6672 72.3544 72.5034 86.6668 54.4767 88.7963V98.7011C81.7148 95.9772 101.524 71.8096 98.8006 44.5715C96.572 21.0477 77.9015 2.4763 54.4767 0.148682ZM44.5715 0.297311C34.9144 1.23826 25.7029 4.95255 18.1753 11.1925L25.2572 18.5221C30.8039 14.0649 37.4896 11.1925 44.5715 10.2021V0.297311ZM11.1928 18.1753C4.95281 25.7029 1.23852 34.8648 0.248047 44.5715H10.1528C11.0938 37.5391 13.8671 30.8534 18.2747 25.2572L11.1928 18.1753ZM66.8581 32.1906L42.6905 56.3582L32.1915 45.8592L26.9419 51.1087L42.6905 66.8573L72.1077 37.4401L66.8581 32.1906ZM0.297444 54.4763C1.28792 64.183 5.10125 73.3449 11.2422 80.8725L18.2746 73.7906C13.9165 68.1944 11.0936 61.5087 10.2022 54.4763H0.297444ZM25.2572 81.0705L18.1753 87.8553C25.6534 94.0953 34.8648 98.0077 44.5715 99.0477V89.1429C37.5391 88.2515 30.8534 85.4286 25.2572 81.0705Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
    </svg>
  );
}

export default SuccessIcon;
