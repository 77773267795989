import React from "react";
// import InputNumber from "components/utils/InputNumber";

const InputNumberWithArrows =  ({label, value=0, changeValue, changeProperties, step, min, max, type}) => {
  const currentStep = step || 0.25;
  let buttonLabelMinus = type === 'x' ? 'left' : type === 'y' ? 'down': type === 'scale' ? 'small':  type === 'z' ? 'back': '-';
  let buttonLabelPlus = type === 'x' ? 'right' :  type === 'y' ? 'up': type === 'scale' ? 'big': type === 'z' ?'front': '+';
  const change = (val) => {
    if( (min  === undefined || val >= min) && (max  === undefined || val <= max)) {
      changeValue(val, changeProperties)
    }

  }
  return (
    <div className="input-with-buttons">
      {label ? 
      <label>{label}</label>:''
      }
      <div>
        <button className="arrows" onClick={() => change(value - currentStep)}>{buttonLabelMinus}</button>
        <input type="number" value={value} onChange={(e) => change(e.target.value)} max={max} min={min}  step={step || 0.25} />
        <button className="arrows" onClick={() => change(+value + currentStep)}>{buttonLabelPlus}</button>
      </div>
    </div>
  );
};

export default InputNumberWithArrows;
