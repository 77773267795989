import logo from './logo.svg';
import './App.css';
import "./App.scss";
import "styles/movementPage.scss";
import "styles/createStory.scss";
import "styles/styles.scss";
import "styles/simpleControls.scss";
import "styles/heroStyles.scss";
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import TRACKING_ID from "config/trackingId"
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/bootstrap.css";

import { fetchUser } from "store/actions/userActions";
import Spinner from "./components/spinner";
import Router from "./routes";
import ReactGA from 'react-ga4';
ReactGA.initialize(TRACKING_ID);

const  App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: location.pathname + location.search });
  }, [location]);
 

  useEffect(() => {
    if(localStorage.path) {
      localStorage.removeItem('path'); //remove if sign in from the other page
    }

    if(localStorage.token) {
      setLoading(true);

      Promise.all([dispatch(fetchUser())])
      .then(() => setLoading(false));
    } else {
      setLoading(false)
    }

  }, []);

  return (
    <div className="App">
      {loading ? <Spinner /> : <Router />}
      <ToastContainer/>
    </div>
  );
}

export default App;
