import React from "react";
import Header from "components/layout/Header";
import Leftbar from "components/layout/LeftBar";
import MovementContent from "./MovementContent"


export default (props) => {

  return (
      <div>
         <Header  />
         <MovementContent />
      </div>
  );
};
