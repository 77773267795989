import React from "react";

function UserImageIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35C27.165 35 35 27.165 35 17.5Z"
        fill="#F2F2F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.057 8.11395C16.5286 8.16381 16.3378 8.18944 16.0122 8.25416C12.954 8.86212 10.5603 11.228 9.91605 14.2796C9.79676 14.8445 9.76066 15.2159 9.7604 15.8806C9.76025 16.3214 9.77315 16.59 9.80614 16.8339C10.0282 18.4748 10.6708 19.8764 11.7627 21.1016C11.9788 21.3441 12.3151 21.6629 12.6552 21.9479L12.7329 22.0129L12.5377 22.0683C12.4303 22.0988 12.0721 22.2118 11.7417 22.3195C9.36689 23.0934 7.2187 24.3286 5.26468 26.0438C4.83562 26.4205 4.09456 27.1601 3.72881 27.5767L3.44727 27.8974L3.6418 28.1511C4.71218 29.547 6.07396 30.8461 7.53419 31.8644C13.5908 36.0878 21.6944 36.0377 27.6975 31.7398C28.5253 31.1472 29.1564 30.6084 29.9208 29.8418C30.6304 29.1302 31.6259 27.9544 31.5833 27.8782C31.5384 27.7979 30.9456 27.1572 30.5598 26.7721C28.7072 24.9228 26.4484 23.4715 24.0003 22.5576C23.5807 22.401 22.9844 22.2047 22.5166 22.0694L22.3214 22.0129L22.3991 21.9479C23.6915 20.8653 24.5133 19.64 24.9801 18.0997C25.2051 17.3572 25.2937 16.7301 25.2937 15.8806C25.2937 14.8165 25.1288 13.9393 24.7525 13.0016C23.6427 10.2356 21.0887 8.35793 18.1176 8.12354C17.8043 8.09883 17.2687 8.094 17.057 8.11395Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}

export default UserImageIcon;
