import React, {useState} from "react";
import StepListItem from "./StepListItem"
import AddBtn from "components/utils/AddBtn";
import Button from "components/utils/Btn";

const StepList =  ({viewMotions, setPropertyIndex, index, setStep, addStep, removeStep, save}) => {
  const selectItem = (i) => {
    setPropertyIndex(i);
    if(i != -1) {
      setStep(viewMotions[i]);
    }
    
  };

  const renderProperties = () => {
    return viewMotions.map((el, i) => {

      return  <StepListItem
              key={i}
              index={i}
              selectedIndex={index}
              last={viewMotions.length - 1}
              selectItem={selectItem}
              removeStep={removeStep}
              />
    });
  }

  const className = index === -1 ?  "selected sidebar-item" : "sidebar-item";

  return (
      <div className="left-container">
        <ul id="sidebar-nav" className="sidebar-nav">
          <li className={className} key={-1} onClick={() => selectItem(-1)}><div>About</div></li>;
          {renderProperties()}
        </ul>
        <AddBtn handleClick={addStep}/>
        <div>
          <Button handleClick={save} text="Save"/>
        </div>
        
      </div>
  );
};

export default StepList;
