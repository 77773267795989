import endPoint from "config/endPoint";
import { heroActions } from "../types";


export const saveHero = (data) => {
  const fetchHeroFailed = () => ({
    type: heroActions.FETCH_HERO_FAILED,
    payload: data,
  });

  const fetchHeroSuccess = (hero) => ({
    type: heroActions.FETCH_HERO_SUCCESS,
    payload: hero,
  });

  const fetchHeroPending = () => ({
    type: heroActions.FETCH_HERO_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchHeroPending());
    return fetch(`${endPoint.endpoint}/heroes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(fetchHeroSuccess(json));
      })
      .catch(() => dispatch(fetchHeroFailed));
  };
};

export const setHero = (hero) => {
  return {
    type: heroActions.FETCH_HERO_SUCCESS,
    payload: hero
  }
}

export const editHero = (data, id) => {
  const fetchHeroFailed = () => ({
    type: heroActions.FETCH_HERO_FAILED,
    payload: data,
  });

  const fetchHeroSuccess = (hero) => ({
    type: heroActions.FETCH_HERO_SUCCESS,
    payload: hero,
  });

  const fetchHeroPending = () => ({
    type: heroActions.FETCH_HERO_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchHeroPending());
    return fetch(`${endPoint.endpoint}/heroes/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(fetchHeroSuccess(json));
      })
      .catch(() => dispatch(fetchHeroFailed));
  };
};

export const fetchHeroes = (id) => {

  const fetchHeroList = (list) => ({
    type: heroActions.FETCH_HERO_LIST,
    payload: list,
  });


  return async (dispatch) => {
    return fetch(`${endPoint.endpoint}/heroes?filters[owner][id][$eq]=4&filters[owner][id][$eq]=${id}`, {
    // return fetch(`${endPoint.endpoint}/heroes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(fetchHeroList(json));
      })
      .catch(() => dispatch(fetchHeroList([])));
  };
};

export const getHero = (id) => {
  const fetchHeroFailed = () => ({
    type: heroActions.FETCH_HERO_FAILED,
    payload: null,
  });

  const fetchHeroSuccess = (hero) => ({
    type: heroActions.FETCH_HERO_SUCCESS,
    payload: hero,
  });

  const fetchHeroPending = () => ({
    type: heroActions.FETCH_HERO_PENDING,
  });

  return async (dispatch) => {
    dispatch(fetchHeroPending());
    return fetch(`${endPoint.endpoint}/heroes/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(fetchHeroSuccess(json));
      })
      .catch(() => dispatch(fetchHeroFailed));
  };
};

export const fetchHeroesByCreaterId = (id) => {

  const fetchHeroList = (list) => ({
    type: heroActions.FETCH_HERO_LIST,
    payload: list,
  });


  return async (dispatch) => {
    // api/tasks?filters[owner][id][$eq]=1

    return fetch(`${endPoint.endpoint}/heroes?filters[owner][id][$eq]=4`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
      .then((res) => res.json())
      .then((json) => {
        return dispatch(fetchHeroList(json));
      })
      .catch(() => dispatch(fetchHeroList([])));
  };
};
