import React from "react";
import PropertiesItem from "./PropertyItem";

const PropertiesBody =  ({currentProperty, changeValue, defaultValue, gender}) => {
  const def = defaultValue.type; // || currentProperty.type.values[0];
  const defColor = defaultValue.color

  return (
      <div className="props-cont">
        {
        defaultValue.name !== 'gender' ?
        <div className="title-cont">
          <span className="title">{`${defaultValue.name} color`}</span>
          <input type="color" value={defColor} onChange={(e) => changeValue(e.target.value, 'color')}/>
        </div>:''
        }
        <div className="title-cont"><span className="title">{`${defaultValue.name} types`}</span></div>
        <div className="properties-body">
        {
          currentProperty.values[gender].map((el, index) => {
            return <PropertiesItem key={index} selected={el === def} value={el} name={defaultValue.name} gender={gender} changeValue={() => changeValue(el, 'type')}/>
          })
        }
        </div>
      </div>
  );
};

export default PropertiesBody;
