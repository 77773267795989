import React, {useState} from "react";
import PointChangeBody from "./PointChangeBody";
import AddBtn from "components/utils/AddBtn";
import RemoveIcon from "components/utils/RemoveIcon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';



const PointsCont =  ({changeValue, add, points, page, fullStory, changeBg, setStory, remove}) => {
  const [selected, setSelected] = useState(0);

  const addItem = () => {
    add('points');
    setSelected(points.length);
  }

  const removeItem = () => {
    remove('points', selected);
    setSelected(points.length - 1);
  }
  const renderHeaderArea = () => {
    return points.map((el, index) => {
      let isSelected = index === selected;
      let liClassname = isSelected ? "selected li-tab-header": 'li-tab-header';
      let className = isSelected ? "selected tab-header": 'tab-header';
      let icon = isSelected ? faChevronDown :faChevronUp;
      return <li key={index} className={liClassname} onClick={() => {setSelected(index)}}>
                <div  className={className}>
                  <span>Point {index}</span>
                  <div className="icons">
                    <RemoveIcon remove={removeItem}/>
                    <FontAwesomeIcon  icon={icon} />
                  </div>

                </div>

                {
                  isSelected ?
                  <PointChangeBody key={index} changeValue={changeValue} point={el} index={index} changeBg={changeBg} add={add} remove={remove} page={page} fullStory={fullStory} setStory={setStory}/>
                  :''
                }
              </li>
    })
  }
  return (
    <div>
      <ul>
        {renderHeaderArea()}
      </ul>

      <AddBtn handleClick={addItem}/>
    </div>

  );
};

export default PointsCont;
