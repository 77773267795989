import React, {useRef}  from "react";
import Model  from "./Model";
import DragControl from "./Dragable";

const CustomObj =  ({objProps, index, myStory, changeValue}) => {
  const scale = objProps.scale;
  const right = +objProps.right || 0;
  const up = objProps.up || 0;
  const zIndex = +objProps.zIndex || 0;
  const n = objProps.name || 0;
  const objRef = useRef('');


  return (
    <DragControl  property='objects' elRef={objRef} index={index} myStory={myStory} changeValue={changeValue}>
      <group ref={objRef} position={[right, up, zIndex]}>
        <Model   myGlb={require(`assets/glb/obj/obj_${n}.glb`)}  scale={scale} myHeroProps={objProps}/>
      </group>
    </DragControl>
  );
};
export default CustomObj;
{/* <mesh  position={[right, up, zIndex]} onClick={() => console.log('onClick')}>
          <boxGeometry args={[width, height, 0.01]} />
          <meshBasicMaterial attach="material" color={areaProps.color || null} map={texture} />
        </mesh> */}