import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { Canvas } from '@react-three/fiber'
// import Model  from "./Model";

import { fetchMovements } from "store/actions/movementActions";
import Item from "./Item";

const MovementsList =  (props) => {
  const dispatch = useDispatch();
  const { movementList } = useSelector((state) => state.movementList);
  const { user } = useSelector((state) => state.user);
  // console.log(list)
  useEffect(() => {
    dispatch(fetchMovements(user.id));
  }, []);

  return (
    <div className="main-container list-cont">
      <div className="heroes-list">
      {
        movementList ?
        movementList.data.map((el, index) => {
          return <Item key={index}  movement={el}  />
        })
        :''
      }
      </div>
    </div>
  );
};

export default MovementsList;
