import React from "react";
import { Link } from 'react-router-dom';

const Item = ({item}) => {

  return (
    <Link to={item.link} style={{ textDecoration: 'none' }}>
      <li className="sidebar-item">{item.name}</li>
    </Link>

  );
};
// sidebar-active
export default Item;
