import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchHeroes } from "store/actions/heroActions";
import Item from "./Item";

const HeroList =  (props) => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.list);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(fetchHeroes(user.id));
  }, []);

  return (
    <div className="main-container list-cont">
      <div className="heroes-list">
      {
        list ?
        list.data.map((el, index) => {
          return <Item key={index}  hero={el}  />
        })
        :''
      }
      </div>
    </div>
  );
};

export default HeroList;
