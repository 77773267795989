import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 20px 0;
  background-color: ${({ theme }) => theme.white};
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const Span1 = styled.span`
  height: 29px;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  margin: 20px 0;
  color: ${({ theme }) => theme.dark_Grey}; ;
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.light_Grey};
  // @media (max-width: 767px) {
  //   width: 320px;
  // }
`;

const ButtonTenat = styled.button`
  font-family: "Avenir";
  &:focus {
    outline: none;
  }
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
  align-content: center;
  width: 50%;
  border-radius: 10px;
  height: 52px;
  border: none;
  text-transform: uppercase;
  ${({ tenat, theme }) => {
    if (tenat) {
      return {
        backgroundColor: theme.blue_Grey,
        color: theme.white,
      };
    } else
      return {
        backgroundColor: theme.light_Grey,
        color: theme.dark_Grey,
      };
  }};

  // @media (max-width: 767px) {
  //   width: 160px;
  // }
`;

const ButtonLandLord = styled.button`
  font-family: "Avenir";
  &:focus {
    outline: none;
  }
  text-transform: uppercase;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
  align-content: center;
  width: 50%;
  border-radius: 10px;
  height: 52px;
  ${({ tenat, theme }) => {
    if (!tenat) {
      return {
        backgroundColor: theme.blue_Grey,
        color: theme.white,
      };
    } else
      return {
        backgroundColor: theme.light_Grey,
        color: theme.dark_Grey,
      };
  }};

  // @media (max-width: 767px) {
  //   width: 160px;
  // }
`;

function RegisterBottom({ setRole_id }) {
  const [tenat, setTenat] = useState(true);

  return (
    <div>
      <h6>Who are you?</h6>
      <div>
        <ButtonTenat
          onClick={(e) => {
            e.preventDefault();
            setTenat(true);
            setRole_id(1);
          }}
          tenat={tenat}
        >
          Creater
        </ButtonTenat>
        <ButtonLandLord
          onClick={(e) => {
            e.preventDefault();
            setTenat(false);
            setRole_id(2);
          }}
          tenat={tenat}
        >
          Player
        </ButtonLandLord>
      </div>
    </div>
  );
}

export default RegisterBottom;
