export default {
  black: "#000",
  bordaux: '#822f43',
  blue: "#538BF0",
  blue_Grey: "#1F182B",
  dark_Grey: "#2E3034",
  dark_Grey_Selected: "#404040",
  error: "#EB5757",
  fancy_Green: "#008222",
  inactive_Greyed: "#ccc",
  light_Blue: "#E2EAF9",
  light_Grey: "#F7F7F7",
  light_Grey_Inactive: "#777",
  light_Red: '#ec766e',
  text_Grey: "#4f4f4f",
  white: "#fff",

  logo_Red: "#ef3238"
};

/* @media (max-width: 1200px) {
  }
}
@media (max-width: 992) {
  } */
// @media (max-width: 767px) {

//  }
