export default
[
    {
        "name": "pelvis",
        "display_name": "pelvis"
    },
    {
        "name": "thighL",
        "display_name": "thighL"
    },
    {
        "name": "shinL",
        "display_name": "shinL"
    },
    {
        "name": "footL",
        "display_name": "footL"
    },
    {
        "name": "toesL",
        "display_name": "toesL"
    },
    {
        "name": "thighR",
        "display_name": "thighR"
    },
    {
        "name": "shinR",
        "display_name": "shinR"
    },
    {
        "name": "footR",
        "display_name": "footR"
    },
    {
        "name": "toesR",
        "display_name": "toesR"
    },
    {
        "name": "spine001",
        "display_name": "spine001"
    },
    {
        "name": "spine002",
        "display_name": "spine002"
    },
    {
        "name": "chest",
        "display_name": "chest"
    },
    {
        "name": "neck01",
        "display_name": "neck01"
    },
    {
        "name": "neck02",
        "display_name": "neck02"
    },
    {
        "name": "face_head",
        "display_name": "face_head"
    },
    // {
    //     "name": "face_jaw",
    //     "display_name": "face_jaw"
    // },
    // {
    //     "name": "face_eyeL",
    //     "display_name": "face_eyeL"
    // },
    // {
    //     "name": "face_eyeR",
    //     "display_name": "face_eyeR"
    // },
    // {
    //     "name": "face_earL",
    //     "display_name": "face_earL"
    // },
    // {
    //     "name": "face_earR",
    //     "display_name": "face_earR"
    // },
    // {
    //     "name": "face_cheekL",
    //     "display_name": "face_cheekL"
    // },
    // {
    //     "name": "face_cheekR",
    //     "display_name": "face_cheekR"
    // },
    // {
    //     "name": "face_eyeleshL",
    //     "display_name": "face_eyeleshL"
    // },
    // {
    //     "name": "face_eyeleshL01",
    //     "display_name": "face_eyeleshL01"
    // },
    // {
    //     "name": "face_eyeleshR",
    //     "display_name": "face_eyeleshR"
    // },
    // {
    //     "name": "face_eyeleshR01",
    //     "display_name": "face_eyeleshR01"
    // },
    {
        "name": "lh_clavicleL",
        "display_name": "lh_clavicleL"
    },
    {
        "name": "lh_upper_armL",
        "display_name": "lh_upper_armL"
    },
    {
        "name": "lh_lowerarmL",
        "display_name": "lh_lowerarmL"
    },
    {
        "name": "lh_handL",
        "display_name": "lh_handL"
    },
    // {
    //     "name": "lh_index01L",
    //     "display_name": "lh_index01L"
    // },
    // {
    //     "name": "lh_index02L",
    //     "display_name": "lh_index02L"
    // },
    // {
    //     "name": "lh_index03L",
    //     "display_name": "lh_index03L"
    // },
    // {
    //     "name": "lh_thumb01L",
    //     "display_name": "lh_thumb01L"
    // },
    // {
    //     "name": "lh_thumb02L",
    //     "display_name": "lh_thumb02L"
    // },
    // {
    //     "name": "lh_thumb03L",
    //     "display_name": "lh_thumb03L"
    // },
    // {
    //     "name": "lh_middle01L",
    //     "display_name": "lh_middle01L"
    // },
    // {
    //     "name": "lh_middle02L",
    //     "display_name": "lh_middle02L"
    // },
    // {
    //     "name": "lh_middle03L",
    //     "display_name": "lh_middle03L"
    // },
    // {
    //     "name": "lh_ring01L",
    //     "display_name": "lh_ring01L"
    // },
    // {
    //     "name": "lh_ring02L",
    //     "display_name": "lh_ring02L"
    // },
    // {
    //     "name": "lh_ring03L",
    //     "display_name": "lh_ring03L"
    // },
    // {
    //     "name": "lh_pinky01L",
    //     "display_name": "lh_pinky01L"
    // },
    // {
    //     "name": "lh_pinky02L",
    //     "display_name": "lh_pinky02L"
    // },
    // {
    //     "name": "lh_pinky03L",
    //     "display_name": "lh_pinky03L"
    // },
    // {
    //     "name": "lh_clavicleR",
    //     "display_name": "lh_clavicleR"
    // },
    {
        "name": "rh_upper_armR",
        "display_name": "rh_upper_armR"
    },
    {
        "name": "rh_lowerarmR",
        "display_name": "rh_lowerarmR"
    },
    {
        "name": "rh_handR",
        "display_name": "rh_handR"
    },
    // {
    //     "name": "rh_index01R",
    //     "display_name": "rh_index01R"
    // },
    // {
    //     "name": "rh_index02R",
    //     "display_name": "rh_index02R"
    // },
    // {
    //     "name": "rh_index03R",
    //     "display_name": "rh_index03R"
    // },
    // {
    //     "name": "rh_thumb01R",
    //     "display_name": "rh_thumb01R"
    // },
    // {
    //     "name": "rh_thumb02R",
    //     "display_name": "rh_thumb02R"
    // },
    // {
    //     "name": "rh_thumb03R",
    //     "display_name": "rh_thumb03R"
    // },
    // {
    //     "name": "rh_middle01R",
    //     "display_name": "rh_middle01R"
    // },
    // {
    //     "name": "rh_middle02R",
    //     "display_name": "rh_middle02R"
    // },
    // {
    //     "name": "rh_middle03R",
    //     "display_name": "rh_middle03R"
    // },
    // {
    //     "name": "rh_ring01R",
    //     "display_name": "rh_ring01R"
    // },
    // {
    //     "name": "rh_ring02R",
    //     "display_name": "rh_ring02R"
    // },
    // {
    //     "name": "rh_ring03R",
    //     "display_name": "rh_ring03R"
    // },
    // {
    //     "name": "rh_pinky01R",
    //     "display_name": "rh_pinky01R"
    // },
    // {
    //     "name": "rh_pinky02R",
    //     "display_name": "rh_pinky02R"
    // },
    // {
    //     "name": "rh_pinky03R",
    //     "display_name": "rh_pinky03R"
    // }
]