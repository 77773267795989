import React, {useState} from "react";
import Item from "./Item";
import NavigationCircle from "components/utils/NavigationCircle";
import InputColor from "components/utils/InputColor";
import CheckBox from "components/utils/CheckboxNew";

const BgBody =  ({currentProperty, changeValue, defaultValue, defaultImg, change, position}) => {
  const [type, setType] = useState('images');
  const [useGradient, setUseGradient] = useState(false);
  const count = currentProperty.images_count;
  const colorLabel = useGradient ? 'Gradient color': 'Background color';
  const colors = defaultValue.split(',');
  const selectedImg = defaultImg ? defaultImg.match(/bg_(\d+)/)[1] :'';
  const arr = Array.from({ length: count}, (_, index) => index + 1);
  const changeoPosition = (value, type) => {
    change(value, type, 'position');
  }
  const setGradient = (value) => {
    if(value) {
      let color = defaultValue + ',#ffffff';
      changeValue(color)
    } else {
      changeValue(colors[0])
    }
    setUseGradient(value)
  }
  const changeBg = (val, index) => {
    if(useGradient) {
      let newColors = [...colors];
      newColors[index] = val;
      changeValue(newColors.join(','));
      
    } else {
      changeValue(val);
    }
  }


  return (
    <div className="cont-item">
      <InputColor label={colorLabel} value={colors[0]} changeValue={(val) => changeBg(val, 0)}/>
      {
        useGradient ? 
        <InputColor label={colorLabel} value={colors[1]} changeValue={(val) =>  changeBg(val, 1)}/>
        :''
      }
      <CheckBox labelText={'Use Gradient'} defaultChecked={useGradient} changeValue={setGradient}/>
      <div className="properties-body bg-cont">
        <Item key={'-1'} selected={!selectedImg} value={''}  changeValue={changeValue}/>
        {
          arr.map((el, index) => {
            return <Item key={index} selected={el == selectedImg} value={`url(${require('assets/bg/icon_bg_' + el +'.png')})`} isColor={type === 'colors'} changeValue={() => changeValue(el, 'image')}/>
          })
        }
        <NavigationCircle changeValue={changeoPosition} defaultUp={position.up} defaultRight={position.right} defaultZIndex={position.zIndex} stepX={5} stepY={5} hideZindex={true}/>
      </div>
    </div>
  );
};

export default BgBody;
